
import { Dropdown, Table } from "react-bootstrap"
import useFectch from "../createbackend";
import moment from "moment";

const ViewEcomUsers =(()=>{


  const {data,isPending,error} = useFectch('registrations/getUsers_ebeonadiEcommerce');
  console.log("data----------", data);



    return(

        <>
         <div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>E-commerce /</li>
                    <li className="createem">View Ecom-Users</li>
                </ul>
            </div>
            <h2 className="viewemployee-head">List of Ecom-Users</h2>
            <div>
            <Table striped bordered hover size="sm" className='adaogidi-table'>
    
      
    <thead>
      
      <tr>
        <th>S/N</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th> Phone Number</th>
        <th>User Email</th>
        <th>State</th>
        <th>Local Government</th>
        <th>User Address</th>
        <th>Created Date/time</th>
       
        <th>Action</th>
      </tr>
    </thead>


    <tbody>
{/* {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>} */}

  
{
 data.map((ecom,i)=>(
  <tr key={ecom.userId}>
    <td> {i+1} </td>
    <td> {ecom.firstName} </td>
    <td>{ecom.lastName}</td>
    <td>{ecom.phoneNumber}</td>
    <td>{ecom.emailAddress}</td>
    <td>{ecom.state} </td>
    <td>{ecom.city} </td>
    <td>{ecom.address} </td>
    <td>{   moment( ecom.createdDateTime).format('llll') } </td>
    
    
    <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
      <Dropdown.Toggle id="dropdown-autoclose-outside" >
        Action
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdownshow-d">
        <Dropdown.Item className="sideside" >Suspend</Dropdown.Item>
        <Dropdown.Item className="sideside" >UnSuspend</Dropdown.Item>
        <Dropdown.Item  className="sideside" id="delete-red" >Delete</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
    
      
  </tr>

 ))  

}

  
  

  
</tbody>   
     


    
  </Table>

            </div>
        
        </>
    )
})
export default ViewEcomUsers