import {RiArrowDropDownLine} from "react-icons/ri"
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import useFectch from "./createbackend";
import axios from "../../utility/axios"
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"


const ViewCompanyNews=(()=>{




    const [action, setAction]= useState('');
    const [show, setShow] = useState(false)
    const [lgShow, setLgShow] = useState(false);
  
    const actionButton= ()=>{
     setAction(!action)
   
   
    }
  
    const {data,isPending,error} = useFectch('programs/getCompanyNews');
  
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const notify = () => toast("Processing your request...");
  
  
  // delete applicant
  const [applicantId,setApplicantId] =useState('')
  
  const toggleDelete = (company) =>{
   
    setApplicantId(company.companyNews_id)
    
    setLgShow(true)
  }
  const [errorMess, setErrorMess] = useState("");
   const [successMessage, setSuccessMessage] = useState("");
  
  
    const handleSubmitDelete =(event)=>{
      notify()
      event.preventDefault();
      axios.put(`programs/removeCompanyNews/${applicantId}`)
      .then(response =>{
        console.log('emeka>>>>>>>>>>>>',response)
        
        if(response){
          setSuccessMessage("Applicant Removed Successfully");
          window.location.reload();
       }else{
          
        setErrorMess(data.message);
       } 
        })
      .catch(error=>{
        console.log(error);
      });
  
      setLgShow(false)
  
      handleSubmitDelete();
    }
  
  
    const closeErrorClick =(()=>{
      setErrorMess(false)
  })


    return(

        <>
       <div>
             <ul className="action-bar">
                 <li>Home /</li>
                <li>Company News/</li>
                <li className="createem">Company News</li>
            </ul>
         </div> 
         <h2 className="viewemployee-head">Company News</h2>



         <Table striped bordered hover size="sm" className='adaogidi-table'>
   <thead>

    
     <tr>
       <th>S/N</th>
       <th>Topic</th>
       <th>Second topic</th>
       <th>start date time</th>
       <th>end date time</th>
       <th>link</th>
       <th>Details</th>
       
       
       <th>Status</th>
      
      

      

       <th>Action</th>
     </tr>
   </thead>
   <tbody>
   {/* {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>}
    */}
      {
        data.map((company,i)=>(
  <tr key={company.companyNews_id} >
 <td> {i+1} </td>
 <td>  {company.topic} </td>
 <td>  {company.secondTopic} </td>
 <td>  { moment(company.startDateTime).format('llll') } </td>
 <td>  { moment(company.endDateTime).format('llll') } </td>
 <td> {company.link} </td>
 <td> {company.details.substring(0,80)}...  </td>
 <td> {company.statuss} </td>
 


       
 <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
<Dropdown.Toggle id="dropdown-autoclose-outside">
Action
</Dropdown.Toggle>

<Dropdown.Menu className="dropdownshow-d">

<Dropdown.Item  className="sideside" onClick={() =>toggleDelete(company)}  >Remove News</Dropdown.Item>

</Dropdown.Menu>
</Dropdown>
</tr>


        ))
 
      }
       




    
   {/* {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>} */}
</tbody>
 </Table>

 <form   onSubmit={handleSubmitDelete}>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Remove News
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to Remove this News?</p>
       <button  className="btn-remove btn btn-danger" type="submit"  onClick={handleSubmitDelete} >Remove News</button> 
       </Modal.Body>
       
      
      </Modal>
      </form>



        </>
    )
})
export default ViewCompanyNews