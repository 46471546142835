import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import ModeHeader from "../layouts/modeheader"
import "../Ebonaditv/ebeonaditv.css"
import Footer from "../layouts/footer"
import Newsfeed from "../layouts/newsfeed"


const RadioAchieve =()=>{


    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>
            <div className="radioachieve-head">Radio Achieve</div>
            <div className="tv-show-container">


            <iframe width="356" height="250" src="https://www.youtube.com/embed/GcSkdcxUcUM" 
            title="Love Doctor can't Answer my questions 💖 Ebeonadi fm Ogidi [ Leophine resident hotel ogidi]" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
                <span className="first-yotube-text">Prince Perry Ebube Izuogu with Okosis @Ebeonadi Fm 103.5 Ogidi Obori N'China, Eze Urukputu</span>

            </div>
            <div className="tv-show-container">
                <iframe width="356" height="250" src="https://www.youtube.com/embed/we12qAS_eOk"
                    title="Ife Adi Ako Ebe Onadi"
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="true"></iframe>

                <span className="first-yotube-text">Chief (Dr.) Oliver De Coque and His Expo 76 Ogene Sound Super of Africa - Topic</span>

            </div>
            <div className="tv-show-container">
                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Febeonadi103.5FM%2Fvideos%2F1528955904263936%2F&show_text=false&width=267&t=0"
                    width="356" height="250"
                    frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"></iframe>
                <span className="first-yotube-text">Newspaper Headlines with Divine and Martin.
                    Send SMS/WhatsApp messages at 0809666103</span>
            </div>
            <div className="tv-show-container">
                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Febeonadi103.5FM%2Fvideos%2F888228309113458%2F&show_text=false&width=267&t=0"
                    width="356" height="250"
                    frameborder="0" allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"></iframe>
                <span className="first-yotube-text">K'OSIME WITH SHAKAZULU</span>
            </div>
            <div className="tv-show-container">
                <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Febeonadi103.5FM%2Fvideos%2F872846747098905%2F&show_text=false&width=267&t=0"
                    width="356" height="250"
                    frameborder="0" allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"></iframe>
                <span className="first-yotube-text">LOVE CLINIC IS ON WITH 'DE GIFTED LOVE DOCTOR</span>

                

            </div>


<Footer/>




        </>
    )
}
export default RadioAchieve