import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import image from "../images/jobspng-removebg-preview.png"
import {TbChevronsDown} from "react-icons/tb"
import {RiFolderSharedLine} from "react-icons/ri"
import {MdCreateNewFolder} from "react-icons/md"
import {MdContactPhone} from "react-icons/md"
import {MdOutlineKeyboardDoubleArrowRight} from "react-icons/md"



const PostJob =(()=>{

    return(
        <>
       <Changer/>
        <ModeHeader/>
        <FirstHeader/>
        <Newsfeed/>
<div className="jobspost-container">
   
<div className="postjobdisplay"> 
 

  <div className = 'container-jobtext projects'>
   <div className="jobtext-h1">Big ideas. Amazing talent. Welcome to <span className="ebejob-name"> Ebeonadi fm </span> job posting site. Find and hire the right person for every job.
     <p className="findtext-job">Contact our helpdesk for immediate posting</p>
       <i className="arrow-downjob"><TbChevronsDown className="arrow-downjob"/></i>
    </div>
<div className="overlay"></div>
</div>


    <div>
     <img src={image} className="jobsposting-image"/>   
    </div>  

</div> 
<div> 
  <span className="postjob-spandiappear"></span>
</div>


<p className="postjob-ptag"></p>

</div>
<section className="section-cover" >
       <h2 className="how-does">How does it work?</h2>
    <div className="jobpost-secondsction" >
            <div className="downjob-container">
              <i className="downjob-icon"><RiFolderSharedLine size={70} /></i>
              <h4 className="downd-head">
                SHARE THE BASICS
              </h4>
              <div className="downtext-p">
                To get started, tell us where you are hiring and who you are hiring for
              </div>
              <span><MdOutlineKeyboardDoubleArrowRight className="down-createjob-arrow" size={40}/></span>
            </div>
            <div className="downjob-container" >
              <i className="downjob-icon"><MdCreateNewFolder size={70} /></i>
              <h4 className="downd-head">
                NAIL DOWN THE DETAILS
              </h4>
              <div className="downtext-p">
                Tell us about your company so we can create your job advert and reach out to the best selected applicants
              </div>
              <span><MdOutlineKeyboardDoubleArrowRight className="down-createjob-arrow" size={40}/></span>
            </div>
            <div  className="downjob-container">
              <i className="downjob-icon"><MdContactPhone  size={70} /></i>
              <h4 className="downd-head">
                CONTACT OUR HELPDESK
              </h4>
              <div className="downtext-p">
                Contact our helpdesk for immediate posting.
                <p className="emailsjob">Email :  <span className="ebeemailjob">ebeonadifmjobs@gmail.com</span></p>
                <p > Call or Whatsapp: 08093334444 / 08093334448 / 08093334446 </p>
              </div>
            </div>

    </div>

</section>



        <Footer/>  
         
         </>
    )
})
export default PostJob