import {MDBBtn,MDBContainer,MDBCard,MDBCardBody,MDBCardImage,MDBRow,MDBCol,MDBIcon,MDBInput,MDBCheckbox}
from 'mdb-react-ui-kit';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import axios from "../../utility/axios";
import { useDispatch, useSelector } from 'react-redux';


let renderCount = 0;

const SignInPage =(()=>{


  const {register,handleSubmit,formState:{errors}} = useForm();
  renderCount ++;
  console.log(errors)

  const handleSubmitClick =(()=>{
    handleSubmit()

  })

const navigate = useNavigate()
const [isPendings, setIsPendings] = useState(false);
const [errorMessage, setErrorMessage] = useState(' ')
const [disabled, setDisabled]= useState(false)

const {items} = useSelector((state)=> state.cart);
    // const dispatch = useDispatch();

   
  const handleSubmited = async (data)=>{
    setDisabled(true);
    setIsPendings(true);
    console.log("data", data)
     await axios.post("registrations/login_ebeonadiEcommerce",data)
      
     .then((res)=>{
        console.log("res",res)
  if(res.data.success === true){
           const ecomUserData = res.data.userDetails
          localStorage.setItem("ecomUserData",JSON.stringify(ecomUserData)) 
          setIsPendings(false);
          setDisabled(false);
          if(items.length === 0){
           navigate("/mainprofile", {state:{}} )
          }else{
            navigate("/bookingpage", {state:{}} )
          }
         }else if(res.data.success === false ){
            setIsPendings(false); 
            setErrorMessage('Incorrect email or password')
          }else{
            setIsPendings(false); 
            
        }
          
    }).catch((err)=>{
        console.log("err", err)
        setIsPendings(false); 
        setDisabled(false);
    }) 
    // console.log("networkcall", networkcall)

}



    return(

        <>
 <main className='mainbookinpage-all'>
      <EcommerceNav/>

   

   <MDBContainer fluid className='signupcontainer'>

  <MDBRow className='d-flex justify-content-center align-items-center h-100'>
  <MDBCol col='12'>

    <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
    <form onSubmit={handleSubmit((data,event) => {
                event.target.reset()
                handleSubmited(data)
                console.log("logindata",data);
                
            })}>
      <MDBCardBody className='p-5 w-100 d-flex flex-column'>
               
        <h2 className="fw-bold mb-2 text-center">Login</h2>
        <p className="text-white-50 mb-3">Please enter your login and password!</p>

        <MDBInput wrapperClass='mb-4 w-100' label='Email address' id='formControlLg' type='email' size="lg"  {...register("emailAddress",{required:"This field is required"})}/>
        <p className="cum-error">{errors.emailAddress?.message}</p>
        <MDBInput wrapperClass='mb-4 w-100' label='Password' id='formControlLg' type='password' size="lg"  {...register("password",{required:"Password is required"})}/>
        <p className="cum-error">{errors.password?.message}</p>

        <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
        <p>Don't have an account <Link  to="/signuppage"><span> Register Now</span></Link></p>

        <button size='lg' className='login-btn-login w-100 btnlogin 'type='submit' disabled={disabled} >
          Login
          {
            isPendings && <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          }
        

        </button>
        {errorMessage && <div className="errlogin">{errorMessage}</div>}
       

      </MDBCardBody>
      </form>
    </MDBCard>

  </MDBCol>
</MDBRow>

</MDBContainer>

<div>
  <Footer/>
</div>
</main>
        
        </>
    )
})
export default SignInPage