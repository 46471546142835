
import { AiOutlineClockCircle } from "react-icons/ai"
import { BsFillBagCheckFill } from "react-icons/bs"

import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import FirstHeader from "../layouts/firstheader"
import Newsfeed from "../layouts/newsfeed"
import FirstSideAdvert from "./firstsideadvert"
import useFectch from "../adminpage/createbackend"
import { useParams } from "react-router-dom"
import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import moment from 'moment'
import Form from 'react-bootstrap/Form';
import { formatDistance, subDays } from 'date-fns'
import Modal from 'react-bootstrap/Modal';
import Changer from "../layouts/changer"
import { useForm } from "react-hook-form"
import axios from "../../utility/axios"
import { Alert } from "react-bootstrap"
import { HiOutlineLocationMarker } from "react-icons/hi"
import { FaGraduationCap } from "react-icons/fa"
import { TbSend } from "react-icons/tb"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"


let renderCount = 0;

const ShowJobDetails = () => {

  const { jobId } = useParams();
  const { data: jobs, isPending, error } = useFectch('jobs/getjobs');


  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

 

  const newArr = jobs.filter((id) =>
    id.jobId === jobId

  )
 
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [isPendings, setIsPendings] = useState(false);


  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;
  console.log(errors)


  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");


  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })


  const handleSubmited = async (data) => {
    // e.preventDefault()
    console.log("data again", data);

    setIsPendings(true)



    await axios.post("jobs/createJobApplications", data)

      .then((res) => {

        console.log("res", res)
        if (res.data.success) {
          setIsPendings(false)
          setSuccess("Application successfully submitted");
          setErrorMessage("");


        } else {

          setErrorMessage(res.data.message);
          setIsPendings(false)
        }

      }).catch((err) => {
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
        setIsPendings(false)
      })
    setShow(false)
    scrolltop()

  };
  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);

      }, 3000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])


  const notify = () => toast("Application processing...");

  const closeErrorClick =(()=>{
    setErrorMessage(false)
})





  return (
    <>
      <div className="overflowdiv">
        <ModeHeader />
        <FirstHeader />
        <Newsfeed />
        <Changer />

        <div className="toaterter-run"> <ToastContainer /></div>
        {
          success && <div >
           
          <button  className="btn btn-success toast-success-apply">{success}</button>
          
        </div>
        }
        {errorMessage && <div >
          <button  className="btn btn-danger toast-success-btnapply">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
          
        </div>
        } 

        {isPendings && <div className="loaderless"> <div className="lds-ripple"> Loading... <div className="blueload"></div><div className="darkorangeload"></div></div>



        </div>}
        {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div className="blueload"></div><div className="darkorangeload"></div></div>



        </div>}



        {error && <div>{error}</div>}

       
        <div className="jobdetails-container">
          {
            newArr.map((job) => (

              <div className="showdetail-container" key={job.jobId}>
                <h1 className="com-head">{job.jobPosition}</h1>
                <p className="com-name"> {job.companyName} </p>
                <div className="showdetail-div">
                  <p>< AiOutlineClockCircle /> {moment(job.startDateTime).fromNow()} </p>
                  <p ><BsFillBagCheckFill /> {job.jobCategory}</p>
                  <p ><HiOutlineLocationMarker /> {job.companyAddress}</p>
                  <p ><FaGraduationCap /> {job.minimunEducation}</p>
                </div>
                <hr />
                <Button onClick={handleShow} className="button-18" role="button"> Apply on Ebeonadi <TbSend /> </Button>

                <hr />
                <div>
                  <h3 className="aboutdetailjob">About the job</h3>

                  <div className="jobdetails-entry"> {job.jobDetails}  </div> <br />

                  <div> Send your Cv and Cover letter to :<span className="sendemailspan"> {job.emailAddress}</span></div>
                </div> <br />



                <div ref={ref}>

                 






                  <Modal show={show} onHide={handleClose}>
                     {isPendings && <div> <div className="lds-ripple"> Loading... <div></div><div></div></div></div>} 
                    <Modal.Header closeButton>
                    <Modal.Title>Apply for {job.jobPosition} </Modal.Title> <p></p>
                    </Modal.Header>
                    <Modal.Header >
                    <Modal.Title className="secheader-job"> {job.companyName} </Modal.Title>
                     
                    </Modal.Header>
                    <Modal.Body>

                      <Form className="formsubmit-container " onSubmit={handleSubmit((data, event) => {

                        event.target.reset()
                        handleSubmited(data)

                      })} >

                        <Form.Group className="mb-3 w-300" controlId="formBasicEmail">
                          <Form.Label>First Name <span className="star-required">*</span></Form.Label>
                          <Form.Control placeholder="Enter first name"
                            {...register("firstName", { required: "This field is required" })}
                          //  onChange={(e) => setFirstName(e.target.value)}
                          />
                          <p className="cum-error">{errors.firstName?.message}
                          </p>
                          <Form.Label>Last Name <span className="star-required">*</span></Form.Label>
                          <Form.Control placeholder="Enter last name"
                            {...register("lastName", { required: "This field is required" })}
                          //  onChange={(e) => setLastName(e.target.value)}
                          />
                          <p className="cum-error">{errors.lastName?.message}</p>
                          <Form.Label>Email Address <span className="star-required">*</span></Form.Label>
                          <Form.Control type="email" placeholder="Enter a valid email"
                            // onChange={(e) => setEmail(e.target.value)}
                            {...register("emailAddress", { required: "This field is required" })} />
                          <p className="cum-error">{errors.emailAddress?.message}</p>


                          <Form.Label>Phone Number <span className="star-required">*</span></Form.Label>
                          <Form.Control type="number" placeholder="Entail phone number"

                            //  onChange={(e) => setPhoneNumber(e.target.value)}
                            {...register("phoneNumber", { required: "This field is required" })}
                          />
                          <p className="cum-error">{errors.phoneNumber?.message}</p>
                          <Form.Label>Company Name <span className="star-required">*</span></Form.Label>
                          <Form.Control placeholder="Enter the name of the company advertised"
                            // onChange={(e) => setCompanyName(e.target.value)}
                            {...register("companyName", { required: "This field is required" })}
                          />
                          <p className="cum-error">{errors.companyName?.message}</p>
                          <Form.Label>Position <span className="star-required">*</span></Form.Label>
                          <Form.Control placeholder="Enter position of the job as advertised"
                            //  onChange={(e) => setPosition(e.target.value)}
                            {...register("position", { required: "This field is required" })}

                          />
                          <p className="cum-error">{errors.position?.message}</p>
                          <Form.Label>Qualification <span className="star-required">*</span></Form.Label>
                          <Form.Control placeholder="Enter your academic qualification, e.g B.sc"
                            {...register("qualification", { required: "This field is required" })}
                          //  onChange={(e) => setQualification(e.target.value)}
                          />
                          <p className="cum-error">{errors.qualification?.message}</p>


                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={notify} >Submit</Button>

                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>

                    </Modal.Footer>
                  </Modal>


                



                </div>

              </div>
            ))
          }

          <div className="sidejod-advert">
            <FirstSideAdvert />

          </div>
        </div>
        <div className="jobformdata-container">
          {/* <JobForm/>  */}
        </div>
        <Footer />
      </div>
    </>
  )

}
export default ShowJobDetails