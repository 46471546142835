 
import ReactAudioPlayer from 'react-audio-player';
import PresentPic from "../images/radio4.jpg"
import music1 from "../Audio/Seyi_Vibez_-_Para_Boi_Speed_Up_.mp3"
import { ReactDOM } from 'react';
//  import {audio1} from "../Audio/Seyi_Vibez_-_Para_Boi_Speed_Up_"
const Player = ({showName,showDate,PresenterName})=>{



    return(

        <div className='audio-player'>
          <div className='remove-dis'>
            <span className='player-name'>{PresenterName}</span>
            <div className='remove-play'>x</div>
            <h1 className='player-head'>{showName}</h1>
            <p className='play-date'>{showDate}</p>
            <img className='pop-pics' src={PresentPic}/> <br/>
            </div>

            <ReactAudioPlayer className='main-play'
  src={music1}
  play
  controls
  
  
//   style={{ padding: "0", height: '25px', width: '140px' }}
//   onPlay={e=> console.log("onPlay")}
/>
{/* <AudioPlayer
        autoPlay
         src={music1}
        onPlay={e => console.log('onPlay')}
        // other props here
      /> */}


        </div>
    )

    
 }
 export default Player