import "./daysprogram.css";
import mondaypic from "../../images/enter5.jpg";
import mondaypic1 from "../../images/kpakpando.png";
import mondaypic2 from "../../images/Picture3-removebg-preview.png";
import mondaypic4 from "../../images/Picture2-removebg-preview.png";
import ShowMissed from "../../news/showmissed";
import useFectch from "../../adminpage/createbackend";
import { useState } from "react";
import man from "../../images/human22.png"
import ReadMoreReact from "read-more-react";
import moment from "moment";





const Allprograms =()=>{

const {data,isPending,error} = useFectch("programs/getPrograms")
console.log("programs>>>>>>>>>",data)
const [isShow, setIsShow] = useState(false)


const ProgramFormatStartTime= data.forEach((time)=>{
    const startTime = time.programStartTime;
    const endTime = time.programEndTime

const dummyDate = "2022-01-01"
const combinedDateTime =`${dummyDate}${startTime}`;
const combinedEndTime =`${dummyDate}${endTime}`;

const paresedTime = moment(combinedDateTime, "YYY-MM-DD h:ma");
const paresedEndTime = moment(combinedEndTime, "YYY-MM-DD h:ma");

time.programStartTime =  paresedTime.format("h:ma")
time.programEndTime =  paresedEndTime.format("h:ma")

})

console.log("formated >>>>", ProgramFormatStartTime)




    return(
        <>


            <div className="control-monday">



                <h2 className="schedule-date"><span className="dayname">All</span> Programs </h2>
            </div>

      

            

            <div className="projcard-container">
          
          
          {
data.map((program)=>(
        

		
        <div className="projcard projcard-blue">
            <div className="projcard-innerbox">
              <div className="day-circle-image">   <img className="projcard-img"  src={program.programpresenters.map((image)=>(
            image.image
        ))}  /> </div>
                <div className="projcard-textbox">
                    <div className="projcard-title">{program.programName}</div>
                    <div className="projcard-subtitle">{program.programOtherNames}</div>

                    <div className="projcard-bar"></div>
                    
                    <div className="projcard-tagbox">
                       <span className="fromprogram"> From </span>  <span className="projcard-tag"> { program.programStartTime }  </span> <span className="toprogram">To</span>
                        <span className="projcard-tag">  { program.programEndTime}</span>
                    </div>
                    <div className="projcard-description">{ <ReadMoreReact  
        text={program.programDetails}
        min={500}
        ideal={800}
        max={2500}  
/> } </div>
                </div>
            </div>
        </div>
        
        ))

    } 
      
        
    </div>





            <div className=" monday-all">




                {isPending && <div>

                    <div className="projcard" >

                        <h2 className="schedule-title-ske"> </h2>
                        <span className="monday-time"> </span>
                        <div className="monday-div-ske">


                            <img className="monday1-image-ske" />

                            <span className="monday-article">
                                <p className="p-skeletons"></p>
                                <p className="p-skeletons"></p>
                                <p className="p-skeletons"></p>
                                <p className="p-skeletons"></p>
                                <p className="p-skeletons"></p>
                                <p className="p-skeletons"></p>



                            </span>

                        </div>
                        {/* <div className="box">
                            <div className="profile-img" />
                            <div className="skeleton-placeholder" />
                            <div className="skeleton-placeholder" />
                        </div> */}

                    </div>
                </div>

                        
                        // <div className="loaderless" id="">
                            
                        //      <div className="lds-ripple"> Loading... <div></div><div></div></div>
                        // </div>
                        
                        
                        }
                        {error && <div className="errorssample">{error}</div>}
                    </div>
                  
                

           
        </>
        
        

        
    )
}
export default Allprograms
