import logo2 from "../images/logo fm2.jpg"
import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import Footer from "../layouts/footer"
import Header from "../layouts/header"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"
import NEwsLetter from "./newsletter"
const AboutUs =()=>{

    return(
        <>
        <div className="overflowdiv">
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>
 <div className="aboutus-container">
    <img className="about-image" src={logo2}/>
    <div className="abouttext-div">
    <h2 className="transmit-head">We Transmit on <span className="about-fre"> 103.5</span> Frequency Modulation Band</h2>
    <p className="about-text">The Only Resident Radio Station On 24hr transmission in Southeast Nigeria. Started in 2014, Ebeonadi combines culture with entertainment. Proudly Nigerian and Proudly Igbo, we don’t use foreign accents on our station. We are true to our heritage and culture.</p>
    
    </div>
 </div>
 <div className="central-nesletter">
 <div className="subscribe">
	<h2 className="subscribe__title">Let's keep in touch</h2>
	<p className="subscribe__copy">Subscribe to keep up with fresh news and exciting updates. We promise to keep you updated !</p>
	<div className="form">
		<input type="email" className="form__email" placeholder="Enter your email address" />
		<button className="form__button">Send</button>
	</div>
	<div className="notice">
		<input type="checkbox"/>
		<span className="notice__copy">I agree to my email address being stored and uses to recieve recent newsletter.</span>
	</div>
</div>
 </div>
 
 <Footer/>
 </div>
        </>
    )
}
export default AboutUs