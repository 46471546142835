import jobsearch1 from "../images/3dsearch.jpg";
import jobsearch2 from "../images/officeimg.jpg";
import { NavLink } from "react-router-dom";
import Footer from "../layouts/footer";
import {jobData} from "../directpages/jobdata"
import { useEffect, useState } from "react";
import ModeHeader from "../layouts/modeheader";
import FirstHeader from "../layouts/firstheader";
import Changer from "../layouts/changer";
import Newsfeed from "../layouts/newsfeed";
import axios from "../../utility/axios"
import Button from 'react-bootstrap/Button';
import moment from "moment";
import {FcSearch} from "react-icons/fc"
import {CiLocationOn} from "react-icons/ci"
import {BsFillBagFill} from "react-icons/bs"
import {FaGraduationCap} from "react-icons/fa"
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact";






const GetJobs =()=>{

    const [jobName, setjobName] = useState("")
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    
    const itemsPerPage = 8;
    

 useEffect(()=>{
      axios.get("jobs/getjobs")
      .then(response=>{
        console.log("abia>>>>>>>>",response)
        if (!response.statusText=== "OK"){
          throw Error("Could not fetch data")
        }
        setData(response.data)
        setIsPending(false)
        setError(null)
       
      })
      .catch(error =>{
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
     },[]);
              // Pagination
     const [currentPage, setCurrentPage] = useState(1);
    
     const startIndex = (currentPage - 1) * itemsPerPage;
     const endIndex = startIndex + itemsPerPage;

     const currentItems = data.slice(startIndex, endIndex);
     const totalPages = Math.ceil(data.length / itemsPerPage);

     const handlePageChange = (page) => {
      setCurrentPage(page);
    };
   
  const [currentDate, setCurrentDate] = useState()


  useEffect(() => {
    const handleActive = () => {
      const now = new Date();
      const currentDateTime = moment(now).format("YYYY-MM-DD HH:mm:ss");

      const activeData = data.find(
        (active) => currentDateTime <= active.endDateTime
      );

      setCurrentDate(activeData);
    };

    handleActive();
  }, [data]);

    return(

      <div className="overflowdiv">

        <ModeHeader />
        <FirstHeader />
        <Changer />
        <Newsfeed />
         <div className="getjob-container" > 
          <div className="jobsearch-text">
            <h1 className="search-h1">GET YOUR DREAM JOB</h1>
            <p className="search-p">Explore and discover the right job for you!</p>

          </div>

         

        </div> 


        <input className="jobsearch-input" placeholder="Search job Listings..."
          onChange={(event) => setjobName(event.target.value)}  />
           <FcSearch className="searchicon-jobs" size={30}/>
       
        <h1 className="job-category">Featured Jobs</h1>
        <div className="joblisting-all">

          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

          </div>}
          {error && <div className="errorssample">{error}</div>}
    

          <div className="showjob2">
          {currentItems.length === 0 ? (
            <div className="joblisting-container">
              <div className="joblisting-text">
                <span className="no-jobs">Checking for available jobs, Please wait !</span>
              </div>
            </div>
          ) : (
            currentItems.filter(
                (job) =>
                  job.companyName &&
                  job.companyName.toLowerCase().indexOf(jobName) !== -1
              )
              .map((name) => (
                <div className="joblisting-container" key={name.jobId}>
               
                <div className="joblisting-text">

                  <span className="jobnamemain">{name.jobPosition}</span> <br /><br />
                  <span className="jobcompany-name">{name.companyName
                  }</span> <br /><br />
                  <div className="jobdetails-side">
                  <div className="jobflexw">
                    <div className="comadresss"> <CiLocationOn size={20} />  {name.companyAddress}  </div>
                    <div className="apptype"> < BsFillBagFill size={20} />  {name.jobCategory}  </div>
                    <div className="minedu">   < FaGraduationCap size={20} />  {name.minimunEducation}  </div>

                    <div
                        className={`minedus ${
                          moment(name.endDateTime).isBefore(moment())
                            ? "red-status"
                            : ""
                        }`}
                      >
                        {name.statuss}
                      </div>
                   
                  </div>


                <div className="jobdetailsside">
                     
                     {
                    <ReadMoreReact  
                      text={name.jobDetails}
                      min={100}
                      ideal={300}
                      max={2500}
                      readMoreText= ""  
              
                    
                  />
                  }
                   <NavLink to={`/jobdetails/${name.jobId}`} className="view-btn">
                    <button className="viemorejobs">
                            Read More/Apply 
        

                    </button>
                  </NavLink>
                    
                   </div>
                   <div className="jobtime"> {moment(name.startDateTime).fromNow()
                  }</div>
                   </div>

                 
                </div>


              </div>

            )
            
            ) )}
          </div>
        </div>
        <div className="pagination-job">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              disabled={currentPage === page}
              className="pagination-job-button"

            >
              {page}
            </button>
          )
        )}
      </div>

        <Footer />
      </div>


         )
}
export default GetJobs