
import { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../utility/axios";
import useFectch from "./createbackend";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md";




let renderCount = 0;


const CreateNews =()=>{
  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;
  console.log("Create News data", errors)

  const [showAccess, setshowAccess] = useState(false);
  const [data, setData] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isPendings, setIsPendings] = useState(false);

 
  
  const displayAccess = () => {

    setshowAccess(!showAccess)
}

// const[val,setVal]=useState("")

const {data:news,isPending,error} = useFectch('registrations/getUsers');

const [image, setImage] = useState(null);
    
const handleImageChange = (e) => {

    console.log(e.target.files)
  setImage(e.target.files[0]);
};

const scrolltop = (() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"

  });
})





const handleSubmited = async (data)=>{
  setIsPendings(true);
  setDisabled(true)
 

  console.log("data", data);
  const formData = new FormData();
  formData.append("topic",data.topic);
  formData.append("newsCategory",data.newsCategory);
  formData.append("newsType",data.newsType);
  formData.append("scrollBar",data.scrollBar);
  formData.append("startDateTime",data.startDateTime);
  formData.append("endDateTime",data.endDateTime);
  formData.append("onMainBody",data.onMainBody);
  formData.append("startDateAppearance",data.startDateAppearance);
  formData.append("endDateDisappearance",data.endDateDisappearance);
  formData.append("newsDetails",data.newsDetails);
  formData.append("createdBy",data.createdBy);
  formData.append("image",image);
  
   await axios.post("programs/createNews",formData, {
    headers: { "Content-Type": "multipart/form-data" },
   })
  .then((res)=>{
   console.log("res",res)
    if(data.success === true){
          setIsPendings(false); 
           setSuccess("Employee Registered Succefully") 
           setDisabled(false)
 
           }else{
          setIsPendings(false);  
            setSuccess(res.data.message)
            setDisabled(false)
           }
    }).catch((err)=>{
      console.log("err", err)
      setErrorMessage("Something went wrong. Please try again later.");
      setIsPendings(false); 
      setDisabled(false)
  }) 
  scrolltop()

}

const [disabled, setDisabled]= useState(false);


const[selectOption,setSelectOption]=useState(" ");
const handleselectChange =(event)=>{
    setSelectOption(event.target.value)
}
  
useEffect(()=>{
     
  let timeout;
  if (success){
     timeout = setTimeout(()=>{
      setSuccess(false);
     },5000)
  }
  return () => {
      clearTimeout(timeout);
  };

  },[success])

const [datetime, setDatetime] = useState("");
const [date, setDate] = useState("");
const [time, setTime] = useState("");

const handleDateTimeChange = ((event)=>{
const value = event.target.value;
setDatetime(value);

const dateObj = new Date(value);
const dateValue = dateObj.toLocaleDateString();
setDate(dateValue)

const timeValue = dateObj.toLocaleTimeString();
setTime(timeValue)

});

const notify = () => toast("Creating news...");

const closeErrorClick =(()=>{
  setErrorMessage(false)
})


    return(

        <>
        <div>
          <ul className="action-bar">
            <li>Home /</li>
            <li>News/</li>
            <li className="createem">Create News</li>
          </ul>
        </div>
        <div className="toaterter-run"> <ToastContainer /></div>
        {
          success && <div >
           
          <button  className="btn btn-success toast-success-btn"id="tout-success">{success}</button>
          
        </div>
        }
        {errorMessage && <div >
          <button  className="btn btn-danger toast-success-btn">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 
         {isPendings && 
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
        } 


       

        <form className="createnews-form" onSubmit={handleSubmit((data,event) => {
          event.target.reset();
          
          handleSubmited(data)
          
        })} >
          
          <h2 className="createnews-head">Create News</h2>

          
        <p className="cross-checknews" >Note: <span className="cross-checknew">Please cross check your entries before submit!</span></p>


          <label>Topic <span className="star-required">*</span></label><br />
          <input className="newstopic-input" placeholder="Topic..." {...register("topic", {
            required: "This filed is required", maxLength: {
              value: 250,
              message: "Must not be more than 250 characters"
            }
          })} />
          <p className="cum-error">{errors.topic?.message}</p>
          <label>News Type<span className="star-required">*</span></label><br />
          <select className="newstopic-input" {...register("newsCategory", { required: "This field is required" })}  value={selectOption} onChange={handleselectChange} >
            <option value="Paid News">Paid news</option>
            <option value="Company News">Company News</option>

          </select><br /><br />


          <label>News Category<span className="star-required">*</span></label><br />

          <select className="newstopic-input" {...register("newsType", { required: "This field is required" })} value={selectOption} onChange={handleselectChange} >
              <option>Choose...</option>
            <option value="General News">General News</option>
            <option value="Local News" >Local News</option>
            <option value="entertainment" >Entertainment News</option>
            <option value="Sports" >Sports</option>
            <option value="Politics" >Politics News</option>
            {/* <option value="Culture" >Culture</option> */}
            <option value="Cultural News" >Culture News</option>
          </select><br />
          <label>Appear on Scroll bar?</label><br />
          <label className="news-radios" >Yes</label>
          <input className="radionews" type="checkbox" name="yesno" value={true} {...register("scrollBar")} />
          <label className="news-radio">No</label>
          <input className="radionews" type="checkbox" name="yesno" value={false} {...register("scrollBar")} /><br /><br />
          <label>Start Date Time  <span className="star-required">*</span></label><br />
          <input className="newstopic-input" type="datetime-local"  onChange={handleDateTimeChange} {...register("startDateTime", { required: "This field is required" })} />
          <p className="cum-error">{errors.startDateTime?.message}</p>
          <label>End Date Time<span className="star-required">*</span></label><br />
          <input className="newstopic-input" type="datetime-local" {...register("endDateTime", { required: "This field is required" })} />
          <p className="cum-error">{errors.endDateTime?.message}</p>

          <input type="checkbox" className="markbody"  {...register("onMainBody")} onClick={displayAccess} />
          <label>Appear on Main Body?</label>
          <br /><br />
          <span>
            {
              showAccess && <span>
                <label>Start Date Time Appearance</label><br />
                <input className="newstopic-input" type="datetime-local" {...register("startDateAppearance")} /><br /><br />


                <label>End Date Time Disappear</label><br />
                <input className="newstopic-input" type="datetime-local" {...register("endDateDisappearance")} /><br /><br />


              </span>
            }
          </span>
          <label>News Details <span className="star-required">*</span> </label><br />
          <textarea className="news-details" placeholder="Add Details" {...register("newsDetails")} />
          <p className="cum-error">{errors.details?.message}</p>
          <label>Created By<span className="star-required">*</span></label><br />
          <select className="newstopic-input"  {...register("createdBy", { required: "This field is required" })} value={selectOption} onChange={handleselectChange} >
          <option>Choose...</option>
          {
                news.map((news)=>(
                    <option value={news.firstName}>{news.firstName}</option>
                ))
            }
          </select><br /><br />


          <label>Upload Image <span className="star-required">*</span> </label><br />

          <input type="file" required className="uploadcreate-news"onChange={handleImageChange}   /><br /><br />
          <button className="createnews-btn btn btn-primary" type="submit" disabled={disabled} onClick={notify}>Create News</button>
        </form>


      </>
    )
}
export default CreateNews