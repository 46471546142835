
import {RiArrowDropDownLine} from "react-icons/ri"
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from "./createbackend";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "../../utility/axios";
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"
import moment from "moment";



let renderCount = 0;
function ViewJobber() {

  const [action, setAction]= useState('')
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [isPendings, setIsPendings] = useState(false);


  const actionButton= ()=>{
   setAction(!action)
 
 
  }

  const notify = () => toast("Processing your request...");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const {data:job,isPending,error} = useFectch('jobs/getJobs')
  console.log("jobs",job);
   

   const [showMore, setShowMore] = useState(false)

   ///delete///////////////////////////////

  const [errorMess, setErrorMess] = useState("");
 const [successMessage, setSuccessMessage] = useState("");
 const [jobID, setJobID] = useState("")
    
   const handleSubmitDelete =()=>{
    notify()
    // event.preventDefault();
    axios.put(`jobs/removeJob/${jobID}`)
    .then(response =>{
      
      if(response){
        setSuccessMessage("Job Removed Successfully");
        window.location.reload();
  
     }else{
      setSuccessMessage(""); 
     
     } 
      })
    .catch(error=>{
      setErrorMess("Could not delete the Advert, try again later");
    });

    setLgShow(false)
    
  
  }
  const toggleDelete = (job) =>{
    
    setJobID(job.jobId)
  
   setLgShow(true)
 }

// console.log("jobdata>>>>>>>>>>>>>", jobs)


////////////////////////Update jobs////////////////////////////////////////

const [jobs, setJobs] = useState([]);


 const [jobType, setJobType] = useState("");
 const [startDateTime, setStartDateTime] = useState("");
 const [endDateTime, setEndDateTime] = useState("");
 const [priority, setPriority] = useState("");
 const [jobCategory, setJobCategory] = useState("");
 const [companyName, setCompanyName] = useState("");
 const [jobPosition, setJobPosition] = useState("");
 const [companyAddress, setCompanyAddress] = useState("");
 const [applicationType, setApplicationType] = useState("");
 const [minimumEducation, setMinimumEducation] = useState("");
 const [qualifications, setQualifications] = useState("");
 const [startPublicationDateTime, setStartPublicationDateTime] = useState("");
 const [endPublicationDateTimes, setEndPublicationDateTime] = useState("");
 const [jobDetails, setJobDetails] = useState("");

const application = {
  jobType,startDateTime,endDateTime,priority,jobCategory,companyName,jobPosition,
  companyAddress,applicationType,minimumEducation,qualifications,startPublicationDateTime,
  endPublicationDateTimes,jobDetails
}


const [employeeData, setEmployeeData] = useState()
const [jobId, setJobId] = useState("")

const togglejob = (job) => {
  console.log("job", job.jobId)
  setJobId(job.jobId)
   setEmployeeData(job)
   setShow(true)

}

const handleCloseUpdate = () => {
  handleSubmits()
  setShow(false)
  notify()
}

const {register,handleSubmit,formState:{errors}} = useForm();
    renderCount ++;
    console.log(errors)

    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState("");
     
    const handleSubmits = async ()=>{
      setIsPendings(true);
       
        const fullData ={ ...application}
         await axios.put(`jobs/updateJobs/${jobId}`,fullData)
          
         .then((res)=>{
            console.log("res",res)

            if(res.data.success){
              setIsPendings(false);
               setSuccessMessage("Job Updated Successfully");
               window.location.reload();
                setErrorMess("");
             }else{
              setIsPendings(false);
                setErrorMess(res.data.message);
             } 
            
        }).catch((err)=>{
          setIsPendings(false);
            console.log("err", err)
            setErrorMessage("Something went wrong. Please try again later.");
        }) 
        
    
    }

    useEffect(()=>{
     
      let timeout;
      if (success ){
         timeout = setTimeout(()=>{
          setSuccess(false);
         },5000)
      }
      return () => {
          clearTimeout(timeout);
      };
    
      },[success])

      const closeErrorClick =(()=>{
        setErrorMess(false)
    })
    
    
    

  return (

    <>


<div>
             <ul className="action-bar">
                 <li>Home /</li>
                <li>Jobs/</li>
                <li className="createem">View Jobs</li>
            </ul>
         </div> 
         <div className="alertside-div">
         {
          successMessage && <div >
           
          <button  className="btn btn-success toast-success-btn">{successMessage}</button>
          
        </div>
        }
        {errorMess && <div >
          <button  className="btn btn-danger toast-success-btn">{errorMess} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 
         {error &&  <button  className="btn btn-danger toast-success-btn">{error }</button>
          }
         
        {/* {isPendings && 
           <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
           <div className='modal-contents'>
             <div className='loadered'></div>
             <div className='modal-texted'>Loading...</div>
           </div>
         </div>

        }
        */}
        </div>

<h2 className="viewemployee-head">Job List</h2>
 
    <Table striped bordered hover size="sm" className='adaogidi-table'>
      <thead>

       
        <tr>
          <th>S/N</th>
          <th>Job Type</th>
          <th>Company Name</th>
          <th>Job Position</th>
          <th>Email Address</th>
          <th>Employer's Number </th>
          <th>Qualifications</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Start Publication Date</th>
          <th>end Publication Date</th>
          <th>Details</th>
          <th>Priority</th>
          <th>Job Category</th>
          <th>Address</th>
          <th>Application Type</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>}

      {
          job.map((job, i)=>(

            <tr key={job.jobId}>
            <td> {i+1} </td>
            <td>{job.jobType}</td>
            <td>{job.companyName}</td>
            <td>{job.jobPosition}</td>
            <td>{job.emailAddress}</td>
            <td>{job.phoneNumber}</td>
            <td>{job.minimumEducation}</td>
            <td>{  moment(job.startDateTime).format('lll')   }</td>
            <td>{moment(job.endDateTime).format('lll') }</td>
            <td>{ moment(job.startPublicationDateTime).format('lll')   }</td>
            <td>{moment(job.endPublicationDateTime).format('lll') }   </td>
             <td>{job.jobDetails && job.jobDetails.substring(0,50)}...  </td> 
            <td>{job.priority}</td>
            <td>{job.jobCategory}</td>
            <td>{job.companyAddress}</td>
            

            <td>{job.applicationType}</td>
            
                      
            <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
        <Dropdown.Toggle id="dropdown-autoclose-outside">
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdownshow-d">
          <Dropdown.Item className="sideside" onClick={()=>togglejob(job)}  >Update</Dropdown.Item>
          <Dropdown.Item  className="sideside" onClick={() => toggleDelete(job)} >Remove Job</Dropdown.Item>
          {/* <Dropdown.Item className="sideside" >Menu Item</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
          </tr>

            
          ))
        }
       
       
        
      </tbody>
    </Table>

      <form  onSubmit={handleSubmit((jobData) => {
               console.log("data>>>>>>>>>>>>>>>>>>>", jobData)
              handleSubmits(jobData)
              // setJobs(jobData)
          })}  >
    <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
            
        <label>Job Type</label><br/>
                <select className="newstopic-inputs" {...register("jobType")}
                defaultValue={employeeData && employeeData.jobType}
                  onChange={(e) => setJobType(e.target.value)}
                >
                <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="Others">Others</option>
                </select>
                <br/>  <br/>
                <label className="ccpay">Paid Period</label><br/>
                <label>Start Date</label><br/>
                <input className="paidtime-input" placeholder="Start Date" type="datetime-local" {...register("startDateTime")}
                defaultValue={employeeData && employeeData.startDateTime}
                 onChange={(e) => setStartDateTime(e.target.value)}

                /><br/>
                <label className="">End Date</label><br/>
                 <input className="paidtime-input" type="datetime-local"{...register("endDateTime")}
                  defaultValue={employeeData && employeeData.endDateTime}
                   onChange={(e) => setEndDateTime(e.target.value)}
                 /> <br/> <br/>
                 <label>Priority</label><br />
                 <select className="newstopic-inputs" {...register("priority")}
                 defaultValue={employeeData && employeeData.priority}
                  onChange={(e) => setPriority(e.target.value)}
                 >
                     <option value="High">High</option>
                    <option value="Normal">Normal</option>
                    <option value ="Others">Others</option>
                </select>
                <br/>  <br/>
                <label>Job Category</label><br />
                <select className="newstopic-inputs"{...register("jobCategory")}
                defaultValue={employeeData && employeeData.jobCategory}
                 onChange={(e) => setJobCategory(e.target.value)}
                >
                   <option value="House Help">House Help</option>
                    <option value="Sales Person" >Sales Person</option>
                    <option value="Blue Collar">Blue Collar</option>
                    <option value="White Collar">White Collar</option>
                    <option value ="Others">Others</option>
                </select>
                <br/>  <br/>
               <label>Company's Name</label><br />
                <input className="newstopic-inputs" placeholder="Company Name..."
                {...register("companyName", { required: "This filed is required" })} 
                defaultValue={employeeData && employeeData.companyName}
                 onChange={(e) => setCompanyName(e.target.value)}
                />
                <p className="cum-error"></p>
                <label>Job Position</label><br />
                <input className="newstopic-inputs" placeholder="Position..."
                defaultValue={employeeData && employeeData.jobPosition}
                 onChange={(e) => setJobPosition(e.target.value)}

                 {...register("jobPosition", { required: "This filed is required" })}
                />
                <p className="cum-error"></p>

                <label>Company's Address</label><br />
                <input className="newstopic-inputs" placeholder="Position..."
                 defaultValue={employeeData && employeeData.companyAddress}
                {...register("companyAddress", { required: "This filed is required" })}
                 onChange={(e) => setCompanyAddress(e.target.value)}
                />
                <p className="cum-error"></p>

                <label>Application Type</label><br />
                <select className="newstopic-inputs"{...register("applicationType")}
                 defaultValue={employeeData && employeeData.applicationType}
                  onChange={(e) => setApplicationType(e.target.value)}
                >
                    <option value= "Radio Station">Radio Station</option>
                    <option value= "In Person">In Person</option>
                    <option value= "Email" >Email</option>
                    <option value= "Others"  >Others</option>
                </select>
                <br/>  <br/>
                <label>Minimum Education Requirement</label><br />
                 <select className="newstopic-inputs"{...register("minimumEducation")}
                 defaultValue={employeeData && employeeData.minimumEducation}
                  onChange={(e) => setMinimumEducation(e.target.value)}
                 >
                    <option value= "Primary/ Fslc" >Primary/ Fslc</option>
                    <option value='Weac' >Weac</option>
                    <option value= "ND"  >ND</option>
                    <option value= "HND/ B.sc"  >HND/ B.sc</option>
                    <option  value="Masters">Masters</option>
                    <option value="Phd" >Phd</option>
                </select>
                <br/>  <br/>

                <label>Qualifications</label><br />
                <input className="newstopic-inputs" placeholder="Qualification.."
                 defaultValue={employeeData && employeeData.qualifications}
                {...register("qualifications")}
                 onChange={(e) => setQualifications(e.target.value)}
                
                /><br /><br />
                <label>Start Publication Date</label><br />
                <input type="datetime-local" className="newstopic-inputs" placeholder=""
                 defaultValue={employeeData && employeeData.startPublicationDateTime}
                 {...register("startPublicationDateTime")}
                  onChange={(e) => setStartPublicationDateTime(e.target.value)}
                /><br /><br />
                <label>End Publication Date</label><br />
                <input type="datetime-local" className="newstopic-inputs" placeholder=""
                defaultValue={employeeData && employeeData.endPublicationDateTime}
                {...register("endPublicationDateTime")} 
                 onChange={(e) => setEndPublicationDateTime(e.target.value)}
                /><br /><br />

                <label>Job Details</label><br />
                <textarea className="news-detailsed" placeholder="Add Details"
                defaultValue={employeeData && employeeData.jobDetails}
                 onChange={(e) => setJobDetails(e.target.value)}
                {...register("jobDetails", {
                    required: "This filed is required", minLength: {
                        value: 50,
                        message: "Must be more than 200 letters"
                    }
                })} />
           
            
           

        

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      </form>

      {/* remove program */}
      <form onSubmit={ handleSubmitDelete}>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Remove News
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to Remove the Selected Job?</p>
       <button  className="btn-remove btn btn-danger" onClick={handleSubmitDelete}>Remove</button> 
       </Modal.Body>
       
      
      </Modal>
      </form >
    </>
  );}

export default ViewJobber;







