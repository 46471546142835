import axios from "axios";

//axios.defaults.baseURL="http://10.0.5.51:3015/api/v1"

//axios.defaults.baseURL="http://197.210.166.58:4007/api/v1"


    //axios.defaults.baseURL="http://10.0.5.51:3015/api/v1/"


//   axios.defaults.baseURL="http://197.210.166.58:4007/api/v1"


///live endpoint////
 axios.defaults.baseURL="https://www.ebeonadi.com:5500/app3/v1"

export default axios

