import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"


const Edutainment =()=>{

    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>

        <div className="culture-head">Edutainment</div>
        <div className="tv-show-container">
        <iframe width="356" height="250" src="https://www.youtube.com/embed/y8Hb_Q9McXg" 
        title="Preschool Songs from Akili and Me | "frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen ="true"></iframe>
        
        <span className="first-yotube-text">Preschool Songs from Akili and Me | "Let's Introduce Ourselves" | African Edutainment</span>
        </div>

        <div className="tv-show-container">
        <iframe width="356" height="250" src="https://www.youtube.com/embed/PL9Qt-CyzQM" 
        title="Transforming Education through Edutainment | Roland Nunez | TEDxLSSC" 
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen></iframe>
        <span className="first-yotube-text">Transforming Education through Edutainment | Roland Nunez | TEDxLSSC</span>
        </div>

        <div className="tv-show-container">
        <iframe width="356" height="250" src="https://www.youtube.com/embed/nnz5ebKY2kI"
         title="What Is Edutainment Content?  It's not what you think!" 
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen></iframe>

         <span className="first-yotube-text">What Is Edutainment Content? It's not what you think!</span>
        </div>

        <div className="tv-show-container">
        <iframe width="356" height="250" 
        src="https://www.youtube.com/embed/FyehamdlV3w" 
        title="Remember What You Read - How To Memorize What You Read!"
         frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
         allowfullscreen></iframe>
         <span className="first-yotube-text">Remember What You Read - How To Memorize What You Read!</span>
        </div>
        <div className="tv-show-container">
        <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Febeonadi103.5FM%2Fvideos%2F6243609852316012%2F&show_text=false&width=267&t=0"
         width="356" 
        height="250" frameborder="0" 
        allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
         allowFullScreen="true"></iframe>
     <span className="first-yotube-text">OGANIRU EZINAULO WITH LADY D . TOPIC: HOUSE-HELP</span>

        </div>

        <Footer/>
        </>
    )
}
export default Edutainment