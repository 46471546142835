import { RiCalendarEventLine, RiDirectionFill } from "react-icons/ri";
import Table from "react-bootstrap/Table";
import Footer from "../layouts/footer";
import Header from "../layouts/header";

import { useState } from "react";
import DarkExample from "./updateadmin/darktable";
import DarkExamples from "./updateadmin/daytable";
import useFectch from "./createbackend";
import ViewJobber from "./viewjobs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminFooter from "./adminfooter";
import {SlPeople} from "react-icons/si"
import { BsPeopleFill } from "react-icons/bs";
import { MdGames } from "react-icons/md";
import { GiOfficeChair } from "react-icons/gi";
import { set } from "react-hook-form";
import ModifiedNav from "../layouts/modifiednav";
import AdminDashboard from "./admindashboard";

function BasicExample() {
  const [firstCard, setFirstCard] = useState("");
  const [showPage, setShowPage] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false)
const [success, setSuccess] = useState(false)

  const Navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("adminData"));
  console.log("localStorag", userData);
  const firstCards = (alls) => {
    setFirstCard(!firstCard);

    console.log(alls);
  };

  const loggedInCheck =(()=>{
       if(userData === 1){
         return <AdminDashboard/>
       }
  })

  useEffect(() => {
    const checkLogin = () => {
      if (userData.statuss === "active") {
        setSuccess("Welcome! You successfully logged in")
        setShowPage(true);
       
        loggedInCheck()
      }if(userData.emailAddress === "admin@gmail.com"){
          return <ModifiedNav/>
      } else {
        setShowPage(false);
        
      }
    };
    checkLogin();
  }, []);

  useEffect(()=>{
     
    let timeout;
    if (success){
       timeout = setTimeout(()=>{
        setSuccess(false);
       },5000)
    }
    return () => {
        clearTimeout(timeout);
    };

    },[success])

  const { data, isPending, error } = useFectch("programs/getPrograms");
  const { data: employees } = useFectch("registrations/getTotalUsers");
  console.log("employees", employees);

  const { data: advert } = useFectch("adverts/getTotalOnlineAds");
  console.log("Advert", advert);
  const { data: jobs } = useFectch("jobs/getTotalJobs");
  console.log("jobs", jobs);
  const { data: programs } = useFectch("programs/getTotalPrograms");
  console.log("programs", programs);

  const [showJob, setShowJob] = useState("");
  const [showEmployee, setShowEmployee] = useState("");
  const [showAdvert, setShowAdvert] = useState("");
  const [showPrograms, setShowPrograms] = useState("");

  const handleShowJob = () => {
    setShowJob(!showJob);
    displayShow();
  };

  const displayShow = () => {
    if (showJob === "job") {
      return console.log("nowwwwww");
      // <ViewJobber/>
    }
  };
  {
    displayShow();
  }

  return (
    <>
    {
          success && <div >
           
          <button  className="btn btn-success toast-success-btn"> Welcome! {userData.firstName} </button>
          
        </div>
        }
        {/* {errorMessage && <div >
            <button  className="btn btn-danger toast-success-btn">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        }  */}
      <div id="adminpage-allcontainer">
      {/* <div className="container-dashboardt pt-5">
    <div className="row align-items-stretch">
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Employees<svg
              className="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
              <path fill="none" d="M0 0h24v24H0z"></path>
             
               <BsPeopleFill/> 
             
            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count"> {advert.TotalOnlineAds}</span>
            <span
            className="hind-font caption-12 c-dashboardInfo__subInfo">Total Number of Employees </span>
        </div>
      </div>
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">On Air Adverts<svg
              className="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <MdGames  /> 
            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{advert.TotalOnlineAds}</span>
            <span
            className="hind-font caption-12 c-dashboardInfo__subInfo"> Total Number of Adverts</span>
        </div>
      </div>
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title"> Programs<svg
              className="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <RiCalendarEventLine/>
            </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count">{programs.TotalPrograms}</span>
            <span
            className="hind-font caption-12 c-dashboardInfo__subInfo">Total Number of Programs </span>
        </div>
      </div>
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title"> Jobs<svg
              className="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <GiOfficeChair/>
            </svg></h4><span className="hind-font caption-12 c-dashboardInfo__count">{jobs.TotalJobs}</span>
            <span
            className="hind-font caption-12 c-dashboardInfo__subInfo">Total Number of Jobs </span>
        </div>
      </div>
    </div>
  </div> */}
        <div className="admin-cards-div" >
         
        <div className="cardstyle" id="wrap">
          <div className="card-head">On Air Adverts  <MdGames  /></div>
          <p className="card-number"> {advert.TotalOnlineAds} </p>
          <p>Total Number of Adverts</p>

          
        
        </div>
       
        <div className="cardstyle2" id="wrap">
          <div className="card-head2">Employees <BsPeopleFill/> </div>
        
          <p className="card-number"> {employees.TotalEmployees} </p>
          <p>Total Number of Employees</p>
        </div>

        <div className="cardstyle1" id="wrap">
          <div className="card-head2"> Programs    <RiCalendarEventLine/></div>
          <p className="card-number"> {programs.TotalPrograms} </p>
          <p>Total Number of Programs</p>
        </div>

        <div className="cardstyle3  " id="wrap">
          <div className="card-head2"> Jobs  <GiOfficeChair/></div>
          <p className="card-number"> {jobs.TotalJobs} </p>
          <p>Total Number of Jobs</p>
        </div>

        </div>
        {
                isPending && 
                <div className="allprograms-card-skeleton">
                <div>
                <h3 className="card-head"></h3>
               
                  <div>
                    <p>
                     
                    </p>
                  </div>
                
                </div>
              </div>
              }



        
          <div>
          <h3 className="card-head">All Programs</h3>
          {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>}

         
            <div >
             {/* <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Program Name</th>
                  <th>program Days</th>
                </tr>
              </thead>
              <tbody>
              {data.map((data,i) => (
                <tr>
                  <td>{i+1}</td>
                  <td>{data.programName}</td>
                  <td>{data.program_days.map((program)=>(
                              program.day
                  ))}</td>
                </tr>
                 ))}
              </tbody>
                <RiDirectionFill /> {" "}
                </table> */}

<table class="table-fill">
<thead>
<tr className="tr-table">
<th className="th-table">S/N</th>
                  <th className="th-table">Program Name</th>
                  <th className="th-table">Program Days</th>
</tr>
</thead>
<tbody class="table-hover">
{data.map((data,i) => (
 <tr className="tr-table">
 <td className="td-table">{i+1}</td>
 <td className="td-table" >{data.programName}</td>
 <td  className="td-table" >{data.program_days.map((program)=>(
             program.day
 )).join(" - ")}</td>
</tr>
))}
</tbody>
</table>
  




            </div>
         
          </div>
       
              
              
      <div className="admintable-div">
        <table className="prog-table">
          <h2 className="card-head">CURRENT PROGRAM</h2>
          <DarkExample />
        </table>

        <table className="prog-table2">
          <h2 className="card-head">TODAY PROGRAMS</h2>
          <DarkExamples />
        </table>
        </div>
      </div>
     
      <AdminFooter/>
      
    </>
  );
}

export default BasicExample;
