import { NavLink } from "react-bootstrap"
import EcommmerceFooter from "./ecocommercefooter"
import { Link } from "react-router-dom"
import {datas} from "./itemdata"



const EcommerceGoods =(()=>{

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
  .formatToParts(0)
  .filter(part => part.type === 'currency')
  .map(part => part.value)
  .join('')
 
  
  return(

        <>
<div className="main-eco-goods">
<h4 className="trending-head">Trending ads</h4>
  <ul className="cards-eco ">
    {
      datas && datas.map((good)=>(
        <li className="cards_item-eco" key={good.id}>
        <Link to={`/goodsdetails/${good.id}`} className="linkselect">
       <div className="card-eco">
         <div className="card_image">
           <img src={good.image.map((image)=>(
                  image.name
           ))
          
          } alt="mixed vegetable salad in a mason jar."  />
           <span className="card_price"><span>{symbol} </span>{good.price} </span>
         </div>
         <div className="card_content">
        <h2 className="card_title">{good.productName} </h2> 
           {/* <div className="card_text">
             <p>Dig into the freshest veggies of the season! This salad-in-a-jar features a mixture of
               leafy greens and seasonal vegetables, fresh from the farmer's market.
             </p>
             <hr className="eco-goods-hr" />
            
           </div> */}
         </div>
       </div>
       </Link>
     </li>

      ))
    }

  
 
  </ul>
</div>
               <div>
              
               </div>
        </>
    )
})
export default EcommerceGoods