import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../utility/axios"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"

let renderCount = 0;


const CreateProms =()=>{

const {register,handleSubmit,formState:{errors}} = useForm();
renderCount ++;
console.log(errors)

const [isPendings, setIsPendings] = useState(false);
const [errorMessage, setErrorMessage] = useState(false);
const [success, setSuccess] = useState(false);
;
const [disabled, setDisabled]= useState(false);

// const [image, setImage] = useState(null);
    
//     const handleImageChange = (e) => {
//         console.log(e.target.files)
//       setImage(e.target.files[0]);
//     };
const [image, setImage] = useState(null);
    
  const handleImageChange = (e) => {
      console.log(e.target.files)
    setImage(e.target.files[0]);
  };


  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  
    });
  })
  

const handleSubmited = async (data)=>{
setDisabled(true)
setIsPendings(true);
  const formData = new FormData();
  formData.append("advertType", data.advertType);
  formData.append("paidStartDate", data.paidStartDate);
  formData.append("paidEndDate", data.paidEndDate);
  formData.append("airStartDate", data.airStartDate);
  formData.append("amountPaid", data.amountPaid);
  formData.append("airEndDate", data.airEndDate);
  formData.append("numberOfTimes", data.numberOfTimes);
  formData.append("advertLink", data.advertLink);
  formData.append("priority", data.priority);
  formData.append("sizeType", data.sizeType);
  formData.append("advertDetails", data.advertDetails);
  formData.append("link", data.link);
  formData.append("image", image);

  
  


  console.log("data", formData)
   await axios.post("adverts/createOnlineAds",formData)
   .then((res)=>{
    console.log("res",res)
    if(data.success === true){
      setIsPendings(false);
      setSuccess(res.data.message)
      setDisabled(false)  
      
      
   }else{
    setSuccess(res.data.message)
      
        setIsPendings(false);
        setDisabled(false)  
   }

  }).catch((err)=>{
      console.log("err", err)
       setIsPendings(false);
       setErrorMessage("Something went wrong, try again later")
       setDisabled(false)  
  }) 
  
  scrolltop() 
}



const[selectOption,setSelectOption]=useState(" ")
const handleselectChange =(event)=>{
    setSelectOption(event.target.value)
}

useEffect(()=>{
     
  let timeout;
  if (success){
     timeout = setTimeout(()=>{
      setSuccess(false);
     },5000)
  }
  return () => {
      clearTimeout(timeout);
  };

  },[success])

  const notify = () => toast("Creating advert...");

  const closeErrorClick =(()=>{
    setErrorMessage(false)
})


    return(
        <>
<div>
            <ul className="action-bar">
                <li>Home /</li>
                <li>Proms/Games/</li>
                <li className="createem">Create Advert</li>
            </ul>
        </div>
        <div className="toaterter-run"> <ToastContainer /></div>
        {
          success && <div >
           
          <button  className="btn btn-success toast-success-btn">{success}</button>
          
        </div>
        }
        {errorMessage && <div >
          <button  className="btn btn-danger toast-success-btn">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 
        {isPendings && 
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
        } 
        
        <form className="createnews-form" onSubmit={handleSubmit((data,event) => {
          event.target.reset();
          setDisabled(true)  
          handleSubmited(data)
        })}>


          <h2 className="createnews-head">Create Advert </h2>
          {/* {   success && <div  className="failednotice" >{success}</div>
        }
        {errorMessage && <div className="failednotice">{errorMessage}</div>} */}

          <label>Advert Type</label><br />
          <select className="newstopic-input" {...register("advertType")} value={selectOption} onChange={handleselectChange} >
            <option value="Paid" >Paid</option>
            <option value="Unpaid" >Unpaid</option>
          </select><br /><br />
          <label>Start paid Date Time  </label><br />
          <input className="newstopic-input" placeholder="Topic..."type="datetime-local" {...register("paidStartDate")} /><br /><br />
          <label>End paid Date Time</label><br />
          <input className="newstopic-input" placeholder="Topic..." type="datetime-local"   {...register("paidEndDate")} /><br /><br />
          <label className="ccpay">On Air Announcement</label><br />
          <label>Start Paid Announcement Date Time</label><br />
          <input className="newstopic-input" placeholder="Topic..." type="datetime-local"  {...register("airStartDate")} /><br /><br />
          <label>End Paid Announcement Date Time</label><br />
          <input className="newstopic-input" placeholder="Topic..." type="datetime-local"  {...register("airEndDate")} /><br /><br />
          <label>Amount Paid</label><br />
          <input className="newstopic-input" placeholder="Topic..." type="number"  {...register("amountPaid")} /><br /><br />
          <label>Number of Times</label><br />
          <input className="newstopic-input" placeholder="Number of times..." type="number"  {...register("numberOfTimes")} /><br /><br />
          <label>Link</label><br />
          <input className="newstopic-input" placeholder="Advert link..." type="text" {...register("link")}   /><br /><br />
          <label>Priority</label><br />
          <select className="newstopic-input"  {...register("priority")} value={selectOption} onChange={handleselectChange}>
            <option value="High">High</option>
            <option value="Normal" >Normal</option>
          </select><br /><br />
          <label>Display Area</label><br />
          <select className="newstopic-input"  {...register("sizeType")} value={selectOption} onChange={handleselectChange} >
            {/* <option value="Top Page Rectangular" >Top Page Rectangular</option> */}
            <option value="Side Page Banner 1" >Side Page Banner 1</option>
            <option value="Side Page Banner 2" >Side Page Banner 2</option>
            <option value="Others" >Others</option>
          </select><br /><br />
          <label>Details</label><br />
          <textarea className="news-details" placeholder="Add Details" {...register("advertDetails")} /><br /><br />
          <label >Upload Image <span className="star-required">*</span></label><br />
          <input type="file" className="uploadcreate-news" name="file" onChange={handleImageChange} required /><br /><br />
          <button className="createnews-btn btn btn-primary" disabled={disabled} type="submit" onClick={notify}>Create Advert</button>
        </form>

        </>
    )
}
export default CreateProms