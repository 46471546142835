import { Button } from "react-bootstrap"
import ProfileNav from "./profilenav"
import { BsPersonFill } from "react-icons/bs"
import { MdOutlinePreview } from "react-icons/md"
import { FaAddressBook } from "react-icons/fa"
import { BiLogOutCircle } from "react-icons/bi"
import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Footer from "../layouts/footer"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { GiReturnArrow } from "react-icons/gi"


const MainProfile = (()=>{

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPage, setShowPage] = useState(false);

  const userData = JSON.parse(localStorage.getItem("ecomUserData"));
  const Navigate = useNavigate();
  useEffect(() => {
    const checkLogin = () => {
      if (userData.statuss === "active") {
       
        setShowPage(true);
      
      }else {
       setShowPage(false);
       Navigate("/ecommerceupper", {state:{}});
     }
    };
    checkLogin();
   }, []);


   const navigation = useNavigate()

   const handleLogout = () => {
     navigation("/ecommerceupper", {state:{}});
     localStorage.removeItem("adminData")
     
   };
 
 


    return(

        <>
        <main className="mainbookinpage-all">
        <ProfileNav/>

            <div className="container-profilemain ">
                <div className="main-body ">


                    {/* <nav aria-label="breadcrumb" className="main-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a >Home</a></li>
                            <li className="breadcrumb-item"><a >User</a></li>
                            <li className="breadcrumb-item active" aria-current="page">User Profile</li>
                        </ol>
                    </nav> */}


                    <div className="row gutters-sm ">
                        <div className="col-md-4 mb-3">
                            <div className="card cardimage-profile">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className="rounded-circle" style={{ width: 150 }} />
                                        <div className="mt-3">
                                            
                                            <p className="text-secondary mb-1">{userData.firstName} {userData.lastName} </p>
                                            <p className="text-muted font-size-sm">{userData.emailAddress} </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-3">
                                <ul className="list-group list-group-flush">
                                    <li> <BsPersonFill /> My Account</li>
                                    <li> <MdOutlinePreview /> Reviews</li>
                                    <li> <FaAddressBook />  Address Book</li>
                                    <hr/>
                                    <li onClick={handleLogout}> <BiLogOutCircle size={30}  />  Logout</li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card mb-3 cardimage-profile1">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Full Name</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                          {userData.firstName} {userData.lastName}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Email</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                           {userData.emailAddress}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Phone</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                        {userData.phoneNumber}
                                        </div>
                                    </div>
                                    <hr />
                                    
                                    
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Address</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                        {userData.address}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <a className="btn btn-info-i " target="__blank" >Edit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row gutters-sm  " id="control-productIt">
                                <div className="col-sm-12 mb-3 checkingcard  ">
                                    <div className="card h-100  cardimage-profile2">
                                        <div className="card-body">
                                            <h6 className="d-flex align-items-center mb-3"><i className="material-icons text-info mr-2"> </i>My Orders</h6>
                                            {/* <div className="ordersdisplay" >
                                                <div className="ordersdisplay-imgdiv">  <img className="ordersdisplay-img" src="https://assets.codepen.io/652/photo-1468777675496-5782faaea55b.jpeg" />
                                                </div>
                                                <div> <h2>Farmstand Salad</h2>
                                                    <p>Quantity: 2</p>
                                                   
                                                    <p>To be delivered between 22 to 24 April</p>
                                                    <h5 className="text-align-center">Contact <span className="contactnum-span">0705657566665</span> For details and enquiries</h5>
                                                    <a  className="bn13 "  onClick={handleShow}>View Details </a>
                                                    
                                                    
                                                </div>
                                                </div> */}
                                                <hr/>

                                               

                                                <div className="ordersdisplay" >
                                                
                                                <div className="centerprofile-pend"> 
                                                    <h2>You don't have any pending order</h2>
                                                    <Link to="/ecommerceupper" className="remove-linkline">  <a  className="bn13 " >Go back to shopping <GiReturnArrow/></a>   </Link>

                                                </div>
                                                </div>

                                               

                                        </div>



                                        
                                    </div>
                                </div>
                               
                            </div>

                            



                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
            </main>

            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              
                 <h5>Farmstand</h5>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Payment Details</Form.Label>
                     <p>Total Amount: #2000</p>
                    <p>Payment Status: Paid</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Delivery </Form.Label>
                 <p>Delivery Method: Door Delivery</p>
                 <p>Expected Delivery Date: 22 to 24 April, 2023</p>
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>

        </>
    )
})
export default MainProfile