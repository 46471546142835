
import present1 from "./../images/bluecurve.jpg"
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utility/axios"
import moment from "moment";
// import present1 from "./../images/bluecurve.jpg"

// import useFectch from "../adminpage/createbackend"

const Presenters = ()=>{

    // const {data,isPending,error} = useFectch('registrations/getUsers');

    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
  
    useEffect(() => {
      axios
        .get("programs/getPrograms")
        .then((response) => {
          console.log("abia>>>>>>>>", response);
          if (!response.statusText === "OK") {
            throw Error("Could not fetch data");
          }
          setIsPending(false);
          setPrograms(response.data);
         
        })
        .catch((error) => {
          setError(error.message);
          setIsPending(false);
          console.log(error);
        });
    }, []);
  
    useEffect(() => {
      const updateCurrentProgram = () => {
        const now = new Date();
        const currentTime = now.getHours() + ":" + now.getMinutes();
        const airingPrograms = programs.filter(
          (program) =>
            currentTime != program.programStartTime &&
            currentTime != program.programEndTime
        );
        setCurrentProgram(airingPrograms);
      };
      updateCurrentProgram();
      const updateInterval = setInterval(() => {
        updateCurrentProgram();
      }, 5000);
      return () => clearInterval(updateInterval);
    }, [programs]);

    const shuffledArray = currentProgram && currentProgram.sort(() => Math.random() - 0.50 ).slice(0, 4)  ;

    const [isShow, setIsShow]= useState(false)

    const ProgramFormatStartTime= programs.forEach((time)=>{
      const startTime = time.programStartTime;
      const endTime = time.programEndTime
  
  const dummyDate = "2023-01-02"
  const combinedDateTime =`${dummyDate}${startTime}`;
  const combinedEndTime =`${dummyDate}${endTime}`;
  
  const paresedTime = moment(combinedDateTime, "YYY-MM-DD h:ma");
  const paresedEndTime = moment(combinedEndTime, "YYY-MM-DD h:ma");
  
  time.programStartTime =  paresedTime.format("h:ma")
  time.programEndTime =  paresedEndTime.format("h:ma")
  
  })
   


    return(
        <>
            <img className="lastline" src={present1} />

            <div className="presenter-all" >

                <h1 className="dailyprograms-head" > Daily Programs </h1>


                <div className="presenters-container"  >

                    
                    {error && <div className="network-errors">{error}</div>}
                    {
                        shuffledArray && shuffledArray.map((program) => (
                          
                            <div className="presenter-div" >
                                 <NavLink to={`/program2/${program.programsId}`}  className="presenternavlink">
                                <img className="img2" src={program.programpresenters.map((image) => image.image)} /><br/>
                                <span className="presentersid-name"> {program.programName}</span> <br/>
                                <span className="presentersid-name"> {program.programpresenters.map((name) => name.firstName)}</span><br/>
                                <span className="presentersid-name">  {program.programStartTime  } : {program.programEndTime}</span>
                                 </NavLink>  
                            </div>
                                
                        ))
                    }


                </div>

          {isPending && <div>
            <div className="presenter-divs-ske">


              <div className="presenter-div" >
                <img className="img2-skeleton" />

              </div>

              <div className="presenter-div" >
                <img className="img2-skeleton" />

              </div>

              <div className="presenter-div" >
                <img className="img2-skeleton" />

              </div>

              <div className="presenter-div" >
                <img className="img2-skeleton" />

              </div>
             </div>

             <div className="box">
                <div className="profile-img" />
                <div className="skeleton-placeholder" />
                <div className="skeleton-placeholder" />
              </div>

          </div>}

        </div>
        </>
    )
}
export default Presenters