import Header from "../layouts/header";
import ModeHeader from "../layouts/modeheader";
import {IoIosArrowDown, IoMdArrowDropdown} from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import Create1 from "./create1";
import BasicExample from "./dashboardpage";
import ViewEmployee from "./viewemployee";
import CreateNews from "./createnews";
import ViewNews from "./viewnews";
import CreateProgram1 from "./createprogram1";
import ViewPrograms from "./viewprograms";
import ViewAdvertRate from "./viewadvertrate";
import CreateAdvertRate from "./createadvert-rate";
import CreateProms from "./createproms";
import UpdateProms from "./updateproms";
import EditCallNumber from "./editcallnumber";
import AddNumber from "./addnumber";
import CreateJobs from "./createjobs";
import viewJobber from "./viewjobs";
import ViewJobber from "./viewjobs";
import {RiCalendarEventLine} from "react-icons/ri";
import CreatePodcastt from "./podcastt";
import {IoMdPersonAdd} from "react-icons/io";
import {BsPeopleFill} from "react-icons/bs";
import {TbWallpaper} from "react-icons/tb";
import {ImNewspaper} from "react-icons/im";
import {RxActivityLog} from "react-icons/rx";
import {TfiPencilAlt, TfiViewListAlt} from "react-icons/tfi";
import {RiAdvertisementFill} from "react-icons/ri";
import {FcAdvertising} from "react-icons/fc";
import {CgGames} from "react-icons/cg";
import {MdOutlineCreateNewFolder, MdOutlineGames, MdOutlineViewHeadline, MdViewDay} from "react-icons/md";
import {AiOutlineFieldNumber} from "react-icons/ai";
import {TbListNumbers} from "react-icons/tb";
import {MdWork} from "react-icons/md";
import {BsBagPlusFill} from "react-icons/bs";
import {MdEvent} from "react-icons/md";
import{SlPeople} from "react-icons/sl"
import{BiNews}from "react-icons/bi"
import {RiAdvertisementLine}from "react-icons/ri"
import{MdGames}from "react-icons/md"
import {BsTelephoneFill}from "react-icons/bs"
import{GiOfficeChair}from "react-icons/gi"
import {MdOutlineRecordVoiceOver}from "react-icons/md"
import{MdSettingsInputAntenna}from "react-icons/md"
import {GiSharpCrown}from "react-icons/gi"
import {GiQueenCrown}from "react-icons/gi"
import ViewPodcast from "./viewappliedjobs";
import {ImListNumbered} from "react-icons/im"
import {GiLaurelCrown} from "react-icons/gi"
import { set } from "react-hook-form";
import CreateAdaogidi from "./createadaogidi";
import ViewAdaogidi from "./viewadaogidi";
import SmallExample from "./viewadaogidi";
import SmallExamples from "./viewemployee";
import HeaderLogin from "../layouts/headerlogin";
import {RiMenuUnfoldFill,RiMenuFoldFill} from "react-icons/ri";

import {BiLogOut}from "react-icons/bi";

import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {ImHome} from "react-icons/im"
import ModifiedNav from "../layouts/modifiednav";
import { HiNewspaper, HiOutlineViewGridAdd } from "react-icons/hi";
import {BiBookReader} from "react-icons/bi"
import ViewCompanyNews from "./viewcompanynews";
import { FaIndustry } from "react-icons/fa";
import { CiViewTable } from "react-icons/ci";
import CreateCompany from "./e-commerce-admin/create-company";
import ViewCompany from "./e-commerce-admin/view-company";
import CreateProduct from "./e-commerce-admin/create-product";
import ViewProduct from "./e-commerce-admin/view-product";
import ViewOrder from "./e-commerce-admin/view-order";
import ViewEcomUsers from "./e-commerce-admin/view-ecom-users";



const SuperAdmin = (()=>{

    const [files, setFiles] = useState(false);
    const [hideSide, setHideSide] = useState('');
    const [hideSides, setHideSides] = useState("");
    const [programDis, setProgramDis] = useState("");
    const [advertRate, setAdvertRate] = useState('');
    const [proms, setProms] = useState("");
    const [editNumber, setEditNumber] = useState("");
    const [jobs, setJobs] = useState("");
    const [podcast, setPodcast] = useState('');
    const [adaOgidi, setAdaOgidi] = useState("");

    const navigation = useNavigate()


    const [expanded, setExpanded] = useState(true);
    const [activeKey, setActiveKey] = useState('1');



    const handleLogout = () => {
        localStorage.removeItem("adminData")
        navigation("/loginpage", {state:{}});
      };
    
    const [menuCollapse, setMenuCollapse] = useState(false)

    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
      };

    const toggleHideSides = () => {
        setHideSides(!hideSides)
    }

    const toggleHideSide = () => {
        setHideSide(!hideSide)

    }

    function showFiles(all) {
        setFiles(all)
        holdFiles()
        console.log(all)
    }
    const programToggle = () =>{
        setProgramDis(!programDis)  
    }
    const AdvertsRate = ()=>{
        setAdvertRate(!advertRate)
    }

    const promsGames = ()=>{
        setProms(!proms)

    }
    const editNumbers = ()=>{
        setEditNumber(!editNumber)
    }
    const jobsShow = ()=>{
        setJobs(!jobs)
    }
    const podCasts =()=>{
        setPodcast(!podcast)
    } 

    const adaOgidis = ()=>{
        setAdaOgidi(!adaOgidi)

    }



    const holdFiles = () => {
        if (files === 'createmployee') {
            return (
                <Create1 />
            )
        } else if (files === "dashboard") {
            return (
                <BasicExample />
            )
        } else if (files === "viewemployee") {
            return (
                <SmallExamples />
            )
        } else if (files === "createnews") {
            return (
                <CreateNews />
            )
        } else if (files === "viewnews") {
            return (
                <ViewNews />
            )
        } else if (files === "createprogram") {
            return (
                <CreateProgram1 />
            )
        } else if (files === "viewprograms") {
            return (
                <ViewPrograms />
            )
        } else if (files === "viewadvertrate") {
            return (
                <ViewAdvertRate />
            )
        } else if (files === "createadvertrate") {
            return (
                <CreateAdvertRate />
            )
        } else if (files === "createproms") {
            return (
                <CreateProms />
            )
        } else if (files === "updateproms") {
            return (
                <UpdateProms />
            )
        } else if (files === "editnumber") {
            return (
                <EditCallNumber />
            )
        } else if (files === "addnumber") {
            return (
                <AddNumber />
            )
         } else if (files === "createjobs"){
           return(
                 <CreateJobs/>
             )
        } else if (files === "viewjobs"){
            return(
               <ViewJobber/>
            )
        } else if(files === "createpodcast"){
            return(
            <CreatePodcastt/>
            )
        }else if(files === "viewcompanynews"){
            return(
            <ViewCompanyNews/>
            )
        }
        else if(files ==="viewpodcast"){
            return(
                <ViewPodcast/>
            )
        }else if(files === "createada"){
            return(
                <CreateAdaogidi/>
            )
        }else if(files === "viewada"){
            return(
                <ViewAdaogidi/>
            )
        }else if(files === "createcompany"){
            return(
                <CreateCompany/>
            )
        }else if(files === "viewcompany"){
            return(
                <ViewCompany/>
            )
        }else if(files === "createproduct"){
            return(
                <CreateProduct/>
            )
        }else if(files === "viewproduct"){
            return(
                <ViewProduct/>
            )
        }else if(files === "vieworder"){
            return(
                <ViewOrder/>
            )
        }else if(files === "vieweusers"){
            return(
                <ViewEcomUsers/>
            )
        }else {
            return (
                <BasicExample />
            )
        }

    }

    return(

        <>
        <ModifiedNav/>
 <div>

<ul className="action-bar">

<li>Home /<span className="addash"> Super Admin</span></li>
</ul>
</div>


<SideNav
onSelect={(selected) => {

}}
className="nowsidecontainer"
>
<SideNav.Toggle />
<SideNav.Nav defaultSelected="home"  >
<NavItem eventKey="home">
<NavIcon>
<ImHome className="side-main-icon"/>
</NavIcon>
<NavText>
<NavLink to="/admindashboard" className="admindash-nav"><li onClick={() => showFiles('dashboard')} className="dashbbb">Super Admin </li></NavLink>
</NavText>
</NavItem>



<NavItem eventKey="2">
<NavIcon>
<SlPeople className="side-main-icon"/>
</NavIcon>
<NavText>
Employee <IoIosArrowDown/>
</NavText>
<NavItem eventKey="2-1">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('createmployee')} ><IoMdPersonAdd className="inicon" size={18} />  Create Employee</li>
</NavText>
</NavItem>
<NavItem eventKey="2-2">
<NavText>
<li className="shift-nav" onClick={() => showFiles('viewemployee')}> <BsPeopleFill size={18} className="inicon" />   View Employee </li>
</NavText>
</NavItem>
</NavItem>


<NavItem eventKey="3">
<NavIcon>
<BiNews className="side-main-icon"/>
</NavIcon>
<NavText>
News   <IoIosArrowDown/>
</NavText>
<NavItem eventKey="3-1">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('createnews')}> <TbWallpaper className="inicon"  size={18}  />   Create News</li>
</NavText>
</NavItem>
<NavItem eventKey="3-2">
<NavText>
<li className="shift-nav"  onClick={() => showFiles('viewnews')}> <ImNewspaper size={18}  className="inicon" />   View News</li>
</NavText>
</NavItem>
</NavItem>

<NavItem eventKey="4">
<NavIcon>
<RiCalendarEventLine className="side-main-icon"/>
</NavIcon>
<NavText>
Programs  <IoIosArrowDown/> 
</NavText>
<NavItem eventKey="4-1">
<NavText>
<li className="shift-nav"  onClick={() => showFiles('createprogram')} >  <TfiPencilAlt className="inicon"  size={18}  />  Create Program </li>
</NavText>
</NavItem>
<NavItem eventKey="4-2">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('viewprograms')}> <RxActivityLog size={18} className="inicon"  />   View Programs</li>
</NavText>
</NavItem>
</NavItem>


<NavItem eventKey="10">
<NavIcon>
<MdGames className="side-main-icon"  /> 
</NavIcon>
<NavText>
Advert   <IoIosArrowDown/>
</NavText>
<NavItem eventKey="10-1">
<NavText>
<li className="shift-nav"  onClick={() => showFiles('createproms')} > <CgGames className="inicon"  size={18}  />  Create Advert</li>
</NavText>
</NavItem>
<NavItem eventKey="10-2">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('updateproms')} > <MdOutlineGames size={18} className="inicon"  />   View Advert</li>
</NavText>
</NavItem>
</NavItem>

<NavItem eventKey="5">
<NavIcon>
<RiAdvertisementLine className="side-main-icon"/> 
</NavIcon>
<NavText>
Advert Rate  <IoIosArrowDown/> 
</NavText>
<NavItem eventKey="5-1">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('viewadvertrate')}  > <FcAdvertising size={18}  className="inicon"  />   View Advert Rate</li>
</NavText>
</NavItem>

</NavItem>

<NavItem eventKey="6">
<NavIcon>
<BsTelephoneFill  className="side-main-icon" />
</NavIcon>
<NavText>
Edit Call-in Numbers  <IoIosArrowDown/>
</NavText>
<NavItem eventKey="6-1">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('editnumber')}> <AiOutlineFieldNumber className="inicon"  size={18} />    Call-in Numbers</li>
</NavText>
</NavItem>
<NavItem eventKey="6-2">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('addnumber')}> <TbListNumbers size={18} className="inicon"  />   Add Number</li>
</NavText>
</NavItem>
</NavItem>


<NavItem eventKey="7">
<NavIcon>
<GiOfficeChair className="side-main-icon"   />
</NavIcon>
<NavText>
Jobs  <IoIosArrowDown/>
</NavText>
<NavItem eventKey="7-1">
<NavText>
<li  className="shift-nav"  onClick={() => showFiles('createjobs')}> <BsBagPlusFill className="inicon"  size={18} />   Create Jobs</li>
</NavText>
</NavItem>
<NavItem eventKey="7-2">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('viewjobs')} > <MdWork size={18} className="inicon" />  View Jobs</li>
</NavText>
</NavItem>
</NavItem>

<NavItem eventKey="8">
<NavIcon>
<MdOutlineRecordVoiceOver  className="side-main-icon" />
</NavIcon>
<NavText>
Jobs Applied  <IoIosArrowDown/>
</NavText>
<NavItem eventKey="8-1">
<NavText>
<li  className="shift-nav" id="shift-nav-applyjob" onClick={() => showFiles('viewpodcast')} > <ImListNumbered className="inicon"  size={20}/>View Applied Jobs</li>
</NavText>
</NavItem>

</NavItem>
<NavItem eventKey="9">
<NavIcon>
<HiNewspaper  className="side-main-icon" />
</NavIcon>
<NavText>
View Company News  <IoIosArrowDown/>
</NavText>
<NavItem eventKey="9-1">
<NavText>
<li  className="shift-nav" id="shift-nav-applyjob" onClick={() => showFiles('viewcompanynews')}> <BiBookReader className="inicon"  size={20}/>View Company News</li>
</NavText>
</NavItem>

</NavItem>

<NavItem eventKey="11">
<NavIcon>
<FaIndustry className="side-main-icon"/>
</NavIcon>
<NavText>
E-commerce   <IoIosArrowDown/>
</NavText>
<NavItem eventKey="11-1">
<NavText>
<li className="shift-nav"  onClick={() => showFiles('createcompany')} >  <MdOutlineCreateNewFolder className="inicon"  size={18}  />  Create Company </li>
</NavText>
</NavItem>
<NavItem eventKey="11-2">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('viewcompany')}> <CiViewTable size={18} className="inicon"  />   View Company</li>
</NavText>
</NavItem>
<NavItem eventKey="11-3">
<NavText>
<li className="shift-nav"  onClick={() => showFiles('createproduct')} >  <HiOutlineViewGridAdd className="inicon"  size={18}  />  Create Product </li>
</NavText>
</NavItem>
<NavItem eventKey="11-4">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('viewproduct')}> <MdViewDay size={18} className="inicon"  /> View Product  </li>
</NavText>
</NavItem>
<NavItem eventKey="11-5">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('vieworder')}> <TfiViewListAlt size={18} className="inicon"  />   View Order</li>
</NavText>
</NavItem>
<NavItem eventKey="11-4">
<NavText>
<li  className="shift-nav" onClick={() => showFiles('vieweusers')}> <MdOutlineViewHeadline size={18} className="inicon"  /> View E-com Users  </li>
</NavText>
</NavItem>
</NavItem>




<NavItem eventKey="12">
<NavIcon >
<BiLogOut className="logoutdownicon" size={30}/>
</NavIcon>
<NavText onClick={handleLogout}>
Log out 
</NavText>


</NavItem>









</SideNav.Nav>
</SideNav>


{/* {
menuCollapse && 

<div className="adminside-nav" collapsed={menuCollapse}>

<ul className="adminside" >

<NavLink to="/admindashboard" className="admindash-nav"><li onClick={() => showFiles('dashboard')} className="dashbbb">Dashboard</li></NavLink>

<hr className="under" />
<li className="adminside-one"><SlPeople className="side-main-icon"/> Employee <IoMdArrowDropdown className="drops" onClick={toggleHideSide} />{
    hideSide &&
    <li className="program-drops">

        <li  className="shift-nav" onClick={() => showFiles('createmployee')} ><IoMdPersonAdd className="inicon" size={18} />  Create Employee</li>

        <li className="shift-nav" onClick={() => showFiles('viewemployee')}> <BsPeopleFill size={18} className="inicon" />   View Employee</li>
    </li>
}



</li>
<hr className="under" />

<li  className="adminside-one"><BiNews className="side-main-icon"/>News   <IoMdArrowDropdown className="drops" onClick={toggleHideSides} />{
    hideSides &&
    <li className="program-drops">

        <li  className="shift-nav" onClick={() => showFiles('createnews')}> <TbWallpaper className="inicon"  size={18}  />   Create News</li>
        <li className="shift-nav"  onClick={() => showFiles('viewnews')}> <ImNewspaper size={18}  className="inicon" />   View News</li>
    </li>



}



</li>
<hr className="under" />
<li className="adminside-one"> <RiCalendarEventLine className="side-main-icon"/> Programs   <IoMdArrowDropdown className="drops" onClick={programToggle}/>{
   programDis &&  
   <li className="program-drops">
   <li className="shift-nav"  onClick={() => showFiles('createprogram')} >  <TfiPencilAlt className="inicon"  size={18}  />  Create Program </li>
   <li  className="shift-nav" onClick={() => showFiles('viewprograms')}> <RxActivityLog size={18} className="inicon"  />   View Programs</li>
</li>

}
   
</li>
<hr className="under" />
<li className="adminside-one" > <RiAdvertisementLine className="side-main-icon"/> Advert Rate   <IoMdArrowDropdown className="drops" onClick={AdvertsRate} />{
 advertRate && <li className="program-drops" >




 <li  className="shift-nav" onClick={() => showFiles('viewadvertrate')}  > <FcAdvertising size={18}  className="inicon"  />   View Advert Rate</li>
</li>

}



</li>
<hr className="under" />
<li className="adminside-one"> <MdGames className="side-main-icon"  /> Advert  <IoMdArrowDropdown className="drops" onClick={promsGames}/>{
    proms &&  <li className="program-drops" >
    <li className="shift-nav"  onClick={() => showFiles('createproms')} > <CgGames className="inicon"  size={18}  />  Create Advert</li>
    <li  className="shift-nav" onClick={() => showFiles('updateproms')} > <MdOutlineGames size={18} className="inicon"  />   View Advert</li>
</li>
}
</li>

   

<hr className="under" />
<li className="adminside-one"> <BsTelephoneFill  className="side-main-icon" />  Edit Call-in Numbers <IoMdArrowDropdown className="drops" onClick={editNumbers}/>{
  editNumber && <li className="program-drops"    >
  <li  className="shift-nav" onClick={() => showFiles('editnumber')}> <AiOutlineFieldNumber className="inicon"  size={18} />    Call-in Numbers</li>
  <li  className="shift-nav" onClick={() => showFiles('addnumber')}> <TbListNumbers size={18} className="inicon"  />   Add Number</li>
</li>

}
    
</li>
<hr className="under" />
 <li className="adminside-one"> <GiOfficeChair className="side-main-icon"   />  Jobs   <IoMdArrowDropdown className="drops" onClick={jobsShow} />{
jobs &&  <li className="program-drops">
<li  className="shift-nav"  onClick={() => showFiles('createjobs')}> <BsBagPlusFill className="inicon"  size={18} />   Create Jobs</li>
<li  className="shift-nav" onClick={() => showFiles('viewjobs')} > <MdWork size={18} className="inicon" />  View Jobs</li>
</li>

}
    
</li>
<hr className="under" />
<li  className="adminside-one"> <MdOutlineRecordVoiceOver  className="side-main-icon" /> Jobs Applied<IoMdArrowDropdown className="drops" onClick={podCasts}  />{
    podcast &&  <li className="program-drops">

    <li  className="shift-nav" onClick={() => showFiles('viewpodcast')} > <ImListNumbered className="inicon"  size={20}/>View Applied Jobs</li>
</li>
}

</li>
<hr className="under" />

<li className="adminside-one">  < MdSettingsInputAntenna  className="side-main-icon" /> Create on Air Promo   <IoMdArrowDropdown className="drops" /></li>
<hr className="under" />


<li className="adminside-one" > < GiQueenCrown  className="side-main-icon" /> Adaogidi Pageant   <IoMdArrowDropdown className="drops" onClick={adaOgidis}/>{

    adaOgidi && <li className="program-drops" >
    <li className="shift-nav" onClick={() => showFiles('createada')}><GiSharpCrown className="inicon"  size={20}/> Create Adaogidi </li>
    <li className="shift-nav" onClick={() => showFiles('viewada')} ><GiLaurelCrown  className="inicon"  size={20}/> List of Winners </li>
    
    </li>
}


</li>
<li className="proms-btn" onClick={handleLogout}>  Log out  <MdOutlineLogout/></li>
</ul>


</div>


} */}



{
holdFiles()
}
        
        
        </>
    )
})
export default SuperAdmin