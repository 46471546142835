
import {GoPlay} from "react-icons/go";
import {CiLogin, CiMicrophoneOn} from "react-icons/ci";
import{ImClock} from "react-icons/im";
import React,{useEffect, useRef, useState} from 'react';
import{TiMediaPause} from "react-icons/ti";
import axios from "../../utility/axios"
import Player from "./player";
import moment from "moment";
// import Player from "../player";


const OnAir = ()=>{

   
   
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
      
    useEffect(()=>{
        axios.get("programs/getPrograms")
        .then(response=>{
          console.log("abia>>>>>>>>",response)
          if (!response.statusText=== "OK"){
            throw Error("Could not fetch data")
          }
          setPrograms(response.data)
          console.log("data>>>>>>>>>",response.data)
        })
        .catch(error =>{
        console.log(error)
        })
       },[]);

    //    const ProgramFormatStartTime= programs.forEach((time)=>{
    //     const startTime = time.programStartTime;
    //     const endTime = time.programEndTime
    
    // const dummyDate = "2022-01-01"
    // const combinedDateTime =`${dummyDate}${startTime}`;
    // const combinedEndTime =`${dummyDate}${endTime}`;
    
    // const paresedTime = moment(combinedDateTime, "YYY-MM-DD h:ma");
    // const paresedEndTime = moment(combinedEndTime, "YYY-MM-DD h:ma");
    
    // time.programStartTime =  paresedTime.format("h:mma")
    // time.programEndTime =  paresedEndTime.format("h:mma")
    
    // })
    

//  console.log("logggggggggggg>>>",ProgramFormatStartTime)

    
       useEffect(()=>{
        const  updateCurrentProgram =(()=>{
            const now = new Date()
            const currentTime = now.getHours() + ':' + now.getMinutes();
            const currentProgram = programs.find(
               (program)=>
              
               currentTime >= program.programStartTime.replace(/^0/, "") &&
                currentTime <= program.programEndTime.replace(/^0/, "")
                 
            );
                           
            setCurrentProgram(currentProgram);
             
        })
        
          updateCurrentProgram()
        const updateInterval = setInterval(()=>{
        updateCurrentProgram(); 
       
       },1000);

      
  
       return () => clearInterval(updateInterval)

       },[programs]);

        console.log("dataummmmmmmm>>>>>>>>>",currentProgram)
     return(
        <>
 
       
            <div className="onair-div">
            <h2 className="onair-head">On Air Now<i><CiMicrophoneOn className="mic-icon" /></i> </h2>
                
                {
                currentProgram ? (
                    <div>
                    <div className="topdisplay-program">
                 
                 <u><h3 className="morning-show">{currentProgram.programName }</h3></u>
                 <p className="onair-time">{ currentProgram.programStartTime
                } - {currentProgram.programEndTime}  <i><ImClock className="time-icon"/></i></p>
              
                 <p className="presenters-name">{currentProgram.programpresenters
                   .map((person)=>(
                    person.firstName   
                 ))
            }</p>
                 </div>
                 <div className="listen-div">
                        
                        
                           
                 </div>
                

             </div>

                ) : (
                    <p className="downbord"> Next program coming soon...</p>
                )
            }
                    
                   <Player/> 
              
                    

             
                <div className="play-div">
                   
           
                </div>
              

            </div>
        </>
    )
}
export default OnAir