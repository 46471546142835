import {MDBBtn,} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus, BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import Footer from "../layouts/footer";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCartTotal, increase, decrease, remove, clearCart, getCartItems } from "../../redux/counter";
import { current } from "@reduxjs/toolkit";
import {datas} from "./itemdata"
import { GiReturnArrow } from "react-icons/gi";

    
function PaymentMethods() {



    
// currency format//
    const symbol = Intl.NumberFormat('en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: 'NGN'
      })
      .formatToParts(0)
      .filter(part => part.type === 'currency')
      .map(part => part.value)
      .join('')
    // 

    const {items, totalAmount} = useSelector((state)=> state.cart);
    const dispatch = useDispatch();

    const totalCount = useSelector((state)=>{
    
        return state.cart.items.length
        // state.cart
    })  

    const [deactivate, setDeactivate] = useState()

  const [disable, setDisable] = useState(false)
//console.log("disable>>", dispatch(decrease()));
  
 useEffect(()=>{
    dispatch(getCartTotal())
   },[items])

   
 if(items.amount === 1){
setDisable(true)
 }

  

 if(items.length === 0){
    return(
        <>
        <div style={{textAlign:"center"}} className="container-sp">
        <h3 className="fs-bold mt-5 " style={{textAlign:"center"}}>
            Your shopping cart is empty
        </h3>
          {/* <MDBBtn onClick={()=>dispatch(getCartItems())}> Get items </MDBBtn> */}
          <Link to="/ecommerceupper" className="remove-linkline">  <a  className="bn13 " onClick={()=>dispatch(getCartItems())}>Go back to shopping <GiReturnArrow/></a>   </Link>
        </div>
        </>
    )
 }
      return (
        <main className="mainbookinpage-all">
        <div className=" container-sp">
        <div className="wrapper wrapper-content animated fadeInRight">
            <div className="row">
                <div className="col-md-9 inner-cartstyle">

                <div className="ibox">
                        <div className="ibox-title">
                            <span className="pull-right">(<strong> {totalCount} </strong>) items</span>
                            <h5>Items in your cart</h5>
                        </div>
                        <div className="ibox-content">
                    {
                      items.map((product)=>(
                        <div className="table-responsive" key={product.id} >

                        <table className="table shoping-cart-table">
                            <tbody>
                            <tr>
                                <td >
                                    <div className="cart-product-imitation">
                                        <img className="cart-product-imitation"src={product.image.map((img)=>(
                                            img.name
                                        ))}/>
                                    </div>

                                    
                                </td>
                                <td className="desc">
                                    <h3>
                                    <a  className="text-navy">
                                        
                                    </a>
                                    </h3>
                                   
                                    <dl className="small m-b-none descriptioncover">
                                        <dt>Short Description </dt>
                                        <dd>{product.shortDescription} </dd>
                                    </dl>
                                    <div className="controlcounter">
                                       <div className="tractminus"  key={product.id} onClick={()=>dispatch(decrease(product.id))} disabled={disable} value={product.amount} onChange={()=>setDeactivate(product.amount)}><i><AiOutlineMinus size={30}/></i></div>
                                        <div className="incrementnum"> <span> {product.amount} </span></div> 
                                      <div className="trackplus" key={product.id} onClick={()=>dispatch(increase(product.id))} > <i><BsPlus size={30}/></i></div>
                                   </div>

                                    <div className="m-t-sm" onClick={()=>dispatch(remove(product.id))} style={{cursor:"pointer"}}>
                                       
                                        <a className="text-muted"><i className="fa fa-trash" ></i> Remove <span className="item-itemRemove">item</span></a>
                                    </div>
                                    
                                </td>
                                <hr/>

                              <td>
                                    <p>
                                    Price:  <p className="boldtesting"> {symbol}  {product.price}  </p>
                                    </p>
                                   
                                </td>
                                
                            </tr>
                            

                            </tbody>
                        </table>
                        <hr/>       
                    </div>

                      ))  
                    }
                    </div>
                       
                    <div className="ibox-content">
                           
                           <Link to="/ecommerceupper"> <button className="btn btn-white continuebtn-shopping button-24"><i className="fa fa-arrow-left"></i> Continue shopping</button></Link>
                           


                           <button   className="button-24" role="button"  onClick={()=>dispatch(clearCart())} >Clear Cart</button>
        
                        </div>
                    </div>


                    
        
                </div>
                <div className="col-md-3">
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>Cart Summary</h5>
                        </div>
                        <div className="ibox-content">
                            <span className="totalspan">
                                Total Amount
                            </span>
                            <h2 className="font-bold">
                                {symbol}{totalAmount}
                            </h2>
        
                            <hr/>
                           
                            <div className="m-t-sm">
                               
                               <Link to="/signinpage"> <button className="button-6" role="button">CHECKOUT</button></Link>
                              
                            
                                
                                
                            </div>
                        </div>
                    </div>
        
                   
                    </div>

                </div>
            </div>
        </div>
        {/* <Footer/> */}
        </main>
    );
    }
    export default PaymentMethods