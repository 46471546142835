
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import EcommerceNav from './ecommerce_nav';
import Footer from '../layouts/footer';
import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import ProfileNav from './profilenav';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import { MdPerson3 } from 'react-icons/md';
import PayButton from '../PayButton';
import { PaystackButton } from 'react-paystack';
import { Toaster, toast } from 'react-hot-toast';
import EcommerceUpper from './ecommerce_upper';
import useFectch from '../adminpage/createbackend';
import axios from '../../utility/axios';
import { id } from 'date-fns/locale';
import { clearCart, decrease } from '../../redux/counter';

// import {AiOutlineClose } from "styled-icons/material/Close";
// import { Menu } from "styled-icons/material/Menu";



let renderCount = 0;




const BookingPage = (()=>{
    const {register,handleSubmit,formState:{errors}} = useForm();
    renderCount ++;
    console.log(errors)

    const [states, setStates]= useState([])
    const [localGvt, setLocalGvt] = useState([])
    
    
    const [showPage, setShowPage] = useState(false);
    const publicKey = "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849"
    const [firstName, setFirstName] =useState();
    const [lastName, setLastName] =useState();
    const [phoneNumber,setPhoneNumber] =useState();
    const [additionNumber,setAdditionNumber] =useState();
    const [emailAddress, setEmailAddress] = useState();
    const [deliveryAddress,setDeliveryAddress] = useState();
    const [addtionInfo,setAddtionInfo] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState()
    const amount = 100000
       

    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false)
const [lgShow, setLgShow] = useState(false);
const [newLga, setNewLga] = useState([])

const dispatch = useDispatch();

const handleClearCart = (()=>{
  dispatch(clearCart())
})

// currency format//
const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
  .formatToParts(0)
  .filter(part => part.type === 'currency')
  .map(part => part.value)
  .join('')
// 
const {items, totalAmount} = useSelector((state)=> state.cart);

const handleFirstModal = (()=>{
    setModalShow(true);
    setLgShow(false);


})
const Navigate = useNavigate();
const handlePaystackSuccessAction = (reference) => {
  handleClearCart()
    Navigate("/mainprofile", {state:{}});
 
}

const [alert, setAlert]= useState()
const handlePaystackCloseAction = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
 
  Navigate("/accountpage", {state:{}});
};



const componentProps = {
    email: emailAddress,
    amount: totalAmount * 100,
   
    metadata:{
        
        fullname: firstName,
        phone: phoneNumber,
        additionNumber,
        deliveryAddress,
        addtionInfo,
        state,
        city
    },

    publicKey: "pk_test_0a9e6a807ac7b5d5cc2ccb0a25e5aa429f45a849 ",
    text:'PAY NOW',
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  
  const [success, setSuccess] = useState(false)

const userData = JSON.parse(localStorage.getItem("ecomUserData")); 
const loggedInCheck =(()=>{
    if(userData == 1){
      return <div>{userData.firstName} logged in!</div>
    }
})

useEffect(() => {
 const checkLogin = () => {
   if (userData.statuss === "active") {
     setSuccess("Welcome! You successfully logged in")
     setShowPage(true);
     setEmailAddress(userData.emailAddress)
     loggedInCheck()
   }else {
    setShowPage(false);
    
  }
 };
 checkLogin();
}, []);


 useEffect(()=>{
    const getState = async () =>{
      const resState = await axios.get('https://ipms.intellims.com:5300/api/v1/public/states')
     
      const relState = await resState.data
      setStates(await relState)
    }
  
    getState()
  },[])

  
useEffect(()=>{
  const getLocalG = async () => {
    const localLga = await axios.get(
      "https://ipms.intellims.com:5300/api/v1/public/local_govt"
    );

    const localGvt = await localLga.data;
   
    setLocalGvt(localGvt)

  };
  
  
  getLocalG()
},[])



const getMainLGA = (e) => {
  const stateId = e.target.value
  console.log('localGvt ==>', localGvt);
 
  const filterLga =  localGvt.filter((lga)=>(
    lga.state_id === stateId
)) 

  setNewLga(filterLga);
};

 
    return(

        <>




        <main className='mainbookinpage-all'>
    

           <ProfileNav/>   
            <div className='booking-container'>
              
                <div className='bookingform-main'>
                    <Form className='booking-form-container' >
                        <p className='filld-form'> Complete the form to finalize your order</p>

                    <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className='lb'>First name</Form.Label>
                                <Form.Control placeholder="Enter your name" 
                                // {...register("firstName")} 
                                onChange={(e) =>setFirstName(e.target.value) }
                                defaultValue={userData.firstName}
                                
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className='lb'>Last name</Form.Label>
                                <Form.Control  placeholder="Last name.."
                                //  {...register("lastName")}
                                onChange={(e) =>setLastName(e.target.value) }
                                defaultValue={userData.lastName}
                                 />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3 " >
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className='lb' >Phone number</Form.Label>
                                <Form.Control type="number" placeholder="Phone number..." 
                                // {...register("phoneNumber")}
                                defaultValue={userData.phoneNumber}
                                onChange={(e) =>setPhoneNumber(e.target.value) }
                                className='controlinput-num'
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label  className='lb'>Additional number</Form.Label>
                                <Form.Control type="number" placeholder=" Enter additional..."
                                //  {...register("additionNumber")}
                                 onChange={(e) =>setAdditionNumber(e.target.value) }

                                 />
                            </Form.Group>



                            <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label  className='lb'>Email Address</Form.Label>
                                <Form.Control type="email" placeholder=" Enter your email..." 
                               
                                   value={userData.emailAddress}
                                // {...register("emailAddress")} 
                                />
                            </Form.Group>
                        </Row>
                    

                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label className='lb'>Delivery Address</Form.Label>
                            <Form.Control placeholder="Enter your delivery address..."
                            //  {...register("deliveryAddress", { required: "This field  is required" })} 
                            onChange={(e) =>setDeliveryAddress(e.target.value) }
                             
                             />
                            {/* <span className="cum-error">{errors.deliveryAddress?.message}</span> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Label className='lb'>Additional Information </Form.Label>
                            <Form.Control placeholder="Enter additional info..." className='controlinput-num'
                             onChange={(e) =>setAddtionInfo(e.target.value) }
                            // {...register("addtionInfo")} 
                            />
                        </Form.Group>

                        <Row className="mb-3 " >
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className='lb' >State</Form.Label>
                                <Form.Select defaultValue="Choose..."
                                 onChange={(e) =>setState(e.target.value) }
                                 {...register("state")}
                                onClick={(e) => getMainLGA(e)}
                                 > 
                                  
                                  <option>Choose...</option>
                                  {
                                    states.map((state)=>(
                                       <option key={state.stateId} value={state.stateId}> {state.name} </option> 
                                    ))
                                  }
                                    
                                   
                                 
                                    
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label  className='lb'>Local government</Form.Label>
                                <Form.Select defaultValue="Choose..."
                                 setCity 
                                 onChange={(e) => setCity (e.target.value) }
                                   
                                 {...register("city")}
                                > 
                                    <option>Choose...</option>
                                    {
                                    newLga && newLga.map((lga, i)=>(
                                    <option key={i.id}> {lga.name} </option>
                                    )) 
                                    }
                                    
                                </Form.Select>
                            </Form.Group>



                        </Row>



                        <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Label className='lb'> <span className='redNote-show'>Note: </span>Please enter and save your correct contact details before you proceed</Form.Label>
                        </Form.Group>

                        <Button id='conorder' type="submit"  >
                            Save
                        </Button>
                        <Link to="/accountpage"><Button variant="secondary" type="cancel" className='m-2'>
                            Cancel
                        </Button></Link>
                    </Form>




                </div>
                <div className="sidedetails-container">
                    <p>Order summary</p>
                    <hr/>
                    <p className='total-amount-sid'>Total Amount: {symbol} {totalAmount} </p>
                    <hr/>
                    <div>
                            
                        <button className="button-6" id='confirmorder' role="button" > <PaystackButton  {...componentProps } className='paystacbtn'/></button>
                        <button id="showcontact-btn" className="button-6" role="button" onClick={()=>setLgShow(true)} >PAY ON DELIVERY</button> 
                        <p className='pwarning'>(Complete the steps in order to proceed)</p>
                        <p className='pwarning2'> <AiOutlineWarning/> By proceeding  you are automatically accepting the Terms & Conditions</p>


                      


                    </div>
                </div>
            </div>

            <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Pay when the product is delivered
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>PLACE YOUR ORDER?</p>
       <div className='place-order-warnContainer'>
       <button  className="btn-remove btn btn-primary" type="submit" onClick={() =>handleFirstModal()} >Order</button> 
       <button  className="btn-remove btn btn-secondary" type="submit" onClick={()=>setLgShow(false)}  >Cancel</button>
       </div>
       </Modal.Body>
       
      
      </Modal>

      <Modal
      size="lg"
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
       
      </Modal.Header>
      <Modal.Body>
        <h4>Thank you for choosing Ebeonadi e-commerce!</h4>
        <p>
          You have successfully completed your order, feel free to explore some other related products
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/mainprofile"><Button >Check out your profile <MdPerson3/> </Button></Link>
       <Link to="/ecommerceupper"> <Button className='btn btn-secondary' onClick={()=>setModalShow(false)}>Close</Button></Link>
      </Modal.Footer>
    </Modal>

            <Footer/>
            </main>
        </>
    )
})
export default BookingPage