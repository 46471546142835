import {RiArrowDropDownLine} from "react-icons/ri"
import {useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from "./createbackend";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "../../utility/axios"
import { Alert } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"


function ViewProgram() {

  const [action, setAction]= useState('')
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);

  const [post, setPost] = useState([]);
  const [showMore, setShowMore] = useState(false)

  const actionButton= ()=>{
   setAction(!action)
 
 
  }
  const notify = () => toast("Processing your request..."); 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

// useEffect(()=>{
//   axios
//      .get("")
//      .then((data))
//      console.log();
//      setPost(data?.data)
// });

const {data,isPending,error} = useFectch("programs/getPrograms")
console.log("programs>>>>>>>>>",data)

const {data:presenters} = useFectch("registrations/getUsers")

  // delete/////////////////////////////////////////////

  const [errorMess, setErrorMess] = useState("");
 const [successMessage, setSuccessMessage] = useState("");
const [programId, setProgramId] = useState("")

  const handleSubmitDelete =()=>{
    //  event.preventDefault();
    axios.put(`programs/removeProgram/${programId}`)
    .then(response =>{
      console.log('emeka>>>>>>>>>>>>',response)
      if(response){
        setSuccessMessage("Program Removed Successfully");
        window.location.reload();
  
     }else{
       setSuccessMessage("Program Removed Successfully");  
      setErrorMess(data.message);
     } 
      })
    .catch(error=>{
      console.log(error);
    });

    setLgShow(false)
    
  
  }
const toggleDelete = (program) =>{
  console.log("programId", program.programsId)
  setProgramId(program.programsId)
  notify()
  setLgShow(true)
}
console.log("programmmmmmmm>",programId);
//////update section///////////////////////////////////

const [employeeData, setEmployeeData] = useState()

const [programType, setProgramType] = useState('');
const [paidStartTime, setPaidStartTime] = useState('');
const [paidEndTime, setPaidEndTime] = useState('');
const [amountPaid, setAmountPaid] = useState('');
const [ programTitle, setProgramTitle] = useState('');
const [programOtherNames, setProgramOtherNames] = useState('');
const [ programDetails, setProgramDetails] = useState('');
const [programDay, setProgramDay] = useState('');
const [programStartTime, setProgramStartTime] = useState('');
const [programEndTime, setProgramEndTime] = useState('');
const [programpresenters, setProgrampresenters] = useState('');
const [presenter, setPresenter] = useState('');
const [callInNumber, setCallInNumber] = useState('');
const [presenterSocialMediaHandle, setPresenterSocialMediaHandle] = useState(null);
const [programStatus, setProgramStatus] = useState(null);
// const [image, setImage] = useState(null);


const initialValues = {
  programType: employeeData && employeeData.programType,
  paidStartTime: employeeData && employeeData.paidStartTime,
  paidEndTime: employeeData && employeeData.paidEndTime,
  amountPaid: employeeData && employeeData.amountPaid, 
  programTitle: employeeData && employeeData.programTitle,
  programOtherNames: employeeData && employeeData.programOtherNames,
  programDetails: employeeData && employeeData.programDetails,
  programDay: employeeData && employeeData.programDay,
  programStartTime: employeeData && employeeData. programStartTime,
  programEndTime: employeeData && employeeData.programEndTime,
  programpresenters: employeeData && employeeData.programpresenters,
  callInNumber: employeeData && employeeData.presenter,
  presenterSocialMediaHandle: employeeData && employeeData.presenterSocialMediaHandle,
  programStatus: employeeData && employeeData.programStatus,
}
const [formValues, setFormValues] = useState(initialValues)
const application = {programType,paidStartTime,paidEndTime,amountPaid,
  programTitle,programOtherNames,programDetails
  ,programDay,programStartTime,programEndTime,
  programpresenters,presenter,presenterSocialMediaHandle,programStatus}

const [isPendingUpdate, setIsPendingUpdate] = useState("")

const toggleProgram = (program) => {
  console.log("newsID", program.programsId)
   setProgramId(program.programsId)
   setEmployeeData(program)
   setShow(true)

}
console.log("apppppppp",application);
const handleSubmits = async (data,event)=>{
  event.preventDefault();
  console.log("data", data);
  const fullData = {
      ...data,
      ...application,
      programPresenters: [{userId: data.programPresenters}]
  };
  console.log('fullData', fullData);
   await axios.put(`programs/updatePrograms/${programId}`,fullData)
    
   .then((res)=>{
      console.log("res>>>>>>>>>>>>",res)
      if(data.success === true){
          setSuccessMessage("Program Updated Succefully")  
          console.log('success>>>>>',successMessage);
          // window.location.reload();
       }
       else{
        // window.location.reload();
      //      setErrorMess(res.data.message)
      //      console.log('error>>>>>',errorMess);
       }
      
  }).catch((err)=>{
      console.log("err", err)
  }) 
  // console.log("networkcall", networkcall)

}

// const handleSubmits = async (presenters, event)=>{
//    event.preventdefault();
//   console.log("data>>>>>", data);
//   const fullData = {
//     ...data,
//       ...application,
//       programpresenters: [{userId: presenters.programpresenters}]
//   };
//   console.log('fullData', fullData);
//    await axios.put(`programs/updatePrograms/${programId}`,fullData,selectOption)
    
//    .then((res)=>{
//       console.log("res>>>>>>>>>>>>",res)
//       if(data.success === true){
//           setSuccessMessage("Program Updated Succefully") 
//           setIsPendingUpdate(false) 
//           // console.log('success>>>>>',success);
          
//        }else{
//            setErrorMess(res.data.message)
//            setIsPendingUpdate(false) 
//        }
      
//   }).catch((err)=>{
//       console.log("err", err)
//   }) 
//   // console.log("networkcall", networkcall)

// }
const[selectOption,setSelectOption]=useState(false)
const handleselectChange =(event)=>{
  setSelectOption(event.target.value)
}


const handleCloseUpdate = () => {
  handleSubmits()
  setShow(false)

}
useEffect(()=>{
     
  let timeout;
  if (successMessage ){
     timeout = setTimeout(()=>{
      setSuccessMessage(false);
     },5000)
  }
  return () => {
      clearTimeout(timeout);
  };

  },[successMessage])


  
  const closeErrorClick =(()=>{
    setErrorMess(false)
})



  return (

    <>


<div>
             <ul className="action-bar">
                 <li>Home /</li>
                <li>Programs/</li>
                <li className="createem">View Programs</li>
            </ul>
         </div> 
         {
          successMessage && <div >
           
          <button  className="btn btn-success toast-success-btn">{successMessage}</button>
          
        </div>
        }
        {errorMess && <div >
          <button  className="btn btn-danger toast-success-btn">{errorMess} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 

{error &&  <button  className="btn btn-danger toast-success-btn">{error }</button>
          }
<h2 className="viewemployee-head">Program List</h2>
 
    <Table striped bordered hover size="sm" className='adaogidi-table'>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Title</th>
          <th>Details</th>
          <th>Days</th>
          <th>Presenter</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>Program Type</th>
          <th>Amount Paid</th>
          <th>Program Status</th>

          <th>Action</th>
        </tr>
      </thead>
      {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>}
      {data && data.map((program, i)=>(
 <tbody>
 <tr>
   <td>{i+1}</td>
   <td>{program.programName}</td>
     <td>{program.programDetails && program.programDetails.substring(0,50)}...</td>     
       <td>{program.program_days.map((days)=>(
               days.day
       )).join("-")
}</td>
      
       <td>{program.programpresenters.map((present)=>(
        present.firstName 
  ))
}</td>
  <td>{program.programStartTime}</td>
   <td>{program.programEndTime}</td>
   <td>{program.programType}</td>
   <td>{program.amountPaid}</td>
   <td>{program.programStatus}</td> 
   
   <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
        <Dropdown.Toggle id="dropdown-autoclose-outside">
          Action
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdownshow-d">
          <Dropdown.Item className="sideside" disabled onClick={()=>toggleProgram(program)}  >Update</Dropdown.Item>
          <Dropdown.Item  className="sideside" onClick={() =>toggleDelete(program)} >Remove Program</Dropdown.Item>
          
        </Dropdown.Menu>
      </Dropdown>
      
 </tr>

 
</tbody>

      ))}
     
    </Table>
<form onSubmit={handleSubmits((data,event)=>{
          event.preventdefault();
        console.log("create program data", data)
         handleSubmits(data)
    })}>
    <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            
        
        
        <label>Program Type </label>
        <select className="createp-inputs" defaultValue={employeeData && employeeData.topic}
         onChange={(e) => setProgramType(e.target.value)} value={selectOption}>
            <option value="Regular">Regular</option>
            <option value="Paid" >Paid</option>
               
        </select><br/><br/>

        <label className="ccpay">Section for only paid programs</label><br/>
                <label>Start Date</label><br/>
                <input className="createp-inputs" placeholder="Start Date" type="datetime-local" name="paidStartTime"
                defaultValue={employeeData && employeeData.paidStartTime}
                 onChange={(e) => setPaidStartTime(e.target.value)}
                /><br/>
                <label className="">End Date</label><br/>
                 <input className="createp-inputs" type="datetime-local" name="paidEndTime" 
                  defaultValue={employeeData && employeeData.paidEndTime}
                   onChange={(e) => setPaidEndTime(e.target.value)}
                 /> <br/> 
                 <label>Amount Paid</label>
                 <input type="number" className="createp-inputs" placeholder="#2000..." name="amountPaid" 
                  defaultValue={employeeData && employeeData.amountPaid}
                   onChange={(e) =>  setAmountPaid(e.target.value)}
                 
                 /><br/>
                 <hr className="underline-program"/>

        <label>Program Title</label><br/>
        <input className="createp-inputs" name="programTitle"
         defaultValue={employeeData && employeeData.programTitle}
          onChange={(e) => setProgramTitle(e.target.value)}
        
        /><br/><br/>
        <label>Program other name</label><br/>
        <input className="createp-inputs" placeholder="Second title..." name="programOtherNames"
         defaultValue={employeeData && employeeData.programOtherNames}
          onChange={(e) => setProgramOtherNames(e.target.value)}
        
        /><br/><br/>
      
        <label>Program Details</label><br/>
        <textarea className="createp-contents" placeholder="Content..." name="programDetails"
         defaultValue={employeeData && employeeData.programDetails}
          onChange={(e) => setProgramDetails(e.target.value)}
        /><br/><br/>
        <label>Select Day </label>
        <select className="createprogram-sele" name="programDay" value={selectOption} onChange={handleselectChange}
        
        >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday" >Wednesday</option>
            <option value="Thursday" >Thursday</option>
            <option value="Fridayday" >Friday</option>
            <option  value="Saturday" >Saturday</option>
            <option  value="Sunday" >Sunday</option>
            <option  value="All" >All</option>
        </select><br/><br/>
        <label>Start Time </label>
        <input className="createp-inputs" placeholder="Start time" type="time" name="programStartTime"
        defaultValue={employeeData && employeeData.programStartTime} 
        onChange={(e) => setProgramStartTime(e.target.value)}
        /><br/><br/>
        
        <label>End Time </label>
        <input className="createp-inputs" placeholder="Stop time" type="time"  name="programEndTime"
        defaultValue={employeeData && employeeData.programEndTime}
         onChange={(e) => setProgramEndTime(e.target.value)}
        
        /><br/><br/>
        
        <label>Select Presenter </label>
        <select className="createp-inputs" value={selectOption} onChange={handleselectChange} name="programpresenters"
       
        >
        {
                presenters.map((presenter)=>(
                  
                    <option value={presenter.userId}>{presenter.lastName} {presenter.firstName}</option>
                ))
            }
        </select><br/><br/>
        <label>Enter Presenter</label>
        <input className="createp-inputs" placeholder="Enter presenter..." name="presenter"
        defaultValue={employeeData && employeeData.presenter} onChange={(e) => setPresenter(e.target.value)}
         /><br/><br/>
        <label>Program call-in Number</label><br/>
        <input className="createp-inputs" placeholder="Enter call-in number..." name="callInNumber"  
        defaultValue={employeeData && employeeData.callInNumber} onChange={(e) => setCallInNumber(e.target.value)}
        
        /><br/><br/>
        <input  className="createp-inputs" placeholder="Social media handle..."name="presenterSocialMediaHandle"
        defaultValue={employeeData && employeeData.presenterSocialMediaHandle} onChange={(e) => setPresenterSocialMediaHandle(e.target.value)}
        
        /><br/><br/>
        <label>Program Status</label><br  value={selectOption} onChange={handleselectChange}/>
        <select className="createp-inputs"name="programStatus" >
            <option value="Active">Active</option>
            <option value="Host on Leave" >Host on Leave</option>
            <option value="Suspended" >Suspended</option>
            <option  value="Others">Others</option>
           
        </select><br/><br/>
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseUpdate}>
            Save Changes
          </Button>
         
        </Modal.Footer>
      </Modal>
      </form>
{/* remove program */}

     <form onSubmit={handleSubmitDelete}>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Remove Program
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to Remove the Selected Program?</p>
       <button  className="btn-remove btn btn-danger" onClick={() =>handleSubmitDelete()} >Remove Program</button> 
       </Modal.Body>
       </Modal>

      </form>
    </>
  );
}

export default ViewProgram;
