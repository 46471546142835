import FirstHeader from "../layouts/firstheader"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"
import ReplyBox from "./replybox"
import newsImage2 from "../images/Newspapers-.jpg";
import Footer from "../layouts/footer";
import Changer from "../layouts/changer";
import { useParams } from "react-router-dom";
import useFectch from "../adminpage/createbackend";
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from "../images/logo fm2.jpg"
import { useState } from "react";
import axios from "../../utility/axios"
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import SecondSideAdvert from "./secondsideadvert";
import FirstSideAdvert from "./firstsideadvert";
import moment from "moment";


let renderCount = 0;


const TopNews = () => {
  const { newsTopic } = useParams();

  const { data: news, isPending, error } = useFectch('programs/getNews');


  const { data: comment } = useFectch('programs/get_comments');

  console.log("comment get>>>>>", comment);

  const newArr = news.filter((id) =>

    id.newsId === newsTopic

  )

  const newComment = comment.filter((id) => (

    id.newsId === newsTopic
  ))

  console.log("newwwww Reeeee>>>>>>>", newArr);


  const { register, handleSubmit, formState: { errors } } = useForm();
  renderCount++;
  console.log(errors)
  const [isPendings, setIsPendings] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmited = async (data) => {
    // event.preventDefault()
    setIsPendings(true);
    console.log("data", data)
    await axios.post("programs/create_comments", data)

      .then((res) => {
        console.log("res", res)

        if (res.data.success) {
          setIsPendings(false);
          setSuccess("Comment Submited Successfully");
          setErrorMessage("");
          window.location.reload();
        } else {
          setIsPendings(false);
          setErrorMessage(res.data.message);
        }

      }).catch((err) => {
        setIsPendings(false);
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
      })
    // console.log("networkcall", networkcall)
    scrolltop()

  }

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 3000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])

  const scrolltop = (()=>{
    window.scrollTo({
        top:0,
        behavior: "smooth"

    });
})



  const [status, setStatus] = useState(false);
  const [type, setType] = useState("Success");
  const [title, setTitle] = useState("Comment Alert");


  


  return (
    <>
      <div className="topnewsbody">
        <ModeHeader />
        <Changer />
        <FirstHeader />
        <Newsfeed />

        {isPending && <div className="loaderless">
          <div className="lds-ripple"> Loading... <div></div><div></div></div>

        </div>}
        {error && <div className="errorssample">{error}</div>}
        {
          newArr && newArr.slice(0, 8).map((news) => (
            <div className="displaymainnews-container" key={news.newsId}>

              <div className="">
                <h1 className="news-head"> {news.newsTopic} </h1>
                <p className="topnews-time">{moment(news.startDateTime).fromNow()
                }</p>

                <div className="newsall-container">

                  <div className="ne-container">
                   
                    <img className="img-new" src={news.image} />
                    <div className="detailsNews">  {news.newsDetails}   </div>
                  </div>
                  <div className="newsadvert-container">
                    <FirstSideAdvert />

                  </div>

                </div>

              </div>

              <div className="allcomments-container">
                <div className="commentdiv-head">Comments (...)</div>
                <div className="news-comment-underline"></div>

                {

                  newComment && newComment.map((comment) => (
                    <div className="imgreply-box">
                      <img className="imgreply" src={logo} />
                      <div className="replynametext">
                        <div className="replymainname">{comment.name}</div>
                        <div className="replymaintext">{comment.comments}</div>
                        <hr className="comment-line" />

                      </div>
                    </div>

                  ))
                }
                {errorMessage && <div className="alert alert-error">
                  <div className="icon__wrapper">
                    <span className="mdi mdi-alert-outline"></span>
                  </div>
                  <p>{errorMessage}</p>
                  <span className="mdi mdi-open-in-new open"></span>
                  <span className="mdi mdi-close close"></span>
                </div>


                }

              </div>
              {
                success &&
                <div className="alert alert-success">
                  <div className="icon__wrapper">
                    <span className="mdi mdi-alert-outline"></span>
                  </div>
                  <p>{success}</p>
                  <span className="mdi mdi-open-in-new open"></span>
                  <span className="mdi mdi-close close"></span>
                </div>
              }



              {isPendings &&
                <div className="loaderless" id=""> <div className="lds-ripple"> Loading... <div></div><div></div></div>
                </div>

              }



              <Form className="displayblocks" onSubmit={handleSubmit((data, event) => {
                event.target.reset()
                console.log("comment>>>>>details", data)
                handleSubmited(data)
              })} >
                <p className="displayreply-head"> Leave Comment </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control hidden defaultValue={newsTopic}  {...register("newsId")} />
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email"  {...register("emailAddress", { required: "This filed is required" })} />
                  <p className="cum-error">{errors.emailAddress?.message}</p>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter first name"   {...register("name", { required: "This filed is required" })} />
                  <p className="cum-error">{errors.name?.message}</p>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Comment</Form.Label> <br />
                  <textarea className="comment-textarea"  {...register("comments", { required: "This filed is required" })} />
                  <p className="cum-error">{errors.comments?.message}</p>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
                <Button variant="primary" type="submit" className="btn-center">
                  Comment
                </Button>
              </Form>

              {/* <ReplyBox/> */}


            </div>
          )



          )
        }



      </div>
      <Footer />
    </>
  )
}
export default TopNews