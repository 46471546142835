import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import logo from "../images/human.png"
import { AiOutlineMenu } from "react-icons/ai";



const MobileNav =()=>{

   const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };
 
    
return(
<>

<header className="Header-fornav">
      <h3 className="ebetest-nav">Ebeonadi</h3>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <ul className="Nav-fornav">
          <li href="/" className="homehover">Home <li className="letname-test">
            <li>home</li>
            <li>home</li>
            <li>home</li>
          </li>
          </li>
          <li href="/" className="homehover" >Articles <li className="letname-test">
            <li>home</li>
            <li>home</li>
            <li>home</li>
          </li> </li>
          <li href="/"  className="homehover">About</li>
          
        </ul>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <AiOutlineMenu className="icon-drop-test"/>
      </button>
    </header>
    
           
                    
                


</>

)}
export default MobileNav