import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import ExampleNow from "./accessmodal";
import axios from "../../utility/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md";
import { useNavigate } from "react-router-dom";



let renderCount = 0;

const Create1 = () => {

  const [isPending, setIsPending] = useState(false);


  const { register, handleSubmit, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    console.log(e.target.files)
    setImage(e.target.files[0]);
  };

  const handleSubmited = async (data) => {
    //   console.log('addddddddd',data);
    setDisabled(true)
    setIsPending(true)
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("socialMediaHandle", data.socialMediaHandle);
    formData.append("emailAddress", data.emailAddress);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("department", data.department);
    formData.append("password", data.password);
    formData.append("hasAccess", data.hasAccess);
    formData.append("image", image);

    await axios.post("registrations/createUser", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })

      .then((res) => {
        console.log("res", res)
        if (res.data.success) {
          setIsPending(false)
          setSuccess("Employee Created Successfully");
          setErrorMessage("");
          setDisabled(false)
          //  navigate(1)
        } else {

          setErrorMessage(res.data.message);
          setIsPending(false)
          setDisabled(false)
          //  navigate(1)
        }

      }).catch((err) => {
        console.log("err", err)
        setErrorMessage("Something went wrong. Please try again later.");
        setIsPending(false)
        setDisabled(false)
      })

    scrolltop()

  };

  const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"

    });
  })

  const [disabled, setDisabled] = useState(false)


  const [selectOption, setSelectOption] = useState("");

  const handleselectChange = (event) => {
    setSelectOption(event.target.value);
  };
  const [showAccess, setShowAccess] = useState(false)
  const displayAccess = (() => {
    setShowAccess(!showAccess)
  })

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);
    };

  }, [success])



  const closeErrorClick = (() => {
    setErrorMessage(false)
  })


  const notify = () => toast("Creating employee...");

  return (
    <>
      <div>


        <ul className="action-bar">


          <li>Home /</li>
          <li>Employee/</li>
          <li className="createem">Create Empolyee</li>
        </ul>
      </div>


      <div className="toaterter-run"> <ToastContainer /></div>
      {
        success && <div >

          <button className="btn btn-success toast-success-btn">{success}</button>

        </div>
      }
      {errorMessage && <div >
        <button className="btn btn-danger toast-success-btn">{errorMessage} <span onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25} /></span></button>

      </div>
      }
      {isPending &&
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
      }


      <form className="createmployee-container" onSubmit={handleSubmit((data, event) => {
        console.log('lettttttt', data);
        setDisabled(true)
        event.target.reset()
        handleSubmited(data)

      })} >




        <h2 className="createmployee-head">Create Employee
          {/* <input type="file" onChange={handleImageChange} name='file'  />  */}
        </h2>

        <label className="name-labe" name="fullName"> First Name <span className="star-required">*</span></label> <br />
        <input placeholder="First Name" className="contentselect" {...register("firstName", {
          required: "This field is required", maxLength: {
            value: 15,
            message: "First Name must not be more than fifteen characters"
          }
        })} />
        <p className="cum-error">{errors.firstName?.message}</p>

        <label className="name-labe" name="fullName"> Last Name <span className="star-required">*</span></label> <br />
        <input placeholder="First Name" className="contentselect" {...register("lastName", { required: "This field is required" })} />
        <p className="cum-error">{errors.lastName?.message}</p>

        <label className="name-labe" name="fullName"> Social Media Handle</label> <br />
        <input placeholder="Social media handle..." className="contentselect" {...register("socialMediaHandle")} /><br /><br />
        <label>Email <span className="star-required">*</span></label> <br />
        <input placeholder="Email" className="contentselect" {...register("emailAddress", { required: "This field is required" })} />
        <p className="cum-error">{errors.emailAddress?.message}</p>
        <label>Phone <span className="star-required">*</span></label> <br />
        <input type="number" placeholder="Phone" className="contentselect" {...register("phoneNumber", {
          required: "This field is required", minLength: {
            value: 9,
            message: "Input correct phone Number"
          }
        })} /><p className="cum-error">{errors.phoneNumber?.message}</p>

        <label>Department</label><br />
        <select className="contentselect" {...register("department", { required: "Select Department" })} value={selectOption} onChange={handleselectChange}  >
          <option value="ICT">ICT</option>
          <option value="Broadcasting">Broadcasting</option>
          <option value="Admin">Admin</option>
          <option value="Security">Security</option>
          <option value="Others">Others</option>

        </select>
        <p className="cum-error">{errors.department?.message}</p>

        <label>Upload your White Background Image <span className="star-required">*</span></label> <br />

        <input type="file" required onChange={handleImageChange} name="file"
        
        />
        <p className="cum-error">{errors.image?.message}</p>

        <span  >{
          showAccess && <span className="access-modal">
            <label>Employee's Password</label><br />
            <input className="password-access" placeholder="Password..." {...register("password")} />
            <p className="cum-error">{errors.password?.message}</p>
            <label>Employee's Role</label> <br />
            <select className="access-select" {...register("hasAccess")} value={selectOption} onChange={handleselectChange}>
              <option value="ICT">ICT</option>
              <option value='Manager'>Manager</option>
              <option value="Admin" >Admin</option>
              <option value="Presenter" >Presenter</option>
              <option value="Hr"  >Hr</option>
              <option value="Others" >Others</option>
            </select><br /><br />

          </span>


        }

        </span>

        <input type="checkbox" className="creatmployee-radio" {...register("UserAccess")} onClick={displayAccess} />
        <label>Admin Access</label>
        <br /><br />

        <button className="createmployee-btn btn btn-primary" disabled={disabled} type="submit" onClick={notify} >Create Employee</button>
        {/* {isPending && 
                <div>
           <div className="loaderss"></div>
           <p>Please wait...</p>
           </div>
            
            } */}

            </form>
          
        </>
    )
}
export default Create1 