
import Table from 'react-bootstrap/Table';
import useFectch from '../createbackend';

function DarkExamples() {

  const {data:today,isPending,error} = useFectch('programs/getPrograms')

  return (


    <>

<Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>S/N</th>
          <th>Program Title</th>
          <th>Host Name</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
    {


      today && today.slice(0,3).map((now,i)=>(

      
        <tr key={now.programId}>
          <td>{i+1}</td>
          <td> {now.programName} </td>
          <td>{now.programpresenters.map((person)=>(
                person.firstName
          ))}</td>
          <td> {now.programStartTime
                }-{now.programEndTime}{""} </td>
        </tr>
        
    
      ))
    }
     </tbody>
    </Table> 

    </>
  );
}

export default DarkExamples;