import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react';
import {datas } from "../components/Ecommerce/itemdata"

const counterSlice = createSlice({
  
  name: 'cart',
  initialState: {
    items: [],
    totalAmount:0,
    totalCount: 0
    
   
  },
reducers: {

  addToCart: (state, action) => {
    const itemInCart = state.items.find((item) => item.id === action.payload.id );
    if (itemInCart ) {
      itemInCart.amount++;
    } else {
      state.items.push({ ...action.payload, amount: 1 });
    }
  },
 
getCartTotal: (state) => {
      let {totalAmount,totalCount} = state.items.reduce(
        (cartTotal,cartItem)=> {
        const {price,amount} = cartItem;
        const  itemTotal = price * amount;
        cartTotal.totalAmount += itemTotal;
        cartTotal.totalCount += amount;
        return cartTotal;
         
      },
         {totalAmount: 0, totalCount: 0 }
      );
      state.totalAmount = parseInt(totalAmount.toFixed(2));
      state.totalCount = totalCount;
},
increase:(state,action) => {
        state.items  = state.items.map((item)=>{
          if(item.id === action.payload ){
            return{...item, amount:+item.amount + 1}
          }
            return item;
        });
},
   
remove:(state, action)=>{
        state.items = state.items.filter((item)=> item.id !== action.payload);
    },

    decrease:(state,action) => {
      state.items  = state.items.map((item)=>{
        if(item.id === action.payload && item.amount >= 1){
          return{...item, amount:item.amount -= 1}
        }
          return item;
      })
    },
    clearCart: (state) =>{
      state.items = [];
    },
    getCartItems:(state)=>{
      state.items = [];
    },
}
})

export const { increase, getCartTotal,remove,decrease,clearCart,getCartItems,addToCart } = counterSlice.actions;
export default counterSlice.reducer;

// const store = configureStore({
//   reducer: counterSlice.reducer
// })

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

