import { BiDetail, BiTimeFive } from "react-icons/bi"
import Footer from "../layouts/footer"
import EcommerceNav from "./ecommerce_nav"
import { CiLocationOn } from "react-icons/ci"
import RelatedProduct from "./relatedproduct"
import { Link, useParams } from "react-router-dom"
import { useCallback, useEffect, useRef, useState } from "react"
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'
import EcoNAvSearch from "./econavsearch"
import { BsTicketDetailed } from "react-icons/bs"
import { AiOutlineShoppingCart } from "react-icons/ai"
import {datas} from "./itemdata"
import {addToCart } from "../../redux/counter";
import { useDispatch, useSelector } from "react-redux"


import toast, { Toaster } from 'react-hot-toast';
import { GiReturnArrow } from "react-icons/gi"


const GoodsDetails =(({id})=>{

  const notify = () => toast('The product has been added to your cart.', {
    
    className: 'toast-messages'});
                 


  const items = useSelector((state)=> state.items);
  const dispatch = useDispatch();
    

const [productData, setProductData] = useState([])
  const {productId} = useParams();
 

                
  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
  .formatToParts(0)
  .filter(part => part.type === 'currency')
  .map(part => part.value)
  .join('')


    const scrollRef = useRef();
    const scrolldown = useRef();

const scrollRefControl = (e)=>{
e.current.scrollIntoView({
  behavior: "smooth"
})
}

const scrollRefDown = (e)=>{
  e.current.scrollIntoView({
    behavior: "smooth"
  })
  }

    const [showImage, setShowImage]= useState('');

    // const handleShowImage =((image)=>{
    //            console.log('testing..',image);
    //     setShowImage(image)
    //     handleClickImage()
    // })
   
    const handleAddToCart =(()=>{
      notify()
    })


    const [alert, setAlert] = useState(false)

    const [removeAlert, setRemoveAlert] =useState('')

    const handleRemoveAlert = (()=>{

      setRemoveAlert(removeAlert)
      setAlert(false)
    })


  useEffect(() => {
    const filteredData = datas && datas.filter((good) => +good.id === +productId);
    setProductData(filteredData);
  }, [datas, productId]);

  
 const scrolltop = (()=>{
      window.scrollTo({
          top:0,
          behavior: "smooth"

      });
  })


 
    return(

        <>
       
       
        <main className="mainbookinpage-all">
{/* <div className="econd-blacknav" >
    <EcommerceNav/>
</div> */}
<div className="providestick-nav" >
<EcoNAvSearch/>
</div>

<div className="details-container">
  { productData.map((good)=>(

    <div className="goodsdetails-container-flex">

      <div className="image-details-con" key={good.id} >
    
    
      <CCarousel controls indicators>
        {
          good.image.slice(0,4).map((test)=>(
    <   CCarouselItem key={test.id}>
        <CImage className="d-block w-100 imageContolCorol" src={test.name} alt="slide 1" />
      </CCarouselItem>
          ))
        }
      
    
    </CCarousel>
    
    
    
    
      <div className="secondimages-container">
      {
        good.image.slice(0,4).map((pic)=>(
            
        <div >
          <img key={pic.id}  src={pic.name} className="second-downimg"/> 
                     </div>
          ))    
        }
        </div>
        <div className="button-toop">
        
        </div>
       <div  className="timedown-image-container">
         <div >
            <h2>{good.productName}</h2>
         </div>
         <div className="handle-time">
            <i ><BiTimeFive size={20}/> Posted 06/08 </i>
            <i><CiLocationOn size={20}/> Abuja, Wuse </i>
         </div>
         </div>
       <div className="product-detailcontainers" >
        <h2 className="product-detailhead" >Product detail</h2>
        <div>
            <h3 ref={scrollRef} >Standard features</h3>
            <p> {good.discription} </p>
           
        </div>
        <div>
            <h3 ref={scrolldown}> Additional features</h3>
          <p> {good.shortDescription} </p>
            
        </div>
        
       </div>
       
      </div> 

   
 
  <div className="sidedetails-container">

          <h4 className="pnamecenter-side"> {good.productName} </h4>
      <h4> <span className="pspan-sideprice"> Price:</span> {symbol} {good.price} </h4>
      
      <hr/>
      <Toaster 
       position="top-center"
       reverseOrder={false}
      
      />
      <button className="button-6" role="button"key={id}
        
        onClick={ ()=>handleAddToCart(dispatch (addToCart({ id:good.id, productName: good.productName, discription: good.discription, shortDescription:good.shortDescription, 
          price:good.price, productContact:good.productContact, deliveryDuration:good.deliveryDuration, delivery:good.delivery, discount: good.discount, amount:good.amount,
          image:good.image
        })))} 
        >ADD TO CART</button>
        
        <hr/>
        <Link to="/ecommerceupper"><button id="showcontact-btn" className="button-6" role="button">Continue shopping <GiReturnArrow/> </button></Link> 
       <p  onClick={()=>{scrollRefControl(scrollRef)}} className="checking-product-details" > <BiDetail/> Product details</p>
        <p  onClick={()=>scrollRefDown(scrolldown)} className="checking-product-details"  > <BsTicketDetailed/> Additional features</p>
        <div>
        



{/* <button id="showcontact-btn" className="button-6" role="button" >Show contact</button> */}


        </div>
    </div>
    </div>
    ))
    
  } 
</div>


<div className="main-eco-goodsrelated">
<h4 className="trending-head">Related product</h4>


<div>
</div>
  <ul className="cards-eco">
    {
      datas.map((data)=>(
<li className="cards_item-eco" key={data.id}>
      
      <li>
      <Link to={`/goodsdetails/${data.id}`} className="linkselect" onClick={scrolltop}>
     <div className="card-eco" >
       <div className="card_image">
         <img src={data.image.map(img=>img.name)} alt="mixed vegetable salad in a mason jar." />
         <span className="card_price"><span> {symbol} </span> {data.price} </span>
       </div>
       <div className="card_content">
      <h2 className="card_title">{data.productName} </h2> 
         {/* <div className="card_text">
           <p>Dig into the freshest veggies of the season! This salad-in-a-jar features a mixture of
             leafy greens and seasonal vegetables, fresh from the farmer's market.
           </p>
           <hr className="eco-goods-hr" />
          
         </div> */}
       </div>
     </div>
     
     </Link>
     </li>

  
</li>



      ))
    }
    </ul>
</div>
               <div>
              
               </div>
         <div>
          {
            alert &&
            <div className="form-alerts toasts" >
            <div role="alert" className="fade form-warning alert  alert-dismissible show">
              <button type="button" className="btn-close" aria-label="Close alert"  onClick={()=>handleRemoveAlert(removeAlert)}></button>
              <div className="d-flex align-items-center">
                <img alt="noti-icon" src="https://blueoctopusllc.files.wordpress.com/2013/08/verificare-apia.jpg"  className="me-4"/>
                <p><b className="d-flex">The product has been added to your cart</b></p>
               
              </div>
               
            </div>
            <div className="showredirection">
            
           <Link to="/accountpage" className="navlink-link"> <button className="showredirection1"> <AiOutlineShoppingCart/> View cart</button> </Link>
           <Link to='/ecommerceupper' className="navlink-link"> <button className="showredirection2" >Continue shopping...</button> </Link>

            </div>
          </div>
          }
 
</div>


               {/* {handleClickImage()} */}
<div><Footer/></div>
</main>
        </>
    )
})
export default GoodsDetails