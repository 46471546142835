
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import advertimage1 from "../images/images2.jpg";
import useFectch from "../adminpage/createbackend";

const FirstSideAdvert = () => {
  const [shuffledData, setShuffledData] = useState([]);
  const { data, isPending, error } = useFectch('adverts/getOnlineAds');

  useEffect(() => {
    // Function to shuffle the data array
    const shuffleData = () => {
      const shuffledArray = [...data];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      setShuffledData(shuffledArray);
    };

    // Shuffle the data array initially
    shuffleData();

    // Set up a timer to shuffle the data array every 10 seconds
    const timer = setInterval(shuffleData, 10000);

    // Clean up the timer when the component is unmounted
    return () => clearInterval(timer);
  }, [data]);

  return (
    <>
      {shuffledData
        .filter((place) => place.sizeType.includes('Side Page Banner 1'))
        .slice(0, 1)
        .map((advert) => (
          <Card style={{ width: '10rem' }} className="cardside-one" key={advert.id}>
            <Card.Img variant="top" className='advertimage-img' src={advert.image} />
            <Card.Body>
              <Card.Text>{advert.advertDetails}</Card.Text>
            </Card.Body>
            <Card.Body>
              <Card.Link href={advert.advertLink} >{advert.advertLink}</Card.Link>
              <Card.Link href={advert.advertLink}>{advert.advertLink}</Card.Link>
            </Card.Body>
          </Card>
        ))}
    </>
  );
};

export default FirstSideAdvert;
