
    export const datas = [
        {
            "id":1,
            "image": [ {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/29/441978/1.jpg?4250"},
            {"name":"https://media.istockphoto.com/id/484719630/photo/adapter-charger-with-usb-cable-isolate-on-white.jpg?s=612x612&w=0&k=20&c=ezzmK3-gKfQlXzL5JppuuWyQQi2xzfzzsftgI4UCCpM="}, 
            {"name":"https://img.freepik.com/free-photo/charger-usb-cable-type-c-red-background_58702-4606.jpg"},
            {"name":"https://www-konga-com-res.cloudinary.com/w_400,f_auto,fl_lossy,dpr_3.0,q_auto/media/catalog/product/N/X/191666_1628156949.jpg"},
           ],
            "productName":"Original phone charger" ,
            "discription":" Well manufactured original charger made for all kinds of phones",
            "shortDescription":"Good for your phone and compatible any where any day" ,
            "price":"2500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"3",
            "delivery": "Door delivery",
            "discount":"0",
            "amount":"5"
            
            
        },
        {
            "id":2,
            "image": 
             [ {"name":"https://ng.jumia.is/unsafe/fit-in/680x680/filters:fill(white)/product/29/9308922/8.jpg?9243"},
             {"name":"https://www-konga-com-res.cloudinary.com/w_auto,f_auto,fl_lossy,dpr_auto,q_auto/media/catalog/product/A/C/92910_1663881697.jpg.jpg?4250"},
             {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
             {"name":"https://ng.jumia.is/unsafe/fit-in/680x680/filters:fill(white)/product/29/9308922/8.jpg?9243"},
            ] ,
            "productName":"Amazon Essentials Men" ,
            "discription":"TECH STRETCH FABRIC: An ultra-light weight and breathable fabric, with a soft smooth sheen finish. Made with quick dry and moisture-wicking finishes, helping you stay comfortable and cool while you workout. This knit fabric has a 4 way gentle stretch for full flexibility during wear.",
            "shortDescription":"ACTIVE LONG SLEEVE TOP: Train in confidence with this long sleeve performance workout hoodie. Designed with a longer body length for added coverage during workouts. Whether you're heading out for a jog, a quick game of basketball or running errands with the kids, our apparel is made to work out, live in, and lounge" ,
            "price":"8500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"3",
            "delivery": "All",
            "discount":"500",
            "amount":"20"
            
        
        },
        {
            "id":3,
            "image": 

             [ {"name":"https://m.media-amazon.com/images/I/91VXOOO0SkL._AC_UY550_.jpg"},
               {"name":"https://www-konga-com-res.cloudinary.com/w_auto,f_auto,fl_lossy,dpr_auto,q_auto/media/catalog/product/A/C/92910_1663881697.jpg.jpg?4250"},
             {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
             {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
            ],

            "productName":"Men shoes" ,
            "discription":"Office men shoe, all the possiblities available",
            "shortDescription":" Train in confidence with this long sleeve performance workout hoodie. Designed with a longer body length for added coverage during workouts. Whether you're heading out for a jog, a quick game of basketball or running errands with the kids, our apparel is made to work out, live in, and lounge" ,
            "price":"9500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"3",
            "delivery": "pickup station",
            "discount":"700",
            "amount":"2"
           
        
            
        },
        {
            "id":4,
            "image": 
           
            
            [ {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"},
            {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
          {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
         ],
            
            
            "productName":"Women cloth" ,
            "discription":"Material:- GeorgetteReady to StitchEmbroidery WorkSequins Work",
            "shortDescription":" Discover a wide range of affordable women's clothing at the best prices " ,
            "price":"5500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"3",
            "delivery": "pickup station",
            "discount":"200",
            "amount":"6"
            
        
            
        },
        {
            "id":5,
            "image":   [ {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"},
            {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
          {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
         ],
            
            "productName":"Kind cloth" ,
            "discription":"Material:- Nice and affordable material",
            "shortDescription":" Discover a wide range of affordable women's clothing at the best prices " ,
            "price":"5500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"1",
            "delivery": "All",
            "discount":"0",
            "amount":"4"
            
        
            
        },
        {
            "id":6,
            "image":   [ {"name":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRWP27t94wcG7XOOswm2tevyG8tEtXJUI48A&usqp=CAU"},
            {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
          {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"},
          {"name":"https://www-konga-com-res.cloudinary.com/w_400,f_auto,fl_lossy,dpr_3.0,q_auto/media/catalog/product/N/X/191666_1628156949.jpg"},
          {"name":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRWP27t94wcG7XOOswm2tevyG8tEtXJUI48A&usqp=CAU"}],
            
            "productName":"fine out wear " ,
            "discription":"Material:- Nice and affordable material",
            "shortDescription":" Discover a wide range of affordable women's clothing at the best prices " ,
            "price":"5500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"1",
            "delivery": "All",
            "discount":"0",
            "amount":"9"
            
        
            
        },
        {
            "id":7,
            "image":   [ {"name":"https://m.media-amazon.com/images/I/91VXOOO0SkL._AC_UY550_.jpg"},
            {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"}, 
          {"name":"https://3.imimg.com/data3/PO/YM/MY-19102226/women-cloth-500x500.jpeg"},
          {"name":"https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/11/5608922/1.jpg?9221"},
          {"name":"https://www-konga-com-res.cloudinary.com/w_400,f_auto,fl_lossy,dpr_3.0,q_auto/media/catalog/product/N/X/191666_1628156949.jpg"},
          {"name":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRWP27t94wcG7XOOswm2tevyG8tEtXJUI48A&usqp=CAU"}],
            
            "productName":"wear all look neat " ,
            "discription":"Wear:- Nice and affordable material",
            "shortDescription":" Discover a wide range of affordable women's clothing at the best prices " ,
            "price":"5500",
            "productContact":"0907565656666" ,
            "deliveryDuration":"1",
            "delivery": "All",
            "discount":"0",
            "amount":"12"
            
        
            
        },
    ]
    
    
    