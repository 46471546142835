import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import ModeHeader from "../layouts/modeheader"
import content1 from "../images/writing-great-content.jpg"
import {TfiHandPointDown} from "react-icons/tfi"
import Footer from "../layouts/footer"
import Newsfeed from "../layouts/newsfeed"


const SubmitContent =()=>{

    return(
       <>
       <ModeHeader/>
       <FirstHeader/>
       <Changer/>
       <Newsfeed/>
       <div className="contentimg-div">
        <div className="content-cover">
            <p className="creativeare"> Are You Creative? You want People to Know about You and your Content</p>
            <h2 className="contentsub" >Submit Your Content <TfiHandPointDown/></h2>
            </div>
         <img className="content-img" src={content1}/>

       </div>

            <form className="contentform-container" >
                <label className="name-label"> Full Name *</label> <br />
                <input placeholder="First Name" className="contentname" required /><br /><br />
                <label>Email *</label> <br />
                <input placeholder="Email" className="contentname" required /><br /><br />
                <label>Phone *</label> <br />
                <input placeholder="Phone" className="contentname" required /><br /><br />
                <label>Type of Content</label><br />
                <select className="contentselect" required>
                    <option>Video</option>
                    <option>Comedy</option>
                    <option>Article</option>
                    <option>Others</option>

                </select><br /><br />
                <label>Short description of the Content</label><br />
                <textarea className="contentdescribe" /><br />
                <label>Is this Work Original Content by You?</label> <br />
                <input className="contentyes" type="radio" name="answer" value="yes"/>
                <label>Yes</label><br />
                <input className="contentyes" type="radio" name="answer" value="no"/>
                <label>No</label><br />
                <label>Upload your Content</label><br />
                <input type="file" className="content" />
                <button className="content-btn">Submit Content</button>

            </form>
     <Footer/>
        </>
    )
}
export default SubmitContent