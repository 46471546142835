import {RxSpaceEvenlyHorizontally} from "react-icons/rx";
import { NavLink, useParams } from "react-router-dom";
import useFectch from "../adminpage/createbackend";

const Newsfeed =()=>{
    const current = new Date();
    const date = `${current.getHours()}:${current.getMinutes()} `; 


    const {data,isPending,error} = useFectch('programs/getNews');
    console.log("wellllll",data)
  
    return(
        <>
        <div className="newsfeed-container">
            <marquee   behavior="scroll" direction="left" scrollamout="0">
            <span className="head-roll">Todays news in our directory <i className="icon-scroll"><RxSpaceEvenlyHorizontally/></i></span>
                
                {    
                        data.filter((feed)=>(
                             feed.onScrollBar.includes("true")
                        ))
                        .map((scroll)=>(
                        <marquees key={scroll.newsId}>
                           <span className="first-roll"> <NavLink to={`/topnews/${scroll.newsId}`} className="newsfeed-nav"> {scroll.newsTopic}</NavLink> <i className="icon-scroll"><RxSpaceEvenlyHorizontally/></i>  </span>
                           
                            </marquees>
                        ))

            }
            </marquee>
   {/* <span className="show-time">{date}</span> */}
        </div>
        </>
    )
}
export default Newsfeed