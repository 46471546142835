import { NavLink } from "react-router-dom";
import useFectch from "../adminpage/createbackend";
import ReplyBox from "../directpages/replybox"
import Changer from "../layouts/changer";
import FirstHeader from "../layouts/firstheader";
import Footer from "../layouts/footer";
import ModeHeader from "../layouts/modeheader";
import Newsfeed from "../layouts/newsfeed";
import moment from "moment";



const SportsNews = ()=>{

    const {data:sports,isPending,error} = useFectch('programs/getNews');
    // console.log("localnews",cultural)

    return(

        <>
        <div className="overflowdiv">
         <Changer/>
        <ModeHeader/>
        <FirstHeader/>
        <Newsfeed/>
        <h2 className="sidenews-head">Sports News</h2>
        <div className="container-alt row row-cols-1 row-cols-md-5 g-4">

  {isPending && <div className="loaderless"> <div className="lds-ripple"> News loading... <div className="blueload"></div><div className="darkorangeload"></div></div>
          </div>}
          {error && <div className="errorssample">{error}</div>}
   
   

    
  {
        sports && sports.filter((local) => (
          local.newsType.includes('Sports') && local.onMainBody.includes("true") 

        )).map((news) => (

  <div className="card-alt"  key={news.newsId}id="mobilenews-display" >
    <div className="card__header">
            <img src={news.image} alt="card__image" className="card__image"id="imagenewsid" /> 
    </div>
    <NavLink  to={`/topnews/${news.newsId}`} className="newsnav-general">
    <div className="card__body" >
      <span className="tag tag-blue">{news.newsType}</span>
      <h4 className="newstext-alt">{news.newsTopic}</h4>
      
      <div className="user">{moment(news.startDateTime).fromNow()
                } </div>
    
    </div>
    </NavLink>
   
  </div>
  ))

}
</div>

    <Footer/>
    </div>
        
        </>
    )
}
export default  SportsNews