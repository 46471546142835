import { Dropdown, Table } from "react-bootstrap"

const ViewOrder =(()=>{



    return(

        <>

<div>
                <ul className="action-bar">
                    <li>Home /</li>
                    <li>E-commerce /</li>
                    <li className="createem">View Products</li>
                </ul>
            </div>
            <h2 className="viewemployee-head">List of Orders Recieved</h2>
            <div>
            <Table striped bordered hover size="sm" className='adaogidi-table'>
    
      
    <thead>
      
      <tr>
        <th>S/N</th>
        <th>Cus. First Name</th>
        <th>Cus. Last Name</th>
        <th> Cus. Phone</th>
        <th>Additional num.</th>
        <th>Cus. Email</th>
        <th>Delivery Address</th>
        <th>State</th>
        <th>City</th>
        <th>Company</th>
        <th>Product</th>
        <th>Quantity</th>
        <th>Total Price</th>
        <th>Payment Status</th>
        <th>Order date/time</th>
        <th>Expected delivery date</th>

        <th>Action</th>

      </tr>
    </thead>


    <tbody>
   
  
 

    <tr >
    <td>1</td>
    <td>ttt</td>
    <td>ttt</td>
    <td>ttt</td>
    <td>ttt</td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    <td>ttt </td>
    
    <Dropdown className="d-inline mx-2 firstline-drop " autoClose="outside"  >
      <Dropdown.Toggle id="dropdown-autoclose-outside" >
        Action
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdownshow-d">
        <Dropdown.Item className="sideside"   >Confirm order</Dropdown.Item>
        <Dropdown.Item className="sideside"   >Complete order</Dropdown.Item>
        <Dropdown.Item className="sideside"   >Cancel order</Dropdown.Item>
        <Dropdown.Item  className="sideside" id="delete-red"  >Delete</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>
    
      
  </tr>
  

  
</tbody>   
     


    
  </Table>

            </div>
        
        </>
    )
})
export default ViewOrder