


const AdminFooter =(()=>{


    return(
        <>

<div className="admin-footer">
          <p>Powered by Ebeonadi Fm, Ogidi, Anambra State</p>
        </div>

        
        </>
    )
})

export default AdminFooter