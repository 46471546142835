import { useState } from "react";
import { useForm } from "react-hook-form";
import {RiArrowDropDownLine} from "react-icons/ri"
import axios from "../../utility/axios"
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md"

let renderCount = 0;

const AddNumber =()=>{
    const {register,handleSubmit,formState:{errors}} = useForm();
    renderCount ++;
    console.log(errors)

    const [action, setAction]= useState('')
    const [isPendings, setIsPendings] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState("");

    const [disabled, setDisabled]= useState(false)

    const actionButton= ()=>{
     setAction(!action)
   
   
    }

    const handleSubmited = async (data)=>{
        console.log("data", data)
        setIsPendings(true);
        setDisabled(true)
        

         await axios.post("programs/createCallNumbers",data)
          
         .then((res)=>{
            console.log("res",res)

            if(res.data.success){
                setIsPendings(false); 
                setSuccess(" Number Added Successfully");
                setErrorMessage("");
                setDisabled(false)
             }else{
                setIsPendings(false); 
                setSuccess(res.data.message);
                setDisabled(false)
              
             } 
            
        }).catch((err)=>{
            setIsPendings(false); 
            console.log("err", err)
            setErrorMessage("Something went wrong. Please try again later.");
            setDisabled(false)
        }) 
        // console.log("networkcall", networkcall)
    
    }

    useEffect(()=>{
     
    let timeout;
    if (success){
       timeout = setTimeout(()=>{
        setSuccess(false);
       },5000)
    }
    return () => {
        clearTimeout(timeout);
    };

    },[success])

    const notify = () => toast("Creating employee...");
    const closeErrorClick =(()=>{
        setErrorMessage(false)
 })


    return(
        <>
<div>
            <ul className="action-bar">
                <li>Home /</li>
                <li>Edit Call-In Numbers/</li>
                <li className="createem">Create Call-In Numbers</li>
            </ul>
        </div> 
        <div className="toaterter-run"> <ToastContainer /></div>
        {
          success && <div >
           
          <button  className="btn btn-success toast-success-btn">{success}</button>
          
        </div>
        }
        {errorMessage && <div >
            <button  className="btn btn-danger toast-success-btn">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 
        {isPendings && 
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
        } 

   <form className="createmployee-container" onSubmit={handleSubmit((data,event)=>{
    event.target.reset()
    setDisabled(true)
    handleSubmited(data)

   })}  >
        <h2 className="createmployee-head">Add Call-In Number</h2>
                <label > Type <span className="star-required">*</span></label> <br />
                <input type="text" placeholder="e.g General Number..." className="contentname" {...register("type", {required:"This field is required"})} />
                <p className="cum-error">{errors.typeNumber?.message}</p>
                <label>Call-in Number  <span className="star-required">*</span></label> <br />
                <input type="number" placeholder="09000000000000..." className="contentname" {...register("callNumbers",{required:"This filed is required"})}/>
                <p className="cum-error">{errors.callNumber?.message}</p>
                <label>Whatsapp/Text/Email<span className="star-required">*</span></label> <br />
                <input  placeholder="e.g 090000000000000..." className="contentname" {...register("socialMediaNumber",{required:"This filed is required"})} />
                <p className="cum-error">{errors.textNumber?.message}</p>
                

                <button type="submit" className="createmployee-btn btn btn-primary"  disabled={disabled} onClick={notify}>Add Number</button>

            </form>

        </>
    )
}
export default AddNumber