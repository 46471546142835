
import axios from "../../utility/axios"
import {Controller, control, useFieldArray, useForm } from "react-hook-form";
import useFectch from "./createbackend";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineCancel} from "react-icons/md";
import HeaderLogin from "../layouts/headerlogin";


let renderCount = 0;


const CreateProgram1 = ()=>{

const {register,control,handleSubmit,formState:{errors}} = useForm();
renderCount ++;
console.log(errors)

const [isPendings, setIsPendings] = useState(false);

const [errorMessage, setErrorMessage] = useState(false)
const [success, setSuccess] = useState(false)
const [disabled, setDisabled]= useState(false)


const scrolltop = (() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  
    });
  })

const handleSubmited = async (data)=>{
    
    console.log("data", data);
    const fullData = {
        ...data,
        programPresenters: [{userId: data.programPresenters}]
    };
    setDisabled(true)
    setIsPendings(true)
     await axios.post("programs/createPrograms",fullData,selectOption)
      
     .then((res)=>{
        console.log("res>>>>>>>>>>>>",res)
        if(data.success === true){
            setIsPendings(false)
            setSuccess("Program Created Succefully")  
            console.log('success>>>>>',success);
            setIsPendings(false)
            setDisabled(false)
         }else{
            setSuccess(res.data.message)
             console.log('error>>>>>',errorMessage);
             setIsPendings(false)
             setDisabled(false)
         }
        
    }).catch((err)=>{
        console.log("err", err)
        setIsPendings(false)
        setErrorMessage(err.data.message)
        setDisabled(false)
    }) 
   
    scrolltop() 
}
const[selectOption,setSelectOption]=useState(false)
const handleselectChange =(event)=>{
    setSelectOption(event.target.value)
}

useEffect(()=>{
     
    let timeout;
    if (success){
       timeout = setTimeout(()=>{
        setSuccess(false);
       },5000)
    }
    return () => {
        clearTimeout(timeout);
    };

    },[success])


const {data,isPending,error} = useFectch('registrations/getUsers')

const notify = () => toast("Creating program...");

 
const closeErrorClick =(()=>{
    setErrorMessage(false)
})



const { fields, append, remove } = useFieldArray({
    control,
    name: "programDay"
  });


    return(
        <>
        
        <div>
            <ul className="action-bar">
                <li>Home /</li>
                <li>Programs/</li>
                <li className="createem">Create Programs</li>
            </ul>
         

        </div> 
        <div className="toaterter-run"> <ToastContainer /></div>
        {
          success && <div >
           
          <button  className="btn btn-success toast-success-btn">{success}</button>
          
        </div>
        }
        {errorMessage && <div >
            <button  className="btn btn-danger toast-success-btn">{errorMessage} <span  onClick={closeErrorClick} className="remove-erroricon"><MdOutlineCancel size={25}/></span></button>
          
        </div>
        } 
         {isPendings && 
        <div className="lds-ellipsis">Creating please wait<div></div><div></div><div></div><div></div></div>
        } 

       <form className="createprogramform" onSubmit={handleSubmit((data, event) => {
                event.target.reset();
                setDisabled(true)
                handleSubmited(data)
            })} >



                <h2 className="createprogram-head">Create Program</h2>




                <label>Program Type </label>
                <select className="createprogram-select" {...register("programType")} value={selectOption} onChange={handleselectChange}>
                    <option value="Regular">Regular</option>
                    <option value="Paid">Paid</option>

                </select><br /><br />
                <label className="ccpay">Section for only paid programs</label><br />
                <label>Start Date</label><br />
                <input className="createp-input" placeholder="Start Date" type="datetime-local" {...register("paidStartTime")} /><br />
                <label className="">End Date</label><br />
                <input className="createp-input" type="datetime-local" {...register("paidEndTime")} /> <br />
                <label>Amount Paid</label><br />
                <input type="number" className="createp-input" placeholder="#2000..."  {...register("amountPaid")} /><br />
                <hr className="underline-program" />

                <label>Program Title<span className="star-required">*</span></label><br />
                <input className="createp-input" placeholder="Title..." {...register("programTitle", {
                    required: "This field is required", maxLength: {
                        value: 17,
                        message: "Text exceeds the limit"
                    }
                })} />
                <p className="cum-error">{errors.programTitle?.message}</p>
                <label>Program other name</label><br />
                <input className="createp-input" placeholder="Second title..." {...register("programOtherNames")} /><br /><br />


                <label>Program Details<span className="star-required">*</span></label><br />
                <textarea className="createp-content" placeholder="Content..." {...register("programDetails", { 
                    required: "This field is required", minLength:{
                        value: 300,
                        message: "Must be 300 characters or above"
                    }
                    
                    })} />
                <p className="cum-error">{errors.programDetails?.message}</p>
                <label>Select Day </label><br />
               
                
                {
                    fields.map((item, index) => (
                        <div>
                           
                            <select className="createprogram-weekday" {...register(`programDay.${index}.programDay`,{ required: "Please select program day/days" })} onChange={handleselectChange} key={item.id} >
                            <option>Choose...</option>
                                <option key={1} value="Monday" >Monday</option>
                                <option key={2} value="Tuesday">Tuesday</option>
                                <option key={3} value="Wednesday">Wednesday</option>
                                <option key={4} value="Thursday">Thursday</option>
                                <option key={5} value="Friday">Friday</option>
                                <option key={6} value="Saturdy">Saturday</option>
                                <option key={7} value="Sunday">Sunday</option>
                                <option key={8} value="All">All</option>
                            </select>
                           
                            <button className="removeadd-day btn btn-danger" type="button" onClick={() => remove(index)}>Remove</button>
                        </div>
                    ))

                }
                <select
                    type="button"
                    onClick={() => append({ programDay: "Select program days" })}
                    className="createprogram-weekday  "
                    
                    
                > 
                  <option>Click to select day(s)</option>
                </select>
                    
                {/* </input> */}
                <p className="cum-error">{errors.programDay?.message}</p> 
               
                <label>Start Time <span className="star-required">*</span></label>
                <input className="createp-input" placeholder="Start time" type="time" {...register("programStartTime", { required: "Select start time" })} />
                <p className="cum-error">{errors.programStartTime?.message}</p>
                <label>End Time <span className="star-required">*</span> </label>
                <input className="createp-input" placeholder="Stop time" type="time" {...register("programEndTime", { required: "Select end time" })} />
                <p className="cum-error">{errors.programEndTime?.message}</p>
                <label>Select Presenter </label>
                <select className="createprogram-select1"  {...register("programPresenters", {required:"Select presenter for the program"})} value={selectOption} onChange={handleselectChange}  defaultValue="Choose...">
                       <option>Choose...</option>
                    {data && data.filter((present) => (
                        present.department
                            .includes('Broadcasting')

                    )).map((presenter) => (
                        <option value={presenter.userId}>{presenter.lastName} {presenter.firstName}</option>
                    ))
                    }

                </select>
                 <p className="cum-error">{errors.programPresenters?.message}</p>
                <label>Enter Presenter</label>
                <input className="createprogram-select1" placeholder="Optional..." {...register("presenter")} /><br /><br />
                <label>Program call-in Number</label><br />
                <input className="createp-input" placeholder="Enter call-in number..." {...register("callInNumber")} /><br /><br />
                <input className="createprogram-select1" placeholder="Social media handle..." {...register("presenterSocialMediaHandle")}>

                </input><br /><br />
                <label>Program Status</label><br />
                <select className="createprogram-select1" {...register("programStatus")} value={selectOption} onChange={handleselectChange}>
                <option>Choose...</option>
                    <option value="Active">Active</option>
                    <option value="Host on Leave">Host on Leave</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Others">Others</option>

                </select><br /><br />
                <button type="submit" className="createnews-btn btn btn-primary"  disabled={disabled} onClick={notify}>Create Program</button>




            </form>




        </>
    )
}
export default CreateProgram1