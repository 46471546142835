
import { useForm } from "react-hook-form";


let renderCount = 0;


const CreateAdaogidi =()=>{
    const {register,handleSubmit,formState:{errors}} = useForm();
    renderCount ++;
    console.log(errors)


    return(
        <>
        <div>
            <ul className="action-bar">
                <li>Home /</li>
                <li>Adaogidi Pageant/</li>
                <li className="createem">Create Adaogidi</li>
            </ul>
        </div> 

         <form className="createnews-form" onSubmit={handleSubmit((data)=>{
            console.log("createpromo-data", data)
         })}>
          <h2 className="createnews-head">Create Adaogidi Winner</h2>
          <label>First Name</label><br/>
          <input className="newstopic-input" placeholder="Topic..." {...register("topic", {required:"This filed is required"})} />
          <p className="cum-error">{errors.topic?.message}</p>
          <label>Last Name</label><br/>
          <input className="newstopic-input" placeholder="Time..." {...register("time", {required:"Time filed is required"} ) } />
          <p className="cum-error">{errors.promoTime?.message}</p>
          <label>Date</label><br/>
          <input className="newstopic-input" type="datetime-local" placeholder="Date..." {...register("time", {required:"Time filed is required"} ) } />
          <p className="cum-error">{errors.promoTime?.message}</p>
          <label>Profile</label><br/>
          <textarea className="news-details" placeholder="Add Details" {...register("details")}/><br/><br/>
          <label >Upload Image</label>
          <input type="file" className="uploadcreate-news" {...register("image")}/><br/><br/>
  <button className="createnews-btn" type="submit">Create Winner</button>
        </form>

        
        </>
    )
}
export default CreateAdaogidi