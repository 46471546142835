
const Changer = ()=>{
    return(
        <>
            <main className="container">
                {/* <p className="pchanger">ADVERT SPACE</p> */}
                <div className="animation">
                    <div className="first"><div>EBEONADI RADIO TV</div></div>
                    <div className="second"><div>ADVERTISE With Us</div></div>
                    <div className="third"><div>GET NOTICED  </div></div>
                </div>
            </main>
        </>
        
    )
}
export default Changer