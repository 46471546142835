
import "./daysprogram.css";
import mondaypic from "../../images/enter5.jpg";
import mondaypic1 from "../../images/Picture2-removebg-preview.png";
import mondaypic2 from "../../images/kpakpando.png";
import mondaypic4 from "../../images/Picture3-removebg-preview.png";
import useFectch from "../../adminpage/createbackend";
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact";
import moment from "moment";
// import ShowMissed from "../../news/showmissed";
const Tuesday =()=>{


  
    const {data,isPending,error} = useFectch("programs/getPrograms")
console.log("programs>>>>>>>>>",data)

// const [sunday, setSunday]= useState(data)


const ProgramFormatStartTime= data.forEach((time)=>{
    const startTime = time.programStartTime;
    const endTime = time.programEndTime

const dummyDate = "2022-01-01"
const combinedDateTime =`${dummyDate}${startTime}`;
const combinedEndTime =`${dummyDate}${endTime}`;

const paresedTime = moment(combinedDateTime, "YYY-MM-DD h:ma");
const paresedEndTime = moment(combinedEndTime, "YYY-MM-DD h:ma");

time.programStartTime =  paresedTime.format("h:ma")
time.programEndTime =  paresedEndTime.format("h:ma")

})



    return(
        <>

 
          <div  className="control-monday">

 
            
        <h2 className="schedule-date"><span className="dayname">Tuesday</span> Programs</h2> 
        </div> 

        <div className="projcard-container">
          {
  data.filter((sunday)=>(
    sunday.program_days.map((monday) => (
        monday.day
    )).includes('Tuesday')||  sunday.program_days.map((monday) => (
        monday.day
    )).includes('All')
)).map((program) => (

		
        <div className="projcard projcard-blue">
            <div className="projcard-innerbox">
             <div> <img className="projcard-img"  src={program.programpresenters.map((image)=>(
            image.image
        ))}  /></div>  
                <div className="projcard-textbox">
                    <div className="projcard-title">{program.programName}</div>
                    <div className="projcard-subtitle">{program.programOtherNames}</div>

                    <div className="projcard-bar"></div>
                    
                    <div className="projcard-tagbox">
                        From<span className="projcard-tag">{ program.programEndTime}</span><span className="toprogram">To</span>
                        <span className="projcard-tag"> { program.programEndTime}</span>
                    </div>
                    <div className="projcard-description">{ <ReadMoreReact  
        text={program.programDetails}
        min={500}
        ideal={800}
        max={2500}  
/> } </div>
                </div>
            </div>
        </div>
        
        ))

    } 
      
        
    </div>



<div className=" monday-all">

{isPending && <div>
    <div className="projcard" >

<h2 className="schedule-title-ske"> </h2>
<span className="monday-time"> </span>
<div className="monday-div-ske">


    <img className="monday1-image-ske" />

        <span className="monday-article">
            <p className="p-skeletons"></p>
            <p className="p-skeletons"></p>
            <p className="p-skeletons"></p>
            <p className="p-skeletons"></p>
            <p className="p-skeletons"></p>
            <p className="p-skeletons"></p>



    </span>

</div>

</div>
                               </div>} 
          {error && <div>{error}</div>}


            
        </div>
        </>
        
        

        
    )
}
export default Tuesday
