import { NavLink } from "react-router-dom";
import EcommerceNav from "./ecommerce_nav"
import { Fade } from "react-awesome-reveal";
import Footer from "../layouts/footer";
import EcoNAvSearch from "./econavsearch";

const HowToBuy =(()=>{


    return(

        <>
{/* <EcommerceNav/> */}
<EcoNAvSearch/>

<div className="our-solution-text">
      <h1>
        HOW TO BUY AND SALE ON EBEONADI
      </h1>
      <div className="oursolutions-line"></div>
      </div>   
            <div className="body3-container-all">
                <Fade>
                    <div className="body3-main">
                        <div className="body3-image-div">
                           
                            <img src="https://img.freepik.com/free-vector/hand-drawn-street-food-market-illustration_52683-119141.jpg?w=1380&t=st=1691752205~exp=1691752805~hmac=5c1a1043089d274e2fae08a47390750817e39313943c6666fd693f9a8c529a21" className="body3-img1" />
                        </div>
                        <div className="body3-text-div">
                        <h2 className="tittle-text">About Ebeonadi e-market</h2>
                            <div>
                             Ebeonadi e-market is platform created by Ebeonadi Radio Station
                             to reach out to people via networking
                             Here you can buy and sale any product of your choice
                             in just a twinkle of an eye, you are just a tap away 
                             from your target audience.
                             We provide enabling environment for small, medium and large
                             businesses to strife. Make the world here about your business
                             Contact our help-desk for more Info.
                            {/* <NavLink to='/bookingpage' className="navlink-button" > <button className="button-body3-check">
                                Book Now
                            </button></NavLink> */}
</div>  
                        </div>

                    </div>
                </Fade>



            </div>
            <div className="body3-container-all">
                <Fade>
                    <div className="body3-main">
                    
                        <div className="body3-text-div">
                        <h2 className="tittle-text">How to sale on Ebeonadi</h2>
                            <p>1. Contact our help-desk for immediate posting</p>
                            <p>2. Make photos of your item. Feel free to make a lot of photos using your smartphone. Make sure they show your item in the best light.</p>
                      <p>3. Answer the messages and calls from your clients!</p>
                      <p> 4.If everything is ok with your advert, it’ll be on Ebeonadi in a couple of hours after 
                        sending to moderation. We’ll send you a letter and notification when your advert goes live.
                         Check your messages and be ready to earn money! </p>



{/* 
                             <NavLink to='/bookingpage' className="navlink-button" > <button className="button-body3-check">
                              Sell
                            </button></NavLink>  */}

                        </div>
                        <div className="body3-image-div">
                            
                            <img src="https://img.freepik.com/free-vector/street-food-icons-set_1284-22404.jpg?size=626&ext=jpg&ga=GA1.2.1849722987.1675157734&semt=ais" className="body3-img1" />
                        </div>

                    </div>
                </Fade>



            </div>
            <div className="body3-container-all">
                <Fade>
                    <div className="body3-main">
                    <div className="body3-image-div">
                           
                            <img src="https://img.freepik.com/free-vector/people-supermarket-flat-cartoon-family-mother-father-children_33099-180.jpg?size=626&ext=jpg&ga=GA1.2.1849722987.1675157734&semt=ais" className="body3-img1" />
                        </div>

                        <div className="body3-text-div">
                        <h2 className="tittle-text">How to buy on Ebeonadi</h2>
                            <p> 1. Search for the item.
Find what you need using search panel and filters. We have a lot of adverts, choose exactly what you are looking for.</p>
                            <p> 2. Contact a seller.
You Ebeonadi email, social media handles or call them via phone. Discuss all the details, negotiate about the price.</p>
                            <p> 3. Take your item or order a delivery.
We check our sellers carefully, but it’s always better to check twice, right? 
</p>
                            <p> 4. Leave your feedback about us.
Feel free to tell us about your purchase. Let’s build a safe and professional business community together!</p>
                            
                            <NavLink to='/ecommerceupper' className="navlink-button" > <button className="button-body3-check">
                                Go shopping
                            </button></NavLink>

                        </div>
                        
                    </div>
                </Fade>



            </div>

            <div className="body3-container-all">
                <Fade>
                    <div className="body3-main">
                   

                        <div className="body3-text-div">
                        <h2 className="tittle-text">More info</h2>
                            <p> 1. Pay attention to the details.
Make good photos of your goods, write clear and detailed description.</p>
                            <p> 2. Answer quickly.
Don’t make your buyer wait for your message for days. Be online or get SMS notifications on your messages.</p>
                            
                            {/* <NavLink to='/bookingpage' className="navlink-button" ><button className="button-body3-check">
                                Book Now
                            </button></NavLink> */}

                        </div>
                        <div className="body3-image-div">
                           
                            <img src="https://img.freepik.com/premium-vector/people-queue-street-counter-kiosk-with-fresh-fruits-vegetables_80590-10556.jpg?size=626&ext=jpg&ga=GA1.2.1849722987.1675157734&semt=ais" className="body3-img1" />
                        </div>
                        
                    </div>
                </Fade>



            </div>
           <Footer/>

        </>
    )
})
export default HowToBuy