import React, { useEffect, useState } from "react";
import Body1 from "./body/body1";
import Body2 from "./body/body2";
import ContactShow from "./directpages/contactshow";
import Changer from "./layouts/changer";
import Footer from "./layouts/footer";
import Header from "./layouts/header";
import Newsfeed from "./layouts/newsfeed";
import OnAir from "./layouts/onair";
import Presenters from "./layouts/presenters";
import Entertainment from "./news/Entertaiment";
import LineImage from "./news/lineImage";
import News from "./news/newss";
import Podcast from "./news/podcast";
import ShowMissed from "./news/showmissed";
import SocialMedia from "./news/socialmedia";
import nonetwork from "./images/no network.png"
import present1 from "./images/kpakpando.png"
import FirstHeader from "./layouts/firstheader";
import SecondHeader from "./layouts/secondheader";
import {AiOutlineSchedule} from "react-icons/ai"
import ModeHeader from "./layouts/modeheader";
import {BsSearch} from "react-icons/bs"
import FirstSideAdvert from "./directpages/firstsideadvert";
// import Players from "./player";




const Home = ()=>{

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      function handleOnline() {
        setIsOnline(true);
      }
  
      function handleOffline() {
        setIsOnline(false);
      }
  
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
  
      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);
  



    return(
        <>
       
        <div className="home-container">
            {
            isOnline ? (
              <div>
                <Changer />
                <ModeHeader />
                <FirstHeader />
                <Newsfeed />
                <Body2 />
                <Presenters />
                <Footer />
              </div>

                ):(<div>
                   {/* <img src={nonetwork} className="nonetwork-style"/>  */}
                   <h1 className="this-site">This site can't be reached, check your internet connection</h1>    
                </div>)
            }
            

          
           

            

        </div>
        </>
    )
}
export default Home