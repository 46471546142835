import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"


const CultureTradition =()=>{
 
    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>
            <div className="culture-head">Culture/Tradition</div>

            <div className="tv-show-container">


                <iframe width="356" height="250" src="https://www.youtube.com/embed/mpmNcdwGOR0"
                    title="Prince Perry Ebube Izuogu with Okosisi @Ebeonadi Fm 103.5 Ogidi With Obori N'China,Eze Urukputu"
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="true"></iframe>
                <span className="first-yotube-text">Prince Perry Ebube Izuogu with Okosis @Ebeonadi Fm 103.5 Ogidi Obori N'China, Eze Urukputu</span>

            </div>
            <div className="tv-show-container">
                <iframe width="356" height="250" src="https://www.youtube.com/embed/we12qAS_eOk"
                    title="Ife Adi Ako Ebe Onadi"
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="true"></iframe>

                <span className="first-yotube-text">Chief (Dr.) Oliver De Coque and His Expo 76 Ogene Sound Super of Africa - Topic</span>

            </div>
            <div className="tv-show-container">
            <iframe width="356" height="250" src="https://www.youtube.com/embed/H6LCvajtqRM" 
            title="Chief Oliver de coque- Ndi Ogiriga Wara na Nwa." 
            frameborder="0"
             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
              
           <span className="first-yotube-text">Chief Oliver de coque- Ndi Ogiriga Wara na Nwa.</span> 
            </div>

            <div className="tv-show-container">
            <iframe width="356" height="250" src="https://www.youtube.com/embed/DmwQdxv38Zg" 
            title="Chief Steven Osita Osadebe-Nwanne ebezina." 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             allowfullscreen></iframe>

             <span className="first-yotube-text">Chief Steven Osita Osadebe-Nwanne ebezina.</span>  

            </div>
  <Footer/>
        </>
    )

}
export default CultureTradition