

import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./counter";


const store = configureStore({
       reducer:{
              cart:cartReducer,
       }
});
export default store;