import Changer from "../layouts/changer";
import FirstHeader from "../layouts/firstheader";
import ModeHeader from "../layouts/modeheader";
import movie1 from "../images/movie1.jpg"
import movie2 from "../images/movie2.jpg"
import movie3 from "../images/movie3.jpg"
import { NavLink } from "react-router-dom";
import Footer from "../layouts/footer";
import Newsfeed from "../layouts/newsfeed";



const MovieReview =()=>{

    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>
        <div className="movie-container">
            <div className="movie-head">Movie Reviews</div>
            
            <NavLink to="/moviedetails" className="movie1">
                <img className="movie-img" src={movie1}/>
                <div className="movie-text">Megan movie review: the meanest film about bad parenting in years.baffling creative decisions hurt the movie and are likely to leave audiences scratching their heads. The movie centers on the relationship between Supergirl (Meg Donnelly) and Brainiac-5 </div>
        
            </NavLink>

           
            <div className="movie1">
                <img className="movie-img" src={movie3}/>
                <div className="movie-text">Megan movie review: the meanest film about bad parenting in years.baffling creative decisions hurt the movie and are likely to leave audiences scratching their heads. The movie centers on the relationship between Supergirl (Meg Donnelly) and Brainiac-5 </div>

            </div>

            
            <div className="movie1">
                <img className="movie-img" src={movie3}/>
                <div className="movie-text">Megan movie review: the meanest film about bad parenting in years.baffling creative decisions hurt the movie and are likely to leave audiences scratching their heads. The movie centers on the relationship between Supergirl (Meg Donnelly) and Brainiac-5 </div>

            </div>

        </div>

        <Footer/>

        
        </>
    )
}
export default MovieReview