

import { useForm } from "react-hook-form";

let renderCount = 0;


const CreatePodcastt = ()=>{

const {register,handleSubmit,formState:{errors}} = useForm();
renderCount ++;
console.log(errors)


    return(
        <>
<div>
            <ul className="action-bar">
                <li>Home /</li>
                <li>Schedule/</li>
                <li className="createem">Create Schedule</li>
            </ul>
        </div> 
      <form className="createmployee-container" onSubmit={handleSubmit((data)=>{
        console.log("podcast data", data)
      })} >
        <h2 className="createmployee-head">Create Schedule</h2>
                <label className="name-label"> Topic *</label> <br />
                <input placeholder="Topic of the Program..." className="contentselect" {...register("topic", {required:"This field is required"})} />
                <p className="cum-error">{errors.podTopic?.message}</p>
                <label>Date *</label> <br />
                <input type="date" placeholder="Date of the Program..." className="contentselect" {...register("date",{required:"Date of the program is required"})}/>
                <p className="cum-error">{errors.podDate?.message}</p>
                <label>Days of the Week  *</label> <br />
                <select className="contentselect" {...register("host",{required:"select host"})}>
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thursday</option>
                    <option>Friday</option>
                    <option>Saturday</option>

                </select><br /><br />
                <label>Time</label><br/>
                <input type="time" placeholder="Time of the Program..." className="contentselect" {...register("time", {required:"Time of the program is required"})} />
                <p className="cum-error">{errors.podTime?.message}</p>
                <label>Host</label><br />
                <select className="contentselect" {...register("host",{required:"select host"})}>
                    <option>Presenter</option>
                    <option>Caster</option>
                    <option>Security</option>
                    <option>Others</option>

                </select><br /><br />
                {/* <label>Short description of the Content</label><br />
                <textarea className="contentdescribe" /><br /> */}
                <label>Upload Image</label> <br />
                {/* <input className="contentyes" type="radio" name="answer" value="yes"/> */}
                {/* <label>Yes</label><br />
                <input className="contentyes" type="radio" name="answer" value="no"/>
                <label>No</label><br />
                <label>Upload your Content</label><br /> */}
                <input type="file" className="content" {...register("image")} />
                
                
                <br/><br />

                <button type="submit" className="createmployee-btn btn btn-primary" >Upload </button>

            </form>

        </>
    )
}
export default CreatePodcastt