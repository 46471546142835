import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"

const MovieDetails =()=>{

    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>
        <Newsfeed/>
        <div>
            
        </div>
        </>
    )
}
export default MovieDetails