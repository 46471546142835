import Footer from "../layouts/footer"
import EcommerceNav from "./ecommerce_nav"
import EcoNAvSearch from "./econavsearch"
import ProductCards from "./product"
import PaymentMethods from "./product"
import Products from "./product"




const AccountPage =(()=>{



    return(
        <>
        
        {/* <EcommerceNav/> */}
        <EcoNAvSearch/>
          <div className="cartpush-div">
            <ProductCards/>
          </div>
    <div className="footerchange-shopping">  

<Footer/>
</div>    
        </>
    )
})
export default AccountPage