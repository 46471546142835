import { AiOutlineShoppingCart } from "react-icons/ai"
import { BsFillPersonFill, BsJustifyRight } from "react-icons/bs"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {datas} from "./itemdata"
import React, { useEffect, useRef, useState } from "react"



const EcoNAvSearch = (()=>{


const totalCount = useSelector((state)=>{
    
    return state.cart.items.length
   
})
const [display, setDisplay] = useState(false)

 const handleDisplay = (()=>{
    setDisplay(true)
 })

const [search,setSearch] = useState(" ")

const handleDirect =(()=>{
    
    setDisplay(false)
})

const handleSearchDisplay = ((event)=>{
    setSearch(event.target.value)
    if(event.target.value == 0){
        setDisplay(false)
    }else{
        setDisplay(true)
    }

})

let locationList1 = useRef();



useEffect(() => {
  const handler = (event) => {
    if (locationList1.current && !locationList1.current.contains(event.target)) {
      locationList1.current.style.display = 'none';
    }
  };

  document.addEventListener("click", handler);

  return () => {
    document.addEventListener("click", handler);
  };
},[locationList1]);


    return(

        <>



   
        
         <nav className="second-ecomercenav"> 
         <div className=" w-100 "style={{color:"white", textAlign:"center", padding:"1px", backgroundColor:"#030334"}} >  Ebeonadi e-commerce</div>
         <ul className="myaccount-list">
           
           
             <Link to= "/signinpage" className="linkstyle" ><li>Login</li></Link> 
             <Link to= "/signuppage" className="linkstyle" ><li id="hideregisterId">Register</li></Link> 
         </ul>
       
         <Link className="linkstyle" to="/accountpage"><i className="cart-icon"> <i className="badge " value={totalCount}> <span className="cartHideonm">Cart</span>  <AiOutlineShoppingCart size={25} className="carticon-up"/></i> </i> </Link>  
        
<     form className="searchbox">
        <input type="search" placeholder="Search" className="input-search" onKeyDown={(()=>handleDisplay())} onChange={handleSearchDisplay} />
        
        <button type="submit" className="btn-input-search" value="search">&nbsp;</button>
        {display && <div ref={locationList1} className="allsearch-drop">
       
                
                      {
                        datas.filter((product)=>(
                                product.productName && product.productName.toLowerCase().indexOf(search) !== -1

                        )).map((name)=>(

                            <ul className="ulcontrol-search">
                                
                              <Link  to={`/goodsdetails/${name.id}`} onClick={(()=>handleDirect())} className="linktodisplay-search">  
                            <li><i><img className="picSearch-control" src={ name.image.map((pic)=>(
                                         pic.name
                            )) } /> </i>
                               <span> {name.productName} </span>
                            </li>
                            </Link>
                            </ul>   

                        ))
                      }
                
                                
                        
              </div>}  
    </form>
          
    </nav> 
    
    
        
        </>
    )
})
export default EcoNAvSearch