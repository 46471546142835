import { BiLogOutCircle } from "react-icons/bi"
import { BsFillPersonFill } from "react-icons/bs"
import { Link, useNavigate, useNavigation } from "react-router-dom"
import { Nav } from 'react-bootstrap';

import { CCollapse, CContainer, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from '@coreui/react';
import { useState } from "react";


const ProfileNav =(()=>{

  const [visible, setVisible] = useState(false);

  const navigation = useNavigate()

  const handleLogout = () => {
    navigation("/ecommerceupper", {state:{}});
    localStorage.removeItem("adminData")
    
  };

  const profiles = JSON.parse(localStorage.getItem('ecomUserData'));
    return(

        <>
      {/* <nav className="commercenav-container">
     <Link   to='/ecommerceupper' className="navlink-link"> <h5 className="name-ebeano-profile"> Ebeonadi E-commerce</h5>
      <ul className="ecommerce-ul">
      <Link to="/mainprofile" className="linkstyle"><li className="myacountlist"><BsFillPersonFill className="humanicon"/> My Account</li></Link> 
        <li> Hi ! {profiles.firstName}</li>
        <li onClick={handleLogout} className="logout-bookingside" >Log out <BiLogOutCircle/> </li>
        
        
      </ul>

        </nav> */}

  <CNavbar expand="lg" className="control-backgroundnav">
      <CContainer fluid>
     <Link to="/ecommerceupper" style={{textDecoration:"none"}}><CNavbarBrand  className='colapse-nav-profileNav'>Ebeonadi E-commerce</CNavbarBrand></Link>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={() => setVisible(!visible)}
          className='back-dropIcon'
        />

        <CCollapse className="navbar-collapse colapse-nav-profile" visible={visible}>
          <CNavbarNav component="nav" className='navcontrol-push'>
          <CNavLink  className='colapse-nav-profiles'> <li> Hi ! {profiles.firstName}</li></CNavLink>
          <Link to="/mainprofile" className="proHide-link"><CNavLink  active className='colapse-nav-profile'>
            <BsFillPersonFill className="humanicon"/> My Account
            </CNavLink></Link> 
           
            <CNavLink onClick={handleLogout} className='colapse-nav-profile'>Log out <BiLogOutCircle className="logout-take"/></CNavLink>
           
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>

        </>
    )
})
export default ProfileNav