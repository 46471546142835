import React, { useState } from "react"
import "./header.css"
import logo1 from "./../images/logo fm2.jpg"
import {BsPersonWorkspace} from "react-icons/bs"
import ContactShow from "../directpages/contactshow";
import {MdOutlineLogout}from "react-icons/md"
import {ImMenu3}from "react-icons/im"
import { Await,NavLink, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';





const HeaderLogin = ()=>{

const navigation = useNavigate()
    
 const [contactShow, setContactShow] = useState(false)
 const popContact = ()=>{
    setContactShow(!contactShow)
}
const [show, setShow] = useState(false)
const [lgShow, setLgShow] = useState(false);

const handleLogout = () => {
    localStorage.removeItem("adminData")
    navigation("/loginpage", {state:{}});
  };

  const [modalShow, setModalShow] = useState(false);

  
 const profiles = JSON.parse(localStorage.getItem('adminData'));
 
   return(
        <>

<div className="nav-bar">
             
              <NavLink to="/" >  <h1 className="radio-brand">EBEONADI FM</h1></NavLink>
                
                 
                <ul className="nav-list">
                  <li className="namenav-log"  onClick={() => setModalShow(true)}  >{profiles.firstName}</li>
                 <li onClick={() => setModalShow(true)} className="profile-image-static" ><img className="profile-image-static" src={profiles.image}/></li>
                  
                
                </ul>
                <li className="proms-logout"   onClick={() => setLgShow(true)} >  Log out  <MdOutlineLogout/></li>
            
            </div>


            <Modal show={modalShow}   aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header >
        <Modal.Title id="contained-modal-title-vcenter">
          Employee's Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
                 
                       <div>
                           <label className="name-label" name="firstName">First Name : </label> 
                          
                              <span className="name-labelbold" >  {profiles.firstName  }</span>
                               
                               <br /> <br />
                           <label className="name-label" name="fullName"> Last Name : </label> 
                           
                              <span className="name-labelbold"  > {profiles.lastName}</span>
                              

                           <br /> <br />

                           <label className="name-label"  >Email :</label> 

                           
                               <span className="name-labelbold"   >{profiles.emailAddress}</span>

                           <br /><br />


                           <label className="name-label" > Phone : </label> 
                           
                              <span className="name-labelbold"  > {profiles.phoneNumber}</span>
                           

                           <br /> <br />

                           <label  className="name-label" >Department : </label>
                           
                               <span  className="name-labelbold" > {profiles.department}</span>
                              
                           
                          <br /> <br />
                          <label  className="name-label"  >Social Media Handle : </label>
                           
                              <span  className="name-labelbold" > {profiles.socialMediaHandle}</span>
                              
                          
                          <br /> <br />
                          <label  className="name-label"  >Created date time : </label>
                          
                             <span  className="name-labelbold" > {profiles.createdDateTime}</span> 
                              
                           
                          <br /> <br />
                          <label className="name-label"  >status : </label>
                         
                             <span  className="name-labelbold" > {profiles.statuss}</span>
                              
                           
                          <br /> <br />
                            

                           <label  className="name-label" >Profile Picture</label> <br />
                           
                           <img className="edit-image-cont" src={profiles.image} />

                       </div>
          
        
      </Modal.Body>
      <Modal.Footer>
        <Button  onClick={() => setModalShow(false)} >Close</Button>
      </Modal.Footer>
       
    </Modal>
    <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Logout
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to logout?</p>
       <button  className="btn-remove btn btn-danger" onClick={handleLogout} >Logout</button> 
       </Modal.Body>
       
      
      </Modal>
    
        
        
        </>
    )
}
export default HeaderLogin