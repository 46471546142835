
import "./directpages.css"
import ModeHeader from "../layouts/modeheader"
import FirstHeader from "../layouts/firstheader"
import Changer from "../layouts/changer"
import Newsfeed from "../layouts/newsfeed"
import Footer from "../layouts/footer"
import { Table } from "react-bootstrap"
import useFectch from "../adminpage/createbackend"

const ListenLive =()=>{

    


    const {data:callin,isPending,error} = useFectch('programs/getCallNumbers')
    console.log("halla",callin);

    return(
        <>

        <div className="overflowdiv">
        <Changer/>
        <ModeHeader/>
        <FirstHeader/>
        <Newsfeed/>
     
          
            <div className="caller-div">
                
                <span className="call-num"> Call-in Numbers   <span className="contribute"> For Inquiries or Contributions </span></span>
                
            </div>
            <div>
          
          {error && <div className="errorssample">{error}</div>}
              
            <table className="numtable-container">

            

              <Table striped bordered hover variant="dark" className="numtable-container">
      <thead>
      
        <tr>
           <th>S/N</th> 
          <th>Type</th>
          <th>Call Number</th>
          <th>Whatsapp/Text/Email</th>
        </tr>
      </thead>
      <tbody>
      {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

     </div>}
      {
        callin && callin.map((call,i)=>(
          <tr>
           <td>{i+1} </td> 
          <td>{call.type} </td>
          <td> {call.callNumbers}  </td>
          <td>{call.socialMediaNumbers} </td>
        </tr>
          
        ))
      }
       

       

      </tbody>
    </Table>
          </table>
                
            </div>
          
            
            <Footer/>
            </div>
        </>
    )
}
export default ListenLive
