import ViewAdvertRate from "../adminpage/viewadvertrate"
import FirstHeader from "../layouts/firstheader"
import Header from "../layouts/header"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"
import { Table } from "react-bootstrap";
import { Fragment } from "react"
import EditableRoll from "../adminpage/editableroll"
import RollCall from "../adminpage/rollcall"
import Footer from "../layouts/footer"
import useFectch from "../adminpage/createbackend"
import Changer from "../layouts/changer"


const AdvertRAte =(advert)=>{

  const {data,isPending,error} = useFectch('adverts/getAdvertRates');

 return(
    <>
    <ModeHeader/>
    <Changer/>
    <FirstHeader/>
    <Newsfeed/>
  <div className="adwebview">

  
      
   <h2 className="advertsideview">Our Advert Rates</h2>  
   
    <Table striped bordered hover size="sm" id='advertweb-view'>
    
      <thead>
        <tr className="tbview-head">
        <th>Time Zone</th>
        <th >5am-6am</th>
        <th>6am-10am</th>
        <th>10am-1pm</th>
         <th>1pm-4pm</th> 
         <th>4pm-7pm</th> 
         <th>7pm-11am</th> 
         <th>11pm-1am</th>
         <th>1am-5am</th>
         
       
        </tr>
      </thead>
         

  

      <tbody>
      {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>

</div>} 

      {

        data && data.map((advert)=>(
<tr key={advert.advertRatesId} >
        
        
       
        <td className='tdd' >{advert.timeZones} </td>           
        <td className='tdd'>{advert.first}</td>
        <td  className='tdd'>{advert.second}  </td>
        <td className='tdd'>{advert.third}   </td>
        <td className='tdd' >{advert.fourth}  </td>
        <td className='tdd' >{advert.fifth}  </td>
        <td className='tdd' >{advert.sixth}  </td>
        <td className='tdd' >{advert.seventh}  </td>
        <td className='tdd' >{advert.eighth}  </td>
        
        
        </tr>
        
        ))
      }
      
      </tbody>
      
      </Table>


    </div>

   <Footer/> 
    </>
 )
    
}
export default AdvertRAte