import { useEffect, useState } from "react";
import axios from "../../utility/axios";


const useFectch = (url)=>{

    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    
        

 useEffect(()=>{
  setIsPending(true)
      axios.get(url)
      .then(response=>{
        console.log("abia>>>>>>>>",response)
        if (!response.statusText=== "OK"){
          throw Error("Could not fetch data")
        }
        setIsPending(false)
        setData(response.data)
        
        setError(null)
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error =>{
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
     },[url]);

     return{data,isPending, error}
        
    
}
export default useFectch
