import { BsFillPauseCircleFill } from 'react-icons/bs';
import { GoPlay } from 'react-icons/go';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiLoader2Fill } from 'react-icons/ri';
import {CirclesWithBar, Oval } from  'react-loader-spinner';

function Player() {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasSound, setHasSound] = React.useState(false);
  const audioRef = useRef(new Audio('https://edge.mixlr.com/channel/ulcog'));

  const togglePlay = () => {
    const newIsPlaying = !isPlaying;
    setIsPlaying(newIsPlaying);
    localStorage.setItem('isPlaying', newIsPlaying.toString());
  };

  useEffect(() => {
    const storedIsPlaying = localStorage.getItem('isPlaying');
    if (storedIsPlaying !== null) {
      setIsPlaying(storedIsPlaying === 'true');
    }
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    function handleBeforeUnload() {
      setIsPlaying(false);
      audioRef.current.pause();
      localStorage.removeItem('isPlaying');
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;

    const errorHandler = () => {
      setIsLoading(false);
    };

    const playingHandler = () => {
      setHasSound(true);
    };

    const endedHandler = () => {
      setHasSound(false);
    };

    audioElement.addEventListener('error', errorHandler);
    audioElement.addEventListener('playing', playingHandler);
     audioElement.addEventListener('ended', endedHandler);

    return () => {
      audioElement.removeEventListener('error', errorHandler);
       audioElement.removeEventListener('playing', playingHandler);
       audioElement.removeEventListener('ended', endedHandler);
       audioElement.pause();
      audioElement.src = 'https://edge.mixlr.com/channel/ulcog';
    };
  }, []);

  return (
    <>
      <div className="listen">
        <span className="play-icon">

        {isPlaying && !hasSound && (
          <div  className="rotate-spin">
      <Oval
      
      height={60}
      width={60}
      color="#050563"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#FF8C00"
      strokeWidth={2}
      strokeWidthSecondary={2}
    
    />
    </div>
      )}
          {isPlaying ? (
            
            
            <BsFillPauseCircleFill className="play-iconed" size={57} onClick={togglePlay} />
          ) : (
            <GoPlay className="play-iconed" size={65} onClick={togglePlay} />
          )}
        </span>
        <span className='listen-span'>Listen <span className="live-no"> Live</span></span>
      </div>
      {hasSound && isPlaying && (
        <span className="icon-dance">
          {[...Array(10)].map((_, i) => (
            <span className='span-dance' key={i} />
          ))}
        </span>
      )}
     
    </>
  );
}

export default Player;
