
import "./ecommerce.css"
import React, { useState } from 'react';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsListNested, BsPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Dropdown from "../layouts/Drop-down";
import { IoIosArrowDown } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css'; 

const EcommerceNav = (()=>{
 const [showBasic, setShowBasic] = useState(false);


 const [click, setClick] = useState(false);
 // State dropdown
 const [dropdown, setDropdown] = useState(false);
 // Toggle menu-icon
 const handleClick = () => setClick(!click);
 const closeMobileMenu = () => setClick(false);
 const onMouseEnter = () => {
   if (window.innerWidth < 800) {
     setDropdown(false);
   } else {
     setDropdown(true);
   }
 };
 const onMouseLeave = () => {
   if (window.innerWidth < 800) {
     setDropdown(false);
   } else {
     setDropdown(false);
   }
 };

    return(
        <>
     <nav className="ebe-containersup">
      <div className="ebe-containersup-ec" >Ebeonadi e-commerce</div>
     </nav>
   
</>
    )
})
export default EcommerceNav