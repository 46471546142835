import { useParams } from "react-router-dom"
import useFectch from "../adminpage/createbackend"
import Changer from "../layouts/changer"
import FirstHeader from "../layouts/firstheader"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"


const JobServicesRate =()=>{

   
    return(
        <>
<ModeHeader/>
<FirstHeader/>
<Changer/>
<Newsfeed/>

<div>
    
</div>
        </>
    )
}
export default JobServicesRate