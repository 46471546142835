import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFieldArray, useForm } from 'react-hook-form';
import axios from "../../../utility/axios"
import { useState } from 'react';

let renderCount = 0;

const CreateProduct =(()=>{
    const {register,handleSubmit,control,formState:{errors}} = useForm();
    renderCount ++;
    console.log(errors)


    


    const handleImageChange = (e) => {
        console.log(e.target.files)
        setImage(e.target.files[0]);
      };

      const [image, setImage] = useState(null);
      const handleSubmited = async (data) => {
        //   console.log('addddddddd',data);
        // setDisabled(true)
        // setIsPending(true)
      
        const formData = new FormData();
        formData.append("productName", data.productName);
        formData.append("delivery", data.delivery);
        formData.append("productName", data.productName);
        formData.append("prouctPrice", data.prouctPrice);
        formData.append("discountPrice", data.discountPrice);
        formData.append("prouctDetails", data.prouctDetails);
        formData.append("additionaDetails", data.additionaDetails);
        formData.append("productContact", data.productContact);

        formData.append("additionalContact", data.additionalContact);
        formData.append("deliveryDuration", data.deliveryDuration);
        formData.append("ProductImg", image);
        
    
        await axios.post("registrations/createUser", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
    
          .then((res) => {
            console.log("res", res)
            // if (res.data.success) {
            //   setIsPending(false)
            //   setSuccess("Employee Created Successfully");
            //   setErrorMessage("");
            //   setDisabled(false)
        
            // } else {
    
            //   setErrorMessage(res.data.message);
            //   setIsPending(false)
            //   setDisabled(false)
              
            // }
    
          }).catch((err) => {
            console.log("err", err)
            // setErrorMessage("Something went wrong. Please try again later.");
            // setIsPending(false)
            // setDisabled(false)
          })
    
        scrolltop()
    
      };



      
      const scrolltop = (() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
    
        });
      })
      const { fields, append, remove } = useFieldArray({
        control,
        name: "ProductImg"
      });
    

    return(


        <>
        <div>

        <ul className="action-bar">
                    <li>Home /</li>
                    <li>E-commerce / </li>
                    <li className="createem"> Create Product </li>
                </ul>
            </div>
       <div className='createcompany-contain'>
       <Form className='booking-form-container' id='booking-form-container-id' onSubmit={handleSubmit((data, event) => {
        console.log('lettttttt---productdata', data);
        // setDisabled(true)
        event.target.reset()
       

      })}>
                        <p className='filld-form-create'>CREATE PRODUCT</p>

                    <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className='lb'>Product name <span className="star-required">*</span></Form.Label>
                                <Form.Control placeholder="Enter your name"  {...register("productName", { required: "This field is required" })}/>
                                <p className="cum-error">{errors.productName?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className='lb' >Delivery</Form.Label>
                                <Form.Select defaultValue="Choose from created companies..."  {...register("delivery", { required: "This field is required" })}>
                                    <option>Choose...</option>
                                    <option>Door delivery</option>
                                    <option>Pickup station</option>
                                    <option>All</option>
                                </Form.Select>
                                <p className="cum-error">{errors.delivery?.message}</p>
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label className='lb'>Quantity <span className="star-required">*</span></Form.Label>
                                <Form.Control placeholder="Enter product quantity..."  type='number' {...register("quantity", { required: "This field is required" })}/>
                                <p className="cum-error">{errors.quantity?.message}</p>
                            </Form.Group>

                           
                        </Row>
                        <Row className="mb-3 " >
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className='lb' >Company <span className="star-required">*</span></Form.Label>
                                <Form.Select defaultValue="Choose from created companies..." {...register("productName", { required: "This field is required" })}>
                                    <option>Choose from created companies...</option>
                                    <option>Service center</option>
                                    <option>Pickup/deliver</option>
                                </Form.Select>
                                <span className="cum-error">{errors.productName?.message}</span>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label  className='lb'>Product Price <span className="star-required">*</span></Form.Label>
                                 <Form.Control placeholder="Enter product price..." {...register("prouctPrice", { required: "This field is required" })} />
                                 <span className="cum-error">{errors.prouctPrice?.message}</span>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label  className='lb'>Discount Price</Form.Label>
                                 <Form.Control placeholder="Enter discount price if any..." {...register("discountPrice")}  />
                            </Form.Group>
                            
                        
                        </Row>  
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label className='lb'>Product details <span className="star-required">*</span></Form.Label>
                            <Form.Control placeholder="Enter your delivery address..." className='product-detailsinput'  {...register("prouctDetails", { required: "This field is required" })} />
                            <span className="cum-error">{errors.prouctDetails?.message}</span>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                            <Form.Label className='lb'>Additional Information </Form.Label>
                            <Form.Control placeholder="Enter additional info..."className='product-detailsinputad' {...register("additionaDetails", { required: "This field is required" })}/>
                            <span className="cum-error">{errors.additionaDetails?.message}</span>
                        </Form.Group>
                        <Row className="mb-3 " >
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label className='lb' >Product Contact number <span className="star-required">*</span></Form.Label>
                                <Form.Control type="number" placeholder="Enter phone number for enquiries..." {...register("productContact", { required: "This field is required" })} />
                                <span className="cum-error">{errors.productContact?.message}</span>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label  className='lb'>Additional phone number </Form.Label>
                                <Form.Control type="number" placeholder=" Enter additional..."{...register("additionalContact")} />
                               
                            </Form.Group>



                            <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label  className='lb'>Delivery duration <span className="star-required">*</span></Form.Label>
                                <Form.Control  placeholder=" Enter expected delivery day ... e.g 2days" {...register("deliveryDuration")} />
                                
                            </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label  className='lb'>Upload clear images of the product <span className="star-required">*</span></Form.Label><br/>
                                {
                                    fields.map((item,index)=>(
                                
                                    <div>
                                  <Form.Control  type='file' name='ProductImg' onClick={handleImageChange} key={item.id} className='input-imagesfile' />
                                  <button className="removeadd-product " type="button" onClick={() => remove(index)}>Remove</button> 
                                  </div>
                                    ))
                                }
                               
                               <button   type="button" className='button-addimages'
                               onClick={() => append({ ProductImg: image })}> Add Images</button>   
                            </Form.Group>
                            </Row>
                       



                        <Button id='conorder' type="submit" className='submitbtn-create ' >
                            Create Product
                        </Button>
                       
                    </Form>
        </div>
        
        </>
    )
})
export default CreateProduct