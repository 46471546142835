
import {RiArrowDropDownLine} from "react-icons/ri"
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ViewAdaogidi() {

  const [action, setAction]= useState('');
  const [show, setShow] = useState(false)
  const [lgShow, setLgShow] = useState(false);

  const actionButton= ()=>{
   setAction(!action)
 
 
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (

    <>


<div>
             <ul className="action-bar">
                 <li>Home /</li>
                <li>Adaogidi Pageant/</li>
                <li className="createem">List of Winners</li>
            </ul>
         </div> 

<h2 className="viewemployee-head">Winners List</h2>
 
    <Table striped bordered hover size="sm" className='adaogidi-table'>
      <thead>
        <tr>
          <th>S/N</th>
          <th>First Name</th>
          <th>Date</th>
          
          <th>Profile</th>
          <th>Image</th>
          
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>Mark</td>
          
                    
          <button className='startp-btn' onClick={actionButton} >Action <RiArrowDropDownLine size={20} className="action-icon-drop"  />
                {action &&
                  <li className="btn-down" >
                   <Button variant="" onClick={handleShow}>
        Update 
      </Button>
      <Button onClick={() => setLgShow(true)} className="changepassbtn">Remove </Button><br/>
                  </li>

                }</button>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
          <td>Mark</td>
          
        
          
          
          <button className='startp-btn'>Action <RiArrowDropDownLine size={20} className="action-icon-drop" />
                {/* {action &&
                  <li className="btn-down" >
                    <li>Update</li>
                    <li className="down-drop2">Remove</li>
                  </li> */}

                 
                </button> 
        </tr>
        
      </tbody>
    </Table>

    <Modal show={show} onHide={handleClose} animation={false} className="employupdate-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update Winner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            
        
        <label>First Name</label><br />
                <input className="newstopic-inputs" placeholder="Company Name..."  />
                <p className="cum-error"></p>
                <label>Last Name</label><br />
                <input className="newstopic-inputs" placeholder="" />
                <p className="cum-error"></p>
                <label>Date</label><br />
                <input type="date" className="newstopic-inputs" placeholder="" /><br /><br /> 
                <label>Profile</label><br />
                <textarea className="news-detailsed" placeholder=""  />
               
                <label>Change Image</label><br />
                <input type="file" className="newstopic-inputs" placeholder=""/><br /><br />

               
            

           

        

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* remove program */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Remove Winner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <p>Are You Sure You Want to Remove the Selected Winner?</p>
       <button  className="btn-remove btn btn-danger">Remove</button> 
       </Modal.Body>
       
      
      </Modal>


    </>
  );
}

export default ViewAdaogidi;


