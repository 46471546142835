
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../utility/axios"
import Card from 'react-bootstrap/Card';
import present1 from "./../images/bluecurve.jpg"



const Body2 = () => {
    const [data, setData] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
  
    useEffect(() => {
      axios
        .get("programs/getPrograms")
        .then((response) => {
          console.log("abia>>>>>>>>", response);
          if (!response.statusText === "OK") {
            throw Error("Could not fetch data");
          }
          setIsPending(false);
          setPrograms(response.data);
         
        })
        .catch((error) => {
          setError(error.message);
          setIsPending(false);
          console.log(error);
        });
    }, []);
  
    useEffect(() => {
      const updateCurrentProgram = () => {
        const now = new Date();
        const currentTime = now.getHours() + ":" + now.getMinutes();
        const airingPrograms = programs.filter(
          (program) =>
            currentTime != program.programStartTime &&
            currentTime != program.programEndTime
        );
        setCurrentProgram(airingPrograms);
      };
      updateCurrentProgram();
      const updateInterval = setInterval(() => {
        updateCurrentProgram();
      }, 8000);
      return () => clearInterval(updateInterval);
    }, [programs]);

    const shuffledArray = currentProgram && currentProgram.sort(() => Math.random() - 0.50 ).slice(0, 4)  ;

    const [isShow, setIsShow]= useState(false)


    /////body2 skeleton//////////////////////////////
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
      function handleOnline() {
        setIsOnline(true);
      }
  
      function handleOffline() {
        setIsOnline(false);
      }
  
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
  
      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);
  
  
    return (
      <>
        <div className="body2-container">
          <div className="first-body2container">
            <div className="body2name-container">
              <p className="ebeonadiname-body"> Ebeonadi Fm </p>
              <p className="ebeonadiname-favorite">Your Favorite Station </p>
             <NavLink to="/schedule"> <button className="button-73" role="button">Our Programs</button></NavLink>
            </div>
            <div className=" displaytextcover">

              <div className="newabout-container">
                <div className="textdisplay-body2">
                  <span> The </span>
                  <span> radio  </span>
                  <span>station</span>
                  <span>with</span>
                  <span>difference, </span>
                  <span>resident radio station</span>
                  <span> on 24hr transmission</span>
                  <span>in South East Nigeria,</span>
                  <span> Ebeonadi combines</span>
                  <span>culture with</span>
                  <span> entertainment.</span>
                  <span>Proudly Nigeria</span>
                  <span>and Proudly Igbo,</span>
                  <span>we don't use</span>
                  <span>foreign accents</span>
                  <span>in our station</span>
                  <span>we are true to our</span>
                  <span>heritage and culture.</span>

                </div>
                
   <NavLink to={"/aboutus"} className="removebody2btn-nav"><button className="button-56" role="button">Read More</button></NavLink>
              </div>

            </div>
             


            {/* <div className="body22">
              <NavLink to="/schedule" className="schedule">
                Programs 
              </NavLink>
            </div>

            {error && <div>{error}</div>}
               <div>
            <div className="body2-secondcontainer">

      {isPending && 
                  <div className="skeletonbody2-contain">
                    <div className="backendbody2" >
                      <div>
                        <div className="body4">



                          <img className="img2-skeleton"/>

                        </div>
                      </div>
                      <div className="lcontain-skeleton">
                        <p className="rynight"></p>
                        <p className="rynight">

                        </p>
                      </div>
                      <img className="body2Line" />
                    </div>
                    <div className="backendbody2" >
                      <div>
                        <div className="body4">



                          <img className="img2-skeleton"
                          />

                        </div>
                      </div>
                      <div className="lcontain-skeleton">
                        <p className="rynight"></p>
                        <p className="rynight">

                        </p>
                      </div>
                      <img className="body2Line" />
                    </div>

                    <div className="backendbody2" >
                      <div>
                        <div className="body4">



                          <img className="img2-skeleton"
                          />

                        </div>
                      </div>
                      <div className="lcontain-skeleton">
                        <p className="rynight"></p>
                        <p className="rynight">

                        </p>
                      </div>
                      <img className="body2Line" />
                    </div>
                    <div className="backendbody2" >
                      <div>
                        <div className="body4">



                          <img className="img2-skeleton"
                          />

                        </div>
                      </div>
                      <div className="lcontain-skeleton">
                        <p className="rynight"></p>
                        <p className="rynight">

                        </p>
                      </div>
                      <img className="body2Line" />
                    </div>




                  </div>
        }  
         
          

              {shuffledArray  &&
                shuffledArray.map((program) => (
                   
                  
                  <div className="backendbody2" key={program.programsId}>
                    <div>
                      <NavLink to={`/program2/${program.programsId}`} className="body4">
                       
                         
                          
                    <img  className="img2"  src={program.programpresenters.map((image) => image.image)}
                      />
                          
                       </NavLink>
                    </div>
                    <div className="lcontainer">
                      <p className="lnight">{program.programName}</p>
                      <p className="ltime">
                        {program.programStartTime} : {program.programEndTime}
                      </p>
                    </div>
                    <img className="body2Line" src={present1}/> 
                  </div>
                  

                  
                ))} 
            </div>
            </div> */}
          </div>
        </div>
      </>
    );
  };
  
  export default Body2;
  
