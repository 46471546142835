import React, { useState } from "react"
import "./header.css"
import logo1 from "./../images/logo fm2.jpg"
import {IoMdArrowDropdown} from "react-icons/io"
import ContactShow from "../directpages/contactshow";
import {AiOutlineMenu}from "react-icons/ai"
import {ImMenu3}from "react-icons/im"
import { Await,NavLink, Navigate, useNavigate } from "react-router-dom";
import ModifiedNav from "./modifiednav";

const Header = ()=>{

 const [contactShow, setContactShow] = useState(false)
 const popContact = ()=>{
    setContactShow(!contactShow)
}


const handleLogout = () => {
    // Clear any user session or token
    // Redirect to login page or any other page as needed
    // Navigate("/login");
  };



    return(
        <>
            <div className="nav-bar22">
                
            <img className="logo" src={logo1} /> 
                <h1 className="radio-brand">EBEONADI FM</h1>
                <i><AiOutlineMenu/></i>
               
                <ul className="nav-list">
                
                  
                    
                </ul>
            
            </div>
           

        
            
        </>
    )
}
export default Header


