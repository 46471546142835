import EcommerceNav from "./ecommerce_nav"
import image1 from "../images/image-buys.png"
import EcommerceGoods from "./ecommerce-goods"
import EcommmerceFooter from "./ecocommercefooter"
import Footer from "../layouts/footer"
import { Link } from "react-router-dom"
import EcoNAvSearch from "./econavsearch"
import { useState } from "react"



const EcommerceUpper =(()=>{
   
    
    
    return(

        <>
        <main className='mainbookinpage-all'>
        <div>
      
       <EcoNAvSearch/> 
       
       </div>
       <div className="howtobuy-cls" >
        <div className="howto-h1">
            <h1>How to buy on Ebeonadi</h1>
            
<Link to="/howtobuy" ><button className="button-55" role="button">Click here</button></Link>


        </div>
       
        <div>
           <div> <img className="image-howto" src={image1}/></div>
        </div>
       </div>
        <div>
            <EcommerceGoods/>
        </div>
      
        <div>
            <Footer/>
        </div>
        </main>
        </>
    )
})
export default EcommerceUpper