import Changer from "../layouts/changer";
import Header from "../layouts/header";
import Newsfeed from "../layouts/newsfeed";
import OnAir from "../layouts/onair";
import photo2 from "../images/black2.jpg";
// import LineImage from "../news/lineImage"
import ShowMissed from "../news/showmissed";
import Footer from "../layouts/footer";
import FirstHeader from "../layouts/firstheader";
import ModeHeader from "../layouts/modeheader";
import SecondSideAdvert from "./secondsideadvert";
import useFectch from "../adminpage/createbackend";
import { useParams } from "react-router-dom";

const Program2 =()=>{

    const { programsId } = useParams();
    const {data:program,isPending,error} = useFectch("programs/getPrograms")
     console.log("p>>>>>>>>>",program)


     const newArr = program.filter((id)=>
    id.programsId === programsId
      
    )

    return(
        <>
        
         <ModeHeader/>
        <FirstHeader/>
         <Changer />
         {/* <OnAir /> */}
         <Newsfeed/>
         <div className="body2conshow">
         {isPending && <div className="loaderless"> <div className="lds-ripple"> Loading... <div></div><div></div></div>
          </div>}
          {error && <div className="errorssample">{error}</div>}
            
            
            {
                newArr && newArr.map((program)=>(
                    <div>


                    <div className="host-container">
                        <div className="host-div">
                        <span className="prog-name">{program.programName}</span>
                        
                        <img className="host" src={program.programpresenters.map((image)=>(
                                  image.image
                        ))} />
                    <p className="prog-host">{program.programpresenters.map((pro)=>(
                                 pro.firstName && pro.lastName
                    ))}</p>
                        </div>
                        <div className="second-addiv">
                           <SecondSideAdvert/> 
                        </div> 
                    </div>
                    <div className="aboutshow-container">
                        <h2 className="hostdetailsHead">About the Program</h2>
                        <div className="proDetails">
                       { program.programDetails }
                        </div>
        
                    </div>
        
                    </div>
                ))
            }
           
          <Footer/>
          </div>
        </>
    )
}
export default Program2