
import Changer from "../layouts/changer";
import FirstHeader from "../layouts/firstheader";
import ModeHeader from "../layouts/modeheader";
import "../marketplacenews/marketplacenews.css";

const BuyMarketPlace =()=>{

    return(
        <>
        <ModeHeader/>
        <FirstHeader/>
        <Changer/>

        <div>


        </div>
        
        </>
    )
}
export default BuyMarketPlace