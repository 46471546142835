import FirstHeader from "../layouts/firstheader"
import Footer from "../layouts/footer"
import ModeHeader from "../layouts/modeheader"
import Newsfeed from "../layouts/newsfeed"
import logo from  "../images/logo fm2.jpg"
import useFectch from "../adminpage/createbackend"
import moment from "moment"
import ReadMoreReact from "read-more-react/dist/components/ReadMoreReact"
import Changer from "../layouts/changer"
import { BiTimeFive } from "react-icons/bi"

const PromsGames =()=>{


  const {data,isPending,error} = useFectch('programs/getCompanyNews');
 
 


    return(
        <>
        <ModeHeader/>
        <Changer/>
        <FirstHeader/>
        <Newsfeed/>
        {isPending && <div className="loaderless"> <div className="lds-ripple"> Checking for Company news... <div></div><div></div></div>

</div>}
   {
    data.length === 0 && isPending === false ? (
      <div className="games-div"><p>No Proms Runing Currently!</p>
      <p>Please Check Back Later...</p>
      </div>
    ):(
   
      
        data && data.map((news)=>(
           

          <div className="category-card">
          <div className="content" >
            <span className="tag"><img src={logo} className="ebeona-logo-promo"/></span>
            <h1>{news.topic}</h1>
            <h2 className="secondname-com">{news.secondTopic}</h2>
            <p> <BiTimeFive className="timecompany-logo"/>  { moment(news.startDateTime).fromNow() } </p>
          <div>   {news.details && news.details} </div>
          </div>
       
        </div>

        )))
      }
       
    
 


       
        <Footer/>
        </>
    )
}
export default PromsGames