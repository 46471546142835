import logo1 from "../images/logoremove.png";
import present1 from "../images/human22.png";
import {AiOutlineSchedule} from "react-icons/ai";
import OnAir from "./onair";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import useFectch from "../adminpage/createbackend";
import axios from "../../utility/axios"
import Programs from "../directpages/programs";
// import Player from "../../player";
const ModeHeader=()=>{

  
    // const {data,isPending,error} = useFectch("programs/getPrograms")
    // console.log("programs>>>>>>>>>",data)
    
    const [programs, setPrograms] = useState([]);
    const [currentProgram, setCurrentProgram] = useState(null);
    // const [error, setError] = useState(null);
    
        

    useEffect(()=>{
        axios.get("programs/getPrograms")
        .then(response=>{
          console.log("abia>>>>>>>>",response)
          if (!response.statusText=== "OK"){
            throw Error("Could not fetch data")
          }
          setPrograms(response.data)
          console.log("data>>>>>>>>>",response.data)
        })
        .catch(error =>{
        console.log(error)
        })
       },[]);

       useEffect(()=>{
        const  updateCurrentProgram =(()=>{
            // setPrograms(programs)
            const now = new Date()
            const currentTime = now.getHours() + ':' + now.getMinutes()
            //  console.log("curnttttt",currentProgram);
             const currentProgram = programs.find(
               (program)=> 
               currentTime >= program.programStartTime &&
               currentTime <= program.programEndTime
                 
                   )
           
            setCurrentProgram(currentProgram);

        })
        updateCurrentProgram()
        const updateInterval = setInterval(()=>{
        updateCurrentProgram(); 
        // const now = new Date()
        // const time = now.getHours() + ':' + now.getMinutes()
        // const currentProgram = programs.find(
        //     (program) => time >= new Date(program.programStartTime
                
        //         ) && time <= new Date(program.programEndTime
        //             ))
        // setCurrentProgram(currentProgram)
        // console.log("current",currentProgram);
       },1000);
    //    console.log("prom>>>>>>>>",programs);
       return () => clearInterval(updateInterval)

       },[programs]);

       console.log("yeyeyeyeyeyummmmmmmm>>>>>>>>>",programs)
            
return( 
    <>

        <div className="container-header">
           <div className="all-modeprogram">
            <div className="second-div-container">
                <NavLink to="/" className='navigateicon-home'> <img className="logo1" src={logo1} /></NavLink>
                <div className=" presenter22">
                    {
                        currentProgram ? (

                            <img className="img-present" src={currentProgram.programpresenters.map((image) => (image.image))} />

                        ) : (
                            <img className="img-present" src={present1} />
                        )
                    }

                </div>


            </div>

              {/* <div className="program-structure">    */}
                  <OnAir />  
               {/* </div>    */}
            </div>
           
        </div>
    </>
)

}
export default ModeHeader
