import { Await, NavLink, useNavigate } from "react-router-dom"
import { get, useForm } from "react-hook-form";
import Header from "../layouts/header";
import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import axios from "../../utility/axios";

let renderCount = 0;


const AdminLogin =()=>{
const {register,handleSubmit,formState:{errors}} = useForm();
renderCount ++;
console.log(errors)
const [data, setData] = useState([])


const navigate = useNavigate()
const [isPendings, setIsPendings] = useState(false);
const [errorMessage, setErrorMessage] = useState(' ')
const [disabled, setDisabled]= useState(false)

const handleSubmited = async (data)=>{
    setDisabled(true);
    setIsPendings(true);
    console.log("data", data)
     await axios.post("registrations/login",data)
      
     .then((res)=>{
        console.log("res",res)
        if(res.data.success === true){
           const mainData = res.data.userDetails
            localStorage.setItem("adminData",JSON.stringify(mainData)) 
            setIsPendings(false);
            setDisabled(false);
          
            navigate("/admindashboard", {state:{}} )
            if( mainData.emailAddress == "ebeonadiradio@gmail.com"){
             navigate("/superadmin", {state:{}} ) 
        }

        }else if(res.data.success === false ){
            setIsPendings(false); 
            setErrorMessage('Login with existing email and password')
        }else{
            setIsPendings(false); 
            navigate("/adminLogin", {state:{}} )
        }
          
    }).catch((err)=>{
        console.log("err", err)
        setIsPendings(false); 
        setDisabled(false);
    }) 
    
}
return(
        <>
        <Header/>
            <div className="logindiv-container">
        {isPendings && 
           <div style={{ display: isPendings ? 'flex' : 'none' }} className='modaled'>
           <div className='modal-contents'>
             <div className='loadered'></div>
             <div className='modal-texted'>loging in...</div>
           </div>
         </div>

        }
        <form className="login-form" onSubmit={handleSubmit((data)=>{
            handleSubmited(data)
            // console.log(data)
        })}>
            <h2 className="logd">login</h2>
             <label for="">Email</label><br/>
            <input className="input-phone" type="text" placeholder="Email... " {...register("emailAddress", {required:"This field is required"})}/>
            <p className="cum-error">{errors.emailAddress?.message}</p>
            <label for="">Password</label><br/>
            <input className="pass" type="password" placeholder="Password..." {...register("password", {required:"Password is required"})} /><br/>
            <p className="cum-error">{errors.password?.message}</p>
             {/* <NavLink to="/admindashboard" className="lognav">  */}
                <button type="submit" className="login-btn  " disabled={disabled}>login</button>
             {/* </NavLink>   */}
             {errorMessage && <div className="errlogin">{errorMessage}</div>}
           
        </form>
        </div>
        </>
    )
}
export default AdminLogin