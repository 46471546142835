import FirstHeader from "../layouts/firstheader";
import ModeHeader from "../layouts/modeheader";
import AdaPic from "../images/ada.jpg";
import ada2 from "../images/ada3.jpg";
import ada3 from "../images/ada4.jpg";
import Footer from "../layouts/footer";
import Newsfeed from "../layouts/newsfeed";


const AdaOgidi=()=>{
    return(
        <>
        <div className="overflowdiv">
        <ModeHeader/>
        <FirstHeader/>
        <Newsfeed/>
        <div className="adaogidi-container">
            <div className="ada-img-container">
                <img className="ada-pic" src={ada2}/>
                <img className="ada-pic" src={AdaPic}/>
            </div>
            <h2 className="adahead-text">ADAOGIDI PAGEANT</h2>
            <p className="ada-text" >
            Now in it’s 7th edition, Ada Ogidi is combination of beauty pageant and cultural exhibition where the traditional style bikini runway is replaced with show casing the marvelous cultural outfits worthy of a true queen. It has become an annual tradition, every end of the year, at Residency Hotel (Leophine), Ogidi.
            </p> 
<p className="reining-head">Our Reigning Queen (2023)</p>
            
            <div className="adawinner-container">
                <span className="lady-text"><span className="winner-name">Lady Amaka Gbuliaku</span> is a 300 level Chemistry student at Chukwuemeka Ojukwu University, Awka</span>
                <img className="winner-img" src={ada3}/>
            </div>
            <div>
                <p className="previousqueen-head">List of Our Previous Queens:</p>

                <table className="adatable-container">
              
              <thead className="theads">
                  <tr>
                         
                      <td>Year</td>
                      <td className="previousada">Name</td>
                    
                      
                  </tr>
              </thead>
            
              <tbody className="previous-ada-body">
                  <tr >
                      
                      <td className="ada-year" >2020</td>
                      <td className="previousada"> Ada Eze</td>
                    
                      
                  </tr>
                  <tr>
                
                   
                    <td className="ada-year">2021</td>
                    <td className="previousada" >Ngozi Ike</td>
                    
                    
                  </tr>
                  <tr>
                  
                    <td className="ada-year">2022</td>
                    <td className="previousada" > Chisom Nwabueze</td>
                    
                    
                  </tr>
                  
              </tbody>
          </table>
            </div>

        </div>
        <Footer/>
        </div>
        
        </>
    )
}
export default AdaOgidi