import { NavLink } from "react-router-dom"

const NoFound = ()=>{
    return(
        <>
        <div className="nofoundpage-container"> 
<h1 className="h1404-head">Looks like you're lost
</h1>
<p className="zoom-area"><b></b> The page you are looking for is not avaible! </p>
<section className="error-container">
  <span>4</span>
  <span><span className="screen-reader-text">0</span></span>
  <span>4</span>
</section>
<div className="link-container">
  <a target="_blank" 
//   href="https://www.silocreativo.com/en/creative-examples-404-error-css/"
   class="more-link"> <NavLink className="navhome-" to="/" >Go to home page</NavLink></a>
</div>
</div>
        </>
    )
}
export default NoFound